import React, { createContext, FC, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { getApiService, getOrganizationsApiService, getStorageService } from 'services';
import { Organization } from 'types';
import { generateFormData } from 'kl-b2c-ui-kit';

interface OrganizationContext {
    organization: Omit<Organization, 'id' | 'creationDate'> | null;
    getOrganization: (id: string) => void;
    addOrganization: (data: Omit<Organization, 'id' | 'creationDate'>) => void;
    updateOrganization: (data: Omit<Organization, 'creationDate'>) => void;
}

const OrganizationContext = createContext<OrganizationContext>({} as OrganizationContext);

const OrganizationProvider: FC<PropsWithChildren> = ({ children }) => {
    const storageService = getStorageService();
    const [organization, setOrganization] = useState<Omit<Organization, 'id' | 'creationDate'> | null>(null);
    const apiService = getApiService(storageService);
    const organizationsApiService = getOrganizationsApiService(apiService);

    const getOrganization = async (id: string) => {
        const organizations = await organizationsApiService.get(id);
        setOrganization(organizations.data);
    };

    const addOrganization = async (data: Omit<Organization, 'id' | 'creationDate'>) => {
        const organizations = await organizationsApiService.add(generateFormData(data));
        setOrganization(organizations.data);
    };

    const updateOrganization = async (data: Omit<Organization, 'creationDate'>) => {
        const organizations = await organizationsApiService.update(generateFormData(data));
        setOrganization(organizations.data);
    };

    const memoValue = useMemo(
        () => ({
            organization,
            getOrganization,
            addOrganization,
            updateOrganization,
        }),
        [getOrganization, organization, addOrganization, updateOrganization]
    );

    return <OrganizationContext.Provider value={memoValue}>{children}</OrganizationContext.Provider>;
};

export const useOrganization = () => useContext(OrganizationContext);

export default OrganizationProvider;
