import React from 'react';
import { useTranslation } from 'react-i18next';

const EmptyList = () => {
    const { t } = useTranslation('common/errors');

    return <p>{t('empty-list')}</p>;
};

export default EmptyList;
