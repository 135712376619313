import { getBaseMultipleContext } from 'contexts';
import { useContext } from 'react';
import { getApiService, getCourseGroupsApiService, getProfessionGroupsApiService, getStorageService } from 'services';
import {
    AddProfessionGroup,
    GetProfessionGroupsExcelFilter,
    GetProfessionGroupsFilter,
    ProfessionGroup,
    UpdateProfessionGroup,
} from 'types';

const storageService = getStorageService();
const apiService = getApiService(storageService);
const professionApiService = getProfessionGroupsApiService(apiService);

const ProviderResult = getBaseMultipleContext<
    GetProfessionGroupsFilter,
    GetProfessionGroupsExcelFilter,
    ProfessionGroup,
    ProfessionGroup,
    AddProfessionGroup,
    UpdateProfessionGroup
>(professionApiService, 'professionGroups');

export const useProfessionGroups = () => useContext(ProviderResult.Context);
export default ProviderResult.Provider;
