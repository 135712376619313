import styled, { css } from 'styled-components';

export const UserInfoContainer = styled.div<{ collapsed: boolean }>`
    padding: 0 20px;
    margin-top: auto;

    button {
        min-width: 100% !important;
    }

    ${(props) =>
        props.collapsed &&
        css`
            display: flex;
            justify-content: center;

            button {
                padding: 0 !important;
                .kl-components-button-text {
                    display: none;
                }
            }
        `}
`;
