import React, { createContext, FC, PropsWithChildren, useMemo, useState } from 'react';
import { IBaseApi2 } from 'interfaces';

export interface IBaseItemContext<TType, TAdd, TUpdate> {
    item: TType | null;
    getItem: (id: string) => Promise<void>;
    addItem: (data: TAdd) => Promise<TType>;
    updateItem: (data: TUpdate) => Promise<void>;
    setItem: (data: TType) => void;
}

export const getBaseSingularContext = <TFilters, TFiltersExcel, TTable, TType, TAdd, TUpdate>(
    apiService: IBaseApi2<TFilters, TFiltersExcel, TTable, TType, TAdd, TUpdate>
) => {
    const Context = createContext<IBaseItemContext<TType, TAdd, TUpdate>>({} as IBaseItemContext<TType, TAdd, TUpdate>);

    const Provider: FC<PropsWithChildren> = ({ children }) => {
        const baseApiService = apiService;
        const [item, setItem] = useState<TType | null>(null);

        const getItem = async (id: string) => {
            const result = await baseApiService.get(id);
            setItem(result.data);
        };

        const addItem = async (data: TAdd) => {
            const result = await baseApiService.add(data);
            setItem(result.data);

            return result.data;
        };

        const updateItem = async (data: TUpdate) => {
            const eventGroups = await baseApiService.update(data);
            setItem(eventGroups.data);
        };

        const memoValue = useMemo(
            () => ({
                item,
                getItem,
                addItem,
                updateItem,
                setItem,
            }),
            [item, getItem, addItem, updateItem]
        );
        return <Context.Provider value={memoValue}>{children}</Context.Provider>;
    };

    return {
        Provider,
        Context,
    };
};
