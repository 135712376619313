import { getBaseMultipleContext } from 'contexts';
import { useContext } from 'react';
import { getApiService, getStorageService, getProfessionApiService } from 'services';
import {
    AddProfession,
    GetProfessionExcelFilter,
    GetProfessionFilter,
    Profession,
    ProfessionTable,
    UpdateProfession,
} from 'types';

const storageService = getStorageService();
const apiService = getApiService(storageService);
const professionApiService = getProfessionApiService(apiService);

const ProviderResult = getBaseMultipleContext<
    GetProfessionFilter,
    GetProfessionExcelFilter,
    ProfessionTable,
    Profession,
    AddProfession,
    UpdateProfession
>(professionApiService, 'professions');

export const useProfessions = () => useContext(ProviderResult.Context);
export default ProviderResult.Provider;
