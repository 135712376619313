import React, { FC, PropsWithChildren } from 'react';
import { Button } from '@kl/components-v6';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import { ActionButtonsContainer, Gallery, UploadedImagePreviewContainer, UploadedImagesContainer } from './styled';
import { useTranslation } from 'react-i18next';
import { TEN_MB_IN_BYTES } from 'consts/images';
import { useToaster } from 'contexts/toaster.context';

interface GalleryUploaderProps {
    photos: ImageListType;
    onChange: (imageList: ImageListType) => void;
    accept?: string[];
    maxSize?: number;
    maxCount?: number;
}

const GalleryUploader: FC<PropsWithChildren<GalleryUploaderProps>> = (props) => {
    const {
        onChange,
        photos,
        accept = ['jpg', 'jpeg', 'png', 'svg', 'svg+xml', '.gif'],
        maxSize = TEN_MB_IN_BYTES,
        maxCount = 10,
    } = props;

    const { t } = useTranslation('common/shared');
    const { setToaster } = useToaster();

    return (
        <Gallery>
            <ImageUploading
                multiple
                value={photos}
                onChange={(imageList) => {
                    const oversizeImages = imageList
                        .filter((image) => image.file && image.file?.size > maxSize)
                        .map((image) => image.file?.name);

                    if (oversizeImages.length > 0) {
                        setToaster({
                            type: 'warning',
                            message: t('image-size-error', { fileNames: oversizeImages.join(', ') }),
                        });
                    }
                    onChange(imageList.filter((image) => image.file && image.file?.size < maxSize));
                }}
                maxNumber={maxCount}
                maxFileSize={maxSize}
                acceptType={accept}
                onError={(e) => {
                    if (e?.acceptType) {
                        setToaster({
                            type: 'warning',
                            message: t('file-type-error'),
                        });
                    }
                    if (e?.maxFileSize) {
                        setToaster({
                            type: 'warning',
                            message: t('image-size-without-size-error'),
                        });
                    }
                }}
            >
                {({ imageList, onImageUpload, onImageRemoveAll, onImageUpdate, onImageRemove, dragProps }) => (
                    <>
                        <ActionButtonsContainer>
                            <Button onClick={onImageUpload} {...dragProps}>
                                {t('upload-photos')}
                            </Button>
                            {imageList.length > 0 && (
                                <Button mode={'dangerFilled'} onClick={onImageRemoveAll}>
                                    {t('clear-photos')}
                                </Button>
                            )}
                        </ActionButtonsContainer>
                        <UploadedImagesContainer>
                            {imageList.map((image, index) => {
                                return (
                                    <UploadedImagePreviewContainer key={index}>
                                        <span>{image.file?.name}</span>
                                        <Button
                                            size={'small'}
                                            mode={'primaryBlack'}
                                            onClick={() => onImageUpdate(index)}
                                        >
                                            {t('update')}
                                        </Button>
                                        <Button
                                            size={'small'}
                                            mode={'dangerOutlined'}
                                            onClick={() => onImageRemove(index)}
                                        >
                                            {t('remove')}
                                        </Button>
                                    </UploadedImagePreviewContainer>
                                );
                            })}
                        </UploadedImagesContainer>
                    </>
                )}
            </ImageUploading>
        </Gallery>
    );
};

export default GalleryUploader;
