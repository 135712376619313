import React, { createContext, FC, Key, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { getApiService, getEventGroupsApiService, getStorageService } from 'services';
import { Group, EventGroups } from 'types';
import { downloadFileFromBase64, PaginationOutput } from 'kl-b2c-ui-kit';

interface EventGroupsContext {
    eventGroups: PaginationOutput<Group>;
    getEventGroups: (params: EventGroups) => Promise<void>;
    getExcel: (params: Omit<EventGroups, 'page' | 'size'>) => Promise<void>;
    deleteEventGroups: (ids: Key[]) => Promise<void>;
}

const EventGroupsContext = createContext<EventGroupsContext>({} as EventGroupsContext);

const EventGroupsProvider: FC<PropsWithChildren> = ({ children }) => {
    const storageService = getStorageService();
    const [eventGroups, setEventGroups] = useState<PaginationOutput<Group>>({
        count: 0,
        items: null,
    });
    const apiService = getApiService(storageService);
    const eventGroupsApiService = getEventGroupsApiService(apiService);

    const getEventGroups = async (params: EventGroups) => {
        const eventGroups = await eventGroupsApiService.getItems({ ...params });
        setEventGroups(eventGroups.data);
    };

    const deleteEventGroups = async (ids: Key[]) => {
        await eventGroupsApiService.deleteMany(ids);
    };

    const getExcel = async (params: Omit<EventGroups, 'page' | 'size'>) => {
        const base64 = await eventGroupsApiService.getExcel({ ...params });
        downloadFileFromBase64(base64.data, 'xlsx', 'eventGroups');
    };

    const memoValue = useMemo(
        () => ({
            getEventGroups,
            eventGroups,
            getExcel,
            deleteEventGroups,
        }),
        [getEventGroups, eventGroups]
    );

    return <EventGroupsContext.Provider value={memoValue}>{children}</EventGroupsContext.Provider>;
};

export const useEventGroups = () => useContext(EventGroupsContext);

export default EventGroupsProvider;
