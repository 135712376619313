import React, { FC, PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { getBaseSingularContext } from 'contexts';
import { getApiService, getCoursesApiService, getProfessionApiService, getStorageService } from 'services';
import {
    AddOrUpdateProfessionCondition,
    AddOrUpdateProfessionLevel,
    AddOrUpdateProfessionPlus,
    AddOrUpdateProfessionTargetAudience,
    AddOrUpdateProfessionTeaser,
    AddProfession,
    CourseTable,
    GetProfessionExcelFilter,
    GetProfessionFilter,
    Profession,
    ProfessionTable,
    UpdateProfession,
    UpdateProfessionParticle,
} from 'types';
import { DomainType } from 'enums/domain-type';

const storageService = getStorageService();
const apiService = getApiService(storageService);
const professionApiService = getProfessionApiService(apiService);
const courseApiService = getCoursesApiService(apiService);

const BaseProvider = getBaseSingularContext<
    GetProfessionFilter,
    GetProfessionExcelFilter,
    ProfessionTable,
    Profession,
    AddProfession,
    UpdateProfession
>(professionApiService);

const useProfessionBase = () => useContext(BaseProvider.Context);

interface IProfessionContext {
    courseSelect: CourseTable[];
    professionSelect: ProfessionTable[];
    targetAudiencesUpdate: (data: UpdateProfessionParticle<AddOrUpdateProfessionTargetAudience>) => Promise<Profession>;
    professionConditionUpdate: (data: UpdateProfessionParticle<AddOrUpdateProfessionCondition>) => Promise<Profession>;
    professionTeaserUpdate: (data: UpdateProfessionParticle<AddOrUpdateProfessionTeaser>) => Promise<Profession>;
    professionPlusUpdate: (data: UpdateProfessionParticle<AddOrUpdateProfessionPlus>) => Promise<Profession>;
    levelUpdate: (data: UpdateProfessionParticle<AddOrUpdateProfessionLevel>) => Promise<Profession>;
    getSelectedCoursesProfessions: (domain: DomainType) => Promise<void>;
}

const ProfessionContext = createContext<IProfessionContext>({} as IProfessionContext);

const ProfessionProvider: FC<PropsWithChildren> = ({ children }) => {
    const [courseSelect, setCourseSelect] = useState<CourseTable[]>([]);
    const [professionSelect, setProfessionSelect] = useState<ProfessionTable[]>([]);

    const targetAudiencesUpdate = async (
        data: UpdateProfessionParticle<AddOrUpdateProfessionTargetAudience>
    ): Promise<Profession> => {
        const item = await professionApiService.targetAudiencesUpdate(data);

        return item.data;
    };
    const professionConditionUpdate = async (
        data: UpdateProfessionParticle<AddOrUpdateProfessionCondition>
    ): Promise<Profession> => {
        const item = await professionApiService.professionConditionUpdate(data);

        return item.data;
    };
    const professionTeaserUpdate = async (
        data: UpdateProfessionParticle<AddOrUpdateProfessionTeaser>
    ): Promise<Profession> => {
        const item = await professionApiService.professionTeaserUpdate(data);

        return item.data;
    };
    const professionPlusUpdate = async (
        data: UpdateProfessionParticle<AddOrUpdateProfessionPlus>
    ): Promise<Profession> => {
        const item = await professionApiService.professionPlusUpdate(data);

        return item.data;
    };
    const levelUpdate = async (data: UpdateProfessionParticle<AddOrUpdateProfessionLevel>): Promise<Profession> => {
        const item = await professionApiService.levelUpdate(data);

        return item.data;
    };

    const getSelectedCoursesProfessions = async (domain: DomainType) => {
        const coursesCall = courseApiService.getItems({
            page: 0,
            size: 300,
            name: null,
            domainType: domain,
            isVisible: null,
        });

        const professionCall = professionApiService.getItems({
            page: 0,
            size: 300,
            name: null,
            domainType: domain,
            isVisible: null,
        });

        Promise.all([coursesCall, professionCall])
            .then(([courseResult, professionResult]) => {
                setCourseSelect(courseResult?.data?.items ?? []);
                setProfessionSelect(professionResult?.data?.items ?? []);
            })
            .catch((e) => {
                throw new Error(e);
            });
    };

    const memoValue = useMemo(
        () => ({
            courseSelect,
            professionSelect,
            getSelectedCoursesProfessions,
            targetAudiencesUpdate,
            professionConditionUpdate,
            professionTeaserUpdate,
            professionPlusUpdate,
            levelUpdate,
        }),
        [courseSelect, professionSelect]
    );

    return (
        <BaseProvider.Provider>
            <ProfessionContext.Provider value={memoValue}>{children}</ProfessionContext.Provider>
        </BaseProvider.Provider>
    );
};

export const useProfession = () => ({ ...useContext(ProfessionContext), ...useProfessionBase() });
export default ProfessionProvider;
