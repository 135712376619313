import { AgreementsChapter, DomainType, LegalType, PageBuilderKey } from 'enums';
import { PageBuilderFilterType } from 'types';

const baseFilters = {
    page: 0,
    size: 100,
};

export const getFilterMapper = (pageKey: PageBuilderKey): PageBuilderFilterType => {
    if (!filterMapper[pageKey]) {
        throw new Error(`There is no filter mapper for ${pageKey}, consider to add one in to filterMapper object`);
    }

    return filterMapper[pageKey];
};

export const filterMapper: Record<string, PageBuilderFilterType> = {
    Agreements: {
        ...baseFilters,
        chapter: AgreementsChapter.All,
    },
    Cooperation: {
        ...baseFilters,
        type: LegalType.All,
        country: null,
    },
    Subscription: {
        ...baseFilters,
    },
    Speakers: {
        ...baseFilters,
    },
    Reviews: {
        ...baseFilters,
    },
    Organizations: {
        ...baseFilters,
    },
    NewsGroups: {
        ...baseFilters,
    },
    EventGroups: {
        ...baseFilters,
    },
    NewsSequence: {
        ...baseFilters,
        domainType: DomainType.All,
        name: null,
    },
    Events: {
        ...baseFilters,
        domainType: DomainType.All,
        isVisible: null,
        isVisibleOnHomePage: null,
        isVisibleTimeElapsed: null,
        isRegistrationOpened: null,
    },
    Courses: {
        ...baseFilters,
        domainType: DomainType.All,
        isVisible: null,
    },
    Professions: {
        ...baseFilters,
        domainType: DomainType.All,
        isVisible: null,
    },
    CourseGroups: {
        ...baseFilters,
    },
    ProfessionGroups: {
        ...baseFilters,
    },
    CourseLanguages: {
        ...baseFilters,
    },
    EventApplications: {
        ...baseFilters,
    },
    CourseApplications: {
        page: 0,
        size: 50,
    },
    ProfessionApplications: {
        ...baseFilters,
    },
};
