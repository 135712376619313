import React, { createContext, FC, Key, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { getApiService, getNewsGroupsApiService, getStorageService } from 'services';
import { Group, NewsGroups } from 'types';
import { downloadFileFromBase64, PaginationOutput } from 'kl-b2c-ui-kit';

interface NewsGroupsContext {
    newsGroups: PaginationOutput<Group>;
    getNewsGroups: (params: NewsGroups) => Promise<void>;
    getExcel: (params: Omit<NewsGroups, 'page' | 'size'>) => Promise<void>;
    deleteNewsGroups: (ids: Key[]) => Promise<void>;
}

const NewsGroupsContext = createContext<NewsGroupsContext>({} as NewsGroupsContext);

const NewsGroupsProvider: FC<PropsWithChildren> = ({ children }) => {
    const storageService = getStorageService();
    const [newsGroups, setNewsGroups] = useState<PaginationOutput<Group>>({
        count: 0,
        items: null,
    });
    const apiService = getApiService(storageService);
    const newsGroupsApiService = getNewsGroupsApiService(apiService);

    const getNewsGroups = async (params: NewsGroups) => {
        const newsGroups = await newsGroupsApiService.getItems({ ...params });
        setNewsGroups(newsGroups.data);
    };

    const deleteNewsGroups = async (ids: Key[]) => {
        await newsGroupsApiService.deleteMany(ids);
    };

    const getExcel = async (params: Omit<NewsGroups, 'page' | 'size'>) => {
        const base64 = await newsGroupsApiService.getExcel({ ...params });
        downloadFileFromBase64(base64.data, 'xlsx', 'newsGroups');
    };

    const memoValue = useMemo(
        () => ({
            getNewsGroups,
            newsGroups,
            getExcel,
            deleteNewsGroups,
        }),
        [getNewsGroups, newsGroups]
    );

    return <NewsGroupsContext.Provider value={memoValue}>{children}</NewsGroupsContext.Provider>;
};

export const useNewsGroups = () => useContext(NewsGroupsContext);

export default NewsGroupsProvider;
