import React, { createContext, FC, Key, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { getApiService, getCooperationApiService, getStorageService } from 'services';
import { Cooperation, GetCooperation } from 'types';
import { downloadFileFromBase64, PaginationOutput } from 'kl-b2c-ui-kit';

interface CooperationContext {
    cooperation: PaginationOutput<Cooperation>;
    getCooperation: (params: GetCooperation) => Promise<void>;
    getExcel: (params: Omit<GetCooperation, 'page' | 'size'>) => Promise<void>;
    deleteCooperation: (ids: Key[]) => Promise<void>;
}

const CooperationContext = createContext<CooperationContext>({} as CooperationContext);

const CooperationProvider: FC<PropsWithChildren> = ({ children }) => {
    const storageService = getStorageService();
    const [cooperation, setCooperation] = useState<PaginationOutput<Cooperation>>({
        count: 0,
        items: null,
    });
    const apiService = getApiService(storageService);
    const cooperationFormApiService = getCooperationApiService(apiService);

    const getCooperation = async (params: GetCooperation) => {
        const cooperationForm = await cooperationFormApiService.getItems({
            ...params,
            country: params.country ? params.country : null,
            type: params.type ? params.type : null,
        });
        setCooperation(cooperationForm.data);
    };

    const deleteCooperation = async (ids: Key[]) => {
        await cooperationFormApiService.deleteMany(ids);
    };

    const getExcel = async (params: Omit<GetCooperation, 'page' | 'size'>) => {
        const base64 = await cooperationFormApiService.getExcel({
            ...params,
            country: params.country ? params.country : null,
            type: params.type ? params.type : null,
        });
        downloadFileFromBase64(base64.data, 'xlsx', 'cooperation');
    };

    const memoValue = useMemo(
        () => ({
            getCooperation,
            cooperation,
            getExcel,
            deleteCooperation,
        }),
        [getCooperation, cooperation]
    );

    return <CooperationContext.Provider value={memoValue}>{children}</CooperationContext.Provider>;
};

export const useCooperation = () => useContext(CooperationContext);

export default CooperationProvider;
