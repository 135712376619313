import React from 'react';
import { Link, Button } from '@kl/components-v6';
import { Minus, Plus } from '@kl/icons/16';
import { GetFile, toDateTime } from 'kl-b2c-ui-kit';
import { colors } from '@kl/components-v6/design-system/theme/themes/dark/colors';
import { ImagePreview, ShowMarkup } from 'components';
import {
    AgreementsChapter,
    DomainType,
    EventFormType,
    LegalType,
    ProfessionGroupType,
    StudyCourse,
    StudyLevel,
    CellRenderType,
    CourseFormType,
    CourseGroupType,
} from 'enums';
import { Text } from './styled';
import { BusyGroup } from 'types';

import { NavigateFunction, Link as RouterLink } from 'react-router-dom';

export const renderCell = (
    value: unknown,
    type: CellRenderType,
    withLink?: { path: string; navigate?: NavigateFunction }
) => {
    switch (type) {
        case CellRenderType.GROUP_ACTION:
            if (!withLink) {
                throw new Error('Provide with link config! (Example in NewsGroup.tsx page)');
            }

            // I have to use here var, instead of const do I can redeclare variables from wihLink destruction
            // eslint-disable-next-line no-var
            var { path, navigate } = withLink;
            const { id, domain, busy, newsId } = value as BusyGroup;

            if (!busy) {
                return (
                    <Button
                        mode={'secondary'}
                        onClick={() => (navigate ? navigate(`${path}/${id}/${domain}`) : undefined)}
                    >
                        Add
                    </Button>
                );
            } else {
                return (
                    <Button
                        mode={'primaryBlack'}
                        onClick={() => (navigate ? navigate(`${path}/${id}/${domain}/${newsId}`) : undefined)}
                    >
                        Show
                    </Button>
                );
            }
        case CellRenderType.LINK:
            if (!withLink) {
                throw new Error('Provide with link config! (Example in NewsGroup.tsx or Cooperations.tsx pages)');
            }

            // I have to use here var, instead of const do I can redeclare variables from wihLink destruction
            // eslint-disable-next-line no-var
            var { path } = withLink;

            return <RouterLink to={`${path}?email=${value}`}>{value as string}</RouterLink>;
        case CellRenderType.BOOLEAN:
            return value ? (
                <Plus color={colors['criticalitystatuses'].positive} />
            ) : (
                <Minus color={colors['criticalitystatuses'].critical} />
            );
        case CellRenderType.DATETIME:
            return <span>{toDateTime(value as string)}</span>;
        case CellRenderType.MARKUP:
            if (!value) return null;
            return <ShowMarkup markUp={value as string} />;
        case CellRenderType.FILE:
            const { fileName, fileLink } = value as GetFile;
            return (
                <Link href={fileLink} target="__blank">
                    {fileName}
                </Link>
            );
        // TODO: This should accept dynamic enum...
        case CellRenderType.CHAPTER:
            return <Text>{AgreementsChapter[value as AgreementsChapter]}</Text>;
        case CellRenderType.IS_LEGAL:
            return <Text>{LegalType[value as LegalType]}</Text>;
        case CellRenderType.DOMAIN_TYPE:
            return <Text>{DomainType[value as DomainType]}</Text>;
        case CellRenderType.PROFESSION_GROUP_TYPE:
            return <Text>{ProfessionGroupType[value as ProfessionGroupType]}</Text>;
        case CellRenderType.COURSE_GROUP_TYPE:
            return <Text>{CourseGroupType[value as CourseGroupType]}</Text>;
        case CellRenderType.IMAGE_PREVIEW:
            const file = value as GetFile;

            if (!file) {
                return <Text>No image has been provided</Text>;
            }

            return <ImagePreview image={file} />;
        case CellRenderType.GROUP:
            return <Text>{value ? (value as { name: string }).name : ''}</Text>;
        case CellRenderType.AGREEMENT_ID:
            return <RouterLink to={`/agreements/${value as string}`}>{value as string}</RouterLink>;
        case CellRenderType.EVENT_ID:
            return <RouterLink to={`/events/all/${value as string}`}>{value as string}</RouterLink>;
        case CellRenderType.ORGANIZATION_ID:
            return <Text>{value as string}</Text>;
        case CellRenderType.COURSE_ID:
            return <RouterLink to={`/courses/all/${value as string}`}>{value as string}</RouterLink>;
        case CellRenderType.EVENT_FORM_TYPE:
            return <Text>{EventFormType[value as EventFormType]}</Text>;
        case CellRenderType.COURSE_FORM_TYPE:
            return <Text>{CourseFormType[value as CourseFormType]}</Text>;
        case CellRenderType.LEVEL_STUDY:
            return <Text>{StudyLevel[value as StudyLevel]}</Text>;
        case CellRenderType.COURSE_STUDY:
            return <Text>{StudyCourse[value as StudyCourse]}</Text>;
        default:
            return <Text>{value as string}</Text>;
    }
};
