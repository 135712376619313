import React, { FC } from 'react';
import { useReviews } from 'contexts';
import { cellRenderMapper, initialColumns } from './mappers';
import { Review, Reviews as ReviewsType } from 'types';
import { renderCell, getTableColumns } from 'utils';
import { PageBuilder } from 'containers';
import { FilterType, PageBuilderAdditionalFilters, PageBuilderKey } from 'enums';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Reviews: FC = () => {
    const { reviews, getReviews, deleteReviews } = useReviews();
    const { i18n } = useTranslation('pages/reviews');
    const navigate = useNavigate();

    return (
        <PageBuilder<Review>
            pageKey={PageBuilderKey.Reviews}
            data={reviews}
            getItems={(params) => getReviews(params as ReviewsType)}
            deleteItems={deleteReviews}
            columns={getTableColumns<Omit<Review, 'id'>>(
                initialColumns,
                cellRenderMapper,
                renderCell,
                i18n.getResourceBundle(i18n.language, 'pages/reviews')
            )}
            additionalFilters={[
                { type: PageBuilderAdditionalFilters.Dropdown, key: FilterType.Domain },
                { type: PageBuilderAdditionalFilters.Dropdown, key: FilterType.IsVisibleHomePage },
                { type: PageBuilderAdditionalFilters.Dropdown, key: FilterType.IsVisibleCorporateBusinessPage },
                { type: PageBuilderAdditionalFilters.Dropdown, key: FilterType.IsVisibleGovernmentPage },
            ]}
            addItem={() => navigate('/review')}
            updateItem={(review: Review) => navigate(`/review/${review.id}`)}
        />
    );
};

export default Reviews;
