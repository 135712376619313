import React, { PropsWithChildren, Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import {
    NotAuthorized,
    SignIn,
    SignOut,
    Agreements,
    Config,
    Cooperations,
    Subscriptions,
    Speakers,
    EventsBase,
    Speaker,
    NewsBase,
    NewsGroup,
    NewsGroups,
    NewsSequence,
    News,
    Events,
    EventGroup,
    EventGroups,
    Event,
    EventApplications,
    EventApplicationsWrapper,
    Course,
    Courses,
    CoursesBase,
    CoursesGroup,
    CoursesGroups,
    CourseApplications,
    CourseApplicationsWrapper,
    Profession,
    Professions,
    ProfessionsBase,
    ProfessionsGroup,
    ProfessionsGroups,
    ProfessionApplications,
    ProfessionApplicationsWrapper,
    Review,
    Reviews,
    Organization,
    Organizations,
    CourseApplication,
    ProfessionApplication,
} from 'containers';
import { Loader } from '@kl/components-v6';
import {
    AgreementsProvider,
    ConfigProvider,
    useAuth,
    CooperationProvider,
    SubscriptionProvider,
    SpeakersProvider,
    SpeakerProvider,
    NewsGroupProvider,
    NewsGroupsProvider,
    NewsSequenceProvider,
    NewsProvider,
    EventsProvider,
    EventGroupProvider,
    EventGroupsProvider,
    EventProvider,
    EventApplicationsProvider,
    CourseGroupProvider,
    CourseGroupsProvider,
    CourseProvider,
    CoursesProvider,
    CourseApplicationsProvider,
    ProfessionGroupProvider,
    ProfessionGroupsProvider,
    ProfessionProvider,
    ProfessionsProvider,
    ProfessionApplicationsProvider,
    ReviewProvider,
    ReviewsProvider,
    OrganizationProvider,
    OrganizationsProvider,
} from 'contexts';
import { useTranslation } from 'react-i18next';

const Router: React.FC<PropsWithChildren> = (): JSX.Element => {
    const { isAuth } = useAuth();
    const { t } = useTranslation('common/shared');

    return (
        <Suspense fallback={<Loader centered size={'large'} tip={t('loading')} />}>
            <Routes>
                {isAuth ? (
                    <>
                        <Route path="/" element={<Navigate to="/agreements" />} />
                        <Route
                            path="/agreements/:id?"
                            element={
                                <AgreementsProvider>
                                    <Agreements />
                                </AgreementsProvider>
                            }
                        />
                        <Route
                            path="/cooperations"
                            element={
                                <CooperationProvider>
                                    <Cooperations />
                                </CooperationProvider>
                            }
                        />
                        <Route
                            path="/subscriptions"
                            element={
                                <SubscriptionProvider>
                                    <Subscriptions />
                                </SubscriptionProvider>
                            }
                        />
                        <Route
                            path="speakers"
                            element={
                                <SpeakersProvider>
                                    <Speakers />
                                </SpeakersProvider>
                            }
                        />
                        {['speaker/', 'speaker/:id'].map((path) => (
                            <Route
                                key={path}
                                path={path}
                                element={
                                    <SpeakerProvider>
                                        <Speaker />
                                    </SpeakerProvider>
                                }
                            />
                        ))}
                        <Route
                            path="reviews"
                            element={
                                <ReviewsProvider>
                                    <Reviews />
                                </ReviewsProvider>
                            }
                        />
                        {['review/', 'review/:id'].map((path) => (
                            <Route
                                key={path}
                                path={path}
                                element={
                                    <ReviewProvider>
                                        <Review />
                                    </ReviewProvider>
                                }
                            />
                        ))}
                        <Route
                            path="organizations"
                            element={
                                <OrganizationsProvider>
                                    <Organizations />
                                </OrganizationsProvider>
                            }
                        />
                        {['organization/', 'organization/:id'].map((path) => (
                            <Route
                                key={path}
                                path={path}
                                element={
                                    <OrganizationProvider>
                                        <Organization />
                                    </OrganizationProvider>
                                }
                            />
                        ))}
                        {['course/:groupId/:domain', 'course/:groupId/:domain/:id?'].map((path) => (
                            <Route
                                key={path}
                                path={path}
                                element={
                                    <CoursesProvider>
                                        <CourseProvider>
                                            <Course />
                                        </CourseProvider>
                                    </CoursesProvider>
                                }
                            />
                        ))}
                        {['course-group/', 'course-group/:id'].map((path) => (
                            <Route
                                key={path}
                                path={path}
                                element={
                                    <CourseGroupProvider>
                                        <CoursesGroup />
                                    </CourseGroupProvider>
                                }
                            />
                        ))}
                        <Route path="courses" element={<CoursesBase />}>
                            <Route
                                path="all/:id?"
                                element={
                                    <CoursesProvider>
                                        <Courses />
                                    </CoursesProvider>
                                }
                            />
                            <Route
                                path="courses-groups"
                                element={
                                    <CourseGroupsProvider>
                                        <CoursesGroups />
                                    </CourseGroupsProvider>
                                }
                            />
                            <Route
                                path="course-applications"
                                element={
                                    <CourseApplicationsProvider>
                                        <CourseApplications />
                                    </CourseApplicationsProvider>
                                }
                            />
                            <Route
                                path="course-applications-by-id/:courseId"
                                element={
                                    <CourseApplicationsProvider>
                                        <CourseApplicationsWrapper />
                                    </CourseApplicationsProvider>
                                }
                            />
                        </Route>
                        <Route
                            path="course-application/:id"
                            element={
                                <CourseApplicationsProvider>
                                    <CourseApplication />
                                </CourseApplicationsProvider>
                            }
                        />
                        {['profession/:groupId/:domain', 'profession/:groupId/:domain/:id?'].map((path) => (
                            <Route
                                key={path}
                                path={path}
                                element={
                                    <ProfessionsProvider>
                                        <ProfessionProvider>
                                            <Profession />
                                        </ProfessionProvider>
                                    </ProfessionsProvider>
                                }
                            />
                        ))}
                        {['profession-group/', 'profession-group/:id'].map((path) => (
                            <Route
                                key={path}
                                path={path}
                                element={
                                    <ProfessionGroupProvider>
                                        <ProfessionsGroup />
                                    </ProfessionGroupProvider>
                                }
                            />
                        ))}
                        <Route path="professions" element={<ProfessionsBase />}>
                            <Route
                                path="all/:id?"
                                element={
                                    <ProfessionsProvider>
                                        <Professions />
                                    </ProfessionsProvider>
                                }
                            />
                            <Route
                                path="professions-groups"
                                element={
                                    <ProfessionGroupsProvider>
                                        <ProfessionsGroups />
                                    </ProfessionGroupsProvider>
                                }
                            />
                            <Route
                                path="profession-applications"
                                element={
                                    <ProfessionApplicationsProvider>
                                        <ProfessionApplications />
                                    </ProfessionApplicationsProvider>
                                }
                            />
                            <Route
                                path="profession-applications-by-id/:professionId"
                                element={
                                    <ProfessionApplicationsProvider>
                                        <ProfessionApplicationsWrapper />
                                    </ProfessionApplicationsProvider>
                                }
                            />
                        </Route>
                        <Route
                            path="profession-application/:id"
                            element={
                                <ProfessionApplicationsProvider>
                                    <ProfessionApplication />
                                </ProfessionApplicationsProvider>
                            }
                        />
                        {['event/:groupId/:domain', 'event/:groupId/:domain/:id?'].map((path) => (
                            <Route
                                key={path}
                                path={path}
                                element={
                                    <EventsProvider>
                                        <EventProvider>
                                            <Event />
                                        </EventProvider>
                                    </EventsProvider>
                                }
                            />
                        ))}
                        {['event-group/', 'event-group/:id'].map((path) => (
                            <Route
                                key={path}
                                path={path}
                                element={
                                    <EventGroupProvider>
                                        <EventGroup />
                                    </EventGroupProvider>
                                }
                            />
                        ))}
                        <Route path="events" element={<EventsBase />}>
                            <Route
                                path="all/:id?"
                                element={
                                    <EventsProvider>
                                        <Events />
                                    </EventsProvider>
                                }
                            />
                            <Route
                                path="event-groups"
                                element={
                                    <EventGroupsProvider>
                                        <EventGroups />
                                    </EventGroupsProvider>
                                }
                            />
                            <Route
                                path="event-applications"
                                element={
                                    <EventApplicationsProvider>
                                        <EventApplications />
                                    </EventApplicationsProvider>
                                }
                            />
                            <Route
                                path="event-applications-by-id/:eventId"
                                element={
                                    <EventApplicationsProvider>
                                        <EventApplicationsWrapper />
                                    </EventApplicationsProvider>
                                }
                            />
                        </Route>

                        {['news-group/', 'news-group/:id'].map((path) => (
                            <Route
                                key={path}
                                path={path}
                                element={
                                    <NewsGroupProvider>
                                        <NewsGroup />
                                    </NewsGroupProvider>
                                }
                            />
                        ))}
                        {['single-news/:groupId/:domain', 'single-news/:groupId/:domain/:id?'].map((path) => (
                            <Route
                                key={path}
                                path={path}
                                element={
                                    <NewsProvider>
                                        <NewsSequenceProvider>
                                            <News />
                                        </NewsSequenceProvider>
                                    </NewsProvider>
                                }
                            />
                        ))}
                        <Route path="news" element={<NewsBase />}>
                            <Route
                                path="all"
                                element={
                                    <NewsSequenceProvider>
                                        <NewsSequence />
                                    </NewsSequenceProvider>
                                }
                            />
                            <Route
                                path="news-groups"
                                element={
                                    <NewsGroupsProvider>
                                        <NewsSequenceProvider>
                                            <NewsGroups />
                                        </NewsSequenceProvider>
                                    </NewsGroupsProvider>
                                }
                            />
                        </Route>
                        {/*
                            <Route
                            path="/config"
                            element={
                                <ConfigProvider>
                                    <Config />
                                </ConfigProvider>
                            }
                        />
                            */}
                    </>
                ) : (
                    <Route path="*" element={<NotAuthorized />} />
                )}
                <Route path="/login" element={<SignIn />} />
                <Route path="/logout" element={<SignOut />} />
            </Routes>
        </Suspense>
    );
};

export default Router;
