import React, { FC } from 'react';
import { useSubscription } from 'contexts';
import { cellRenderMapper, initialColumns } from './mappers';
import { Filters, Subscription } from 'types';
import { renderCell, getTableColumns } from 'utils';
import { PageBuilder } from 'containers';
import { FilterType, PageBuilderAdditionalFilters, PageBuilderKey } from 'enums';
import { useTranslation } from 'react-i18next';

const Subscriptions: FC = () => {
    const { subscription, getSubscription, getExcel, deleteSubscription } = useSubscription();
    const { i18n } = useTranslation('common/shared');

    return (
        <PageBuilder<Subscription>
            pageKey={PageBuilderKey.Subscription}
            data={subscription}
            getItems={(params) => getSubscription(params as Filters)}
            getExcel={(params) => getExcel(params as Omit<Filters, 'page' | 'size'>)}
            deleteItems={deleteSubscription}
            columns={getTableColumns<Omit<Subscription, 'id'>>(
                initialColumns,
                cellRenderMapper,
                (value, type) =>
                    renderCell(value, type, {
                        path: '/agreements',
                    }),
                i18n.getResourceBundle(i18n.language, 'common/shared')
            )}
            additionalFilters={[{ type: PageBuilderAdditionalFilters.DebounceTextBox, key: FilterType.Email }]}
        />
    );
};

export default Subscriptions;
