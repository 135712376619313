import { AddProfession } from 'types';
import { ControlRendererType } from 'enums';
import { RegisterOptions } from 'react-hook-form';
import { GetFile } from 'kl-b2c-ui-kit';
import { fileSize } from 'containers/form-builder/rules';
import { ACCEPTED_IMAGE_FORMATS_EXCEPT_GIF, THREE_MB_IN_BYTES } from 'consts';

export const professionGeneralRendererType = (
    t: (key: string, options?: Record<string, number | string>) => string
): Record<keyof AddProfession, { type: ControlRendererType; rules?: RegisterOptions; size?: number }> => ({
    prevId: {
        type: ControlRendererType.Hidden,
    },
    name: {
        type: ControlRendererType.TextBox,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 255,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 255,
                }),
            },
        },
    },
    directionTypes: {
        type: ControlRendererType.Hidden,
    },
    link: {
        type: ControlRendererType.Hidden,
    },
    baseDescription: {
        type: ControlRendererType.Hidden,
    },
    additionalDescription: {
        type: ControlRendererType.Hidden,
    },
    isVisibleHomePage: {
        type: ControlRendererType.CheckBox,
    },
    announcementDescription: {
        type: ControlRendererType.Wysiwyg,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 1000,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 1000,
                }),
            },
        },
    },
    image: {
        type: ControlRendererType.UploadImageExceptGif,
        size: THREE_MB_IN_BYTES,
        rules: {
            required: {
                value: true,
                message: t('required-image', {
                    ns: 'common/errors',
                }),
            },
            validate: (file: File | GetFile) => {
                return fileSize(file, THREE_MB_IN_BYTES, ACCEPTED_IMAGE_FORMATS_EXCEPT_GIF, t);
            },
        },
    },
    additionalImage: {
        type: ControlRendererType.UploadImageExceptGif,
        size: THREE_MB_IN_BYTES,
        rules: {
            required: {
                value: true,
                message: t('required-image', {
                    ns: 'common/errors',
                }),
            },
            validate: (file: File | GetFile) => {
                return fileSize(file, THREE_MB_IN_BYTES, ACCEPTED_IMAGE_FORMATS_EXCEPT_GIF, t);
            },
        },
    },
    duration: {
        type: ControlRendererType.Hidden,
    },
    studyForms: {
        type: ControlRendererType.Hidden,
    },
    forWhom: {
        type: ControlRendererType.Hidden,
    },
    skill: {
        type: ControlRendererType.Hidden,
    },
    formTypes: {
        type: ControlRendererType.Hidden,
    },
    isVisible: {
        type: ControlRendererType.CheckBox,
    },
    languages: {
        type: ControlRendererType.Hidden,
    },
    groupEnId: {
        type: ControlRendererType.Hidden,
    },
    groupRuId: {
        type: ControlRendererType.Hidden,
    },
    isExistCourse: {
        type: ControlRendererType.Hidden,
    },
    isFree: {
        type: ControlRendererType.Hidden,
    },
});
