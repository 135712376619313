import React, { FC } from 'react';
import { useCourses } from 'contexts';
import { cellRenderMapper, initialColumns } from './mappers';
import { CourseTable, GetCoursesExcelFilter, GetCoursesFilter } from 'types';
import { Button } from '@kl/components-v6';
import { renderCell, getTableColumns } from 'utils';
import { PageBuilder } from 'containers';
import { DomainType, FilterType, PageBuilderAdditionalFilters, PageBuilderKey } from 'enums';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Courses: FC = () => {
    const { items, getItems, getExcel, deleteItems, getItem } = useCourses();
    const { i18n, t } = useTranslation('pages/courses');
    const navigate = useNavigate();

    return (
        <PageBuilder<CourseTable>
            pageKey={PageBuilderKey.Courses}
            data={items}
            getItems={(params) => getItems(params as GetCoursesFilter)}
            getItem={(id: string) => getItem(id)}
            getExcel={(params) => getExcel(params as GetCoursesExcelFilter)}
            deleteItems={deleteItems}
            columns={getTableColumns<CourseTable>(
                initialColumns,
                cellRenderMapper,
                renderCell,
                i18n.getResourceBundle(i18n.language, 'pages/courses')
            )}
            additionalFilters={[
                { type: PageBuilderAdditionalFilters.Dropdown, key: FilterType.DomainType },
                { type: PageBuilderAdditionalFilters.DebounceTextBox, key: FilterType.Name },
            ]}
            updateItem={(course: CourseTable) => {
                const { id, groupEn, groupRu } = course;
                navigate(`/course/${groupEn?.id || groupRu?.id}/${groupRu ? DomainType.Ru : DomainType.En}/${id}`);
            }}
            canDelete={(data) => data.groupEn?.name !== 'free-course' && data.groupRu?.name !== 'free-course'}
            additionalButtons={[
                {
                    title: '',
                    dataIndex: 'delete',
                    key: 'delete',
                    width: '5%',
                    render: (_: unknown, record: CourseTable) => (
                        <Button onClick={() => navigate(`/courses/course-applications-by-id/${record.id}`)}>
                            {t('show-course-applications')}
                        </Button>
                    ),
                },
            ]}
        />
    );
};

export default Courses;
