import React, { FC, useState } from 'react';
import { useProfession, useToaster } from 'contexts';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Controller, FieldValues, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { AddOrUpdateProfessionCondition } from 'types';
import { getSwaggerError } from 'utils/swagger-errors';
import { ConditionCategory, ControlRendererType, FormBuilderKeys } from 'enums';
import { EmptyList } from 'components';
import { FormBuilder } from 'containers/form-builder';

import { Button, Heading, Textbox, Select } from '@kl/components-v6';
import { FormRow } from 'containers/news/news/styled';
import { ArrayContainer } from 'containers/events/event/features/styled';
import { ErrorMessage } from 'containers/form-builder/styled';
import { enumsToOptions } from 'containers/form-builder/mappers';

type FormValues = {
    conditions: AddOrUpdateProfessionCondition[];
};

const ProfessionConditions: FC = () => {
    const { item, professionConditionUpdate, setItem } = useProfession();
    const { setToaster } = useToaster();
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);

    const { t } = useTranslation(['pages/professions', 'common/shared']);

    const methods = useForm<FormValues>({
        defaultValues: {
            conditions:
                item?.conditions?.map((r) => ({
                    name: r.name,
                    id: r.id,
                    category: r.category,
                })) || [],
        },
    });

    const {
        control,
        formState: { errors },
    } = methods;

    const { fields, append, remove } = useFieldArray<FormValues, 'conditions'>({
        control,
        name: 'conditions',
    });

    const onSubmit = async (data: FieldValues) => {
        try {
            setLoading(true);

            if (id) {
                const newItem = await professionConditionUpdate({ professionId: id, items: data.conditions });
                setItem(newItem);
                setToaster({
                    type: 'success',
                    message: t('update-conditions-success'),
                });
            }
        } catch (e) {
            const swagger = getSwaggerError(e);
            if (swagger) {
                setToaster({
                    type: 'error',
                    message: swagger,
                });
            }
            setToaster({
                type: 'error',
                message: t('backend-error', { ns: 'common/shared' }),
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Heading type={'H2'}>{t('update-conditions')}</Heading>
            <FormProvider {...methods}>
                <FormBuilder<FormValues>
                    data={{} as FormValues}
                    submit={onSubmit}
                    cancel={() => navigate('/professions/all')}
                    formKey={FormBuilderKeys.ProfessionGeneral}
                    isFormEmpty={!id}
                    loading={loading}
                >
                    {!fields.length && <EmptyList />}
                    {fields.map((field, index) => {
                        return (
                            <FormRow key={field.id} style={{ margin: '0 0 45px 0' }}>
                                <span>{`Condition ${index + 1}`}</span>

                                <ArrayContainer>
                                    <div style={{ position: 'relative' }}>
                                        <Controller
                                            name={`conditions.${index}.name`}
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: t('required-field', { ns: 'common/errors' }),
                                                },
                                                maxLength: {
                                                    value: 200,
                                                    message: t('max-length', {
                                                        ns: 'common/errors',
                                                        length: 200,
                                                    }),
                                                },
                                            }}
                                            defaultValue={''}
                                            render={({ field: { onChange, value } }) => (
                                                <Textbox
                                                    value={value}
                                                    allowClear
                                                    onChange={onChange}
                                                    placeholder={t('condition-name')}
                                                />
                                            )}
                                        />

                                        {errors?.conditions?.[index]?.name && (
                                            <ErrorMessage style={{ left: 0 }}>
                                                {errors?.conditions?.[index]?.name?.message}
                                            </ErrorMessage>
                                        )}
                                    </div>
                                    <div style={{ position: 'relative' }}>
                                        <Controller
                                            name={`conditions.${index}.category`}
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: t('required-field', { ns: 'common/errors' }),
                                                },
                                            }}
                                            defaultValue={ConditionCategory.Knowledge}
                                            render={({ field: { onChange, value } }) => {
                                                const enumsKeys = enumsToOptions(ControlRendererType.ConditionCategory);
                                                return (
                                                    <Select
                                                        onChange={onChange}
                                                        defaultValue={value}
                                                        error={!!errors?.conditions?.[index]?.category}
                                                        options={enumsKeys}
                                                    />
                                                );
                                            }}
                                        />

                                        {errors?.conditions?.[index]?.category && (
                                            <ErrorMessage style={{ left: 0 }}>
                                                {errors?.conditions?.[index]?.category?.message}
                                            </ErrorMessage>
                                        )}
                                    </div>
                                </ArrayContainer>

                                <Button mode={'dangerOutlined'} onClick={() => remove(index)}>
                                    {t('delete', { ns: 'common/shared' })}
                                </Button>
                            </FormRow>
                        );
                    })}
                    <Button
                        mode={'primaryBlue'}
                        onClick={() =>
                            append({
                                id: null,
                                name: '',
                                category: ConditionCategory.Knowledge,
                            })
                        }
                    >
                        {t('append', { ns: 'common/shared' })}
                    </Button>
                </FormBuilder>
            </FormProvider>
        </>
    );
};

export default ProfessionConditions;
