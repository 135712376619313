import React, { FC } from 'react';
import { useProfessions } from 'contexts';
import { cellRenderMapper, initialColumns } from './mappers';
import { GetProfessionFilter, ProfessionTable } from 'types';
import { Button } from '@kl/components-v6';
import { renderCell, getTableColumns } from 'utils';
import { PageBuilder } from 'containers';
import { DomainType, FilterType, PageBuilderAdditionalFilters, PageBuilderKey } from 'enums';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Professions: FC = () => {
    const { items, getItems, deleteItems, getItem } = useProfessions();
    const { i18n, t } = useTranslation('pages/professions');
    const navigate = useNavigate();

    return (
        <PageBuilder<ProfessionTable>
            pageKey={PageBuilderKey.Professions}
            data={items}
            getItems={(params) => getItems(params as GetProfessionFilter)}
            getItem={(id: string) => getItem(id)}
            deleteItems={deleteItems}
            columns={getTableColumns<ProfessionTable>(
                initialColumns,
                cellRenderMapper,
                renderCell,
                i18n.getResourceBundle(i18n.language, 'pages/professions')
            )}
            noDefaultFilters
            additionalFilters={[
                { type: PageBuilderAdditionalFilters.DebounceTextBox, key: FilterType.Name },
                { type: PageBuilderAdditionalFilters.Dropdown, key: FilterType.DomainType },
            ]}
            updateItem={(profession: ProfessionTable) => {
                const { id, groupEn, groupRu } = profession;
                navigate(`/profession/${groupEn?.id || groupRu?.id}/${groupRu ? DomainType.Ru : DomainType.En}/${id}`);
            }}
            additionalButtons={[
                {
                    title: '',
                    dataIndex: 'delete',
                    key: 'delete',
                    width: '5%',
                    render: (_: unknown, record: ProfessionTable) => (
                        <Button onClick={() => navigate(`/professions/profession-applications-by-id/${record.id}`)}>
                            {t('show-profession-applications')}
                        </Button>
                    ),
                },
            ]}
        />
    );
};

export default Professions;
