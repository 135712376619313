import React, { FC } from 'react';
import { useOrganizations } from 'contexts';
import { cellRenderMapper, initialColumns } from './mappers';
import { Organization, Organizations as OrganizationsType } from 'types';
import { renderCell, getTableColumns } from 'utils';
import { PageBuilder } from 'containers';
import { FilterType, PageBuilderAdditionalFilters, PageBuilderKey } from 'enums';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Organizations: FC = () => {
    const { organizations, getOrganizations, deleteOrganizations } = useOrganizations();
    const { i18n } = useTranslation('pages/organizations');
    const navigate = useNavigate();

    return (
        <PageBuilder<Organization>
            pageKey={PageBuilderKey.Organizations}
            data={organizations}
            getItems={(params) => getOrganizations(params as OrganizationsType)}
            deleteItems={deleteOrganizations}
            columns={getTableColumns<Organization>(
                initialColumns,
                cellRenderMapper,
                renderCell,
                i18n.getResourceBundle(i18n.language, 'pages/organizations')
            )}
            additionalFilters={[
                { type: PageBuilderAdditionalFilters.Dropdown, key: FilterType.Domain },
                { type: PageBuilderAdditionalFilters.Dropdown, key: FilterType.IsVisibleGovernmentPage },
            ]}
            addItem={() => navigate('/organization')}
            updateItem={(organization: Organization) => navigate(`/organization/${organization.id}`)}
        />
    );
};

export default Organizations;
