import { AgreementSelected, IsVisible } from 'enums';

export const dropdownToBoolean = (shown: IsVisible | AgreementSelected | null): boolean | null => {
    switch (shown) {
        case IsVisible.Visible:
        case AgreementSelected.Selected: {
            return true;
        }
        case IsVisible.Hidden:
        case AgreementSelected.NotSelected: {
            return false;
        }
        default: {
            return null;
        }
    }
};
