import React, { createContext, FC, Key, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { getApiService, getEventsApiService, getStorageService } from 'services';
import { Events, GetEventsFilters } from 'types';
import { downloadFileFromBase64, PaginationOutput } from 'kl-b2c-ui-kit';
import { dropdownToBoolean } from 'contexts/mappers';
import { useToaster } from 'contexts/toaster.context';
import { AxiosError } from 'axios';

interface EventsContext {
    events: PaginationOutput<Events>;
    getEvents: (params: GetEventsFilters) => Promise<void>;
    getEvent: (id: string) => Promise<void>;
    getExcel: (params: Omit<GetEventsFilters, 'page' | 'size'>) => Promise<void>;
    deleteEvents: (ids: Key[]) => Promise<void>;
}

const EventsContext = createContext<EventsContext>({} as EventsContext);

const EventsProvider: FC<PropsWithChildren> = ({ children }) => {
    const storageService = getStorageService();
    const [events, setEvents] = useState<PaginationOutput<Events>>({
        count: 0,
        items: null,
    });
    const apiService = getApiService(storageService);
    const eventsApiService = getEventsApiService(apiService);
    const { setToaster } = useToaster();

    const getEvents = async (params: GetEventsFilters) => {
        try {
            const events = await eventsApiService.getItems({
                ...params,
                domainType: params.domainType ? params.domainType : null,
                isVisibleTimeElapsed: dropdownToBoolean(params.isVisibleTimeElapsed),
                isVisible: dropdownToBoolean(params.isVisible),
                isVisibleOnHomePage: dropdownToBoolean(params.isVisibleOnHomePage),
                isRegistrationOpened: dropdownToBoolean(params.isRegistrationOpened),
            });
            setEvents(events.data);
        } catch (e: unknown) {
            setToaster({
                type: 'error',
                message: (e as AxiosError).message,
            });
        }
    };

    const getEvent = async (id: string) => {
        const event = await eventsApiService.getItem(id);
        setEvents({ count: 1, items: [event.data] });
    };

    const deleteEvents = async (ids: Key[]) => {
        await eventsApiService.deleteMany(ids);
    };

    const getExcel = async (params: Omit<GetEventsFilters, 'page' | 'size'>) => {
        const base64 = await eventsApiService.getExcel({
            ...params,
            domainType: params.domainType ? params.domainType : null,
            isVisibleTimeElapsed: dropdownToBoolean(params.isVisibleTimeElapsed),
            isVisible: dropdownToBoolean(params.isVisible),
            isVisibleOnHomePage: dropdownToBoolean(params.isVisibleOnHomePage),
            isRegistrationOpened: dropdownToBoolean(params.isRegistrationOpened),
        });
        downloadFileFromBase64(base64.data, 'xlsx', 'events');
    };

    const memoValue = useMemo(
        () => ({
            getEvents,
            events,
            getExcel,
            deleteEvents,
            getEvent,
        }),
        [getEvents, events, getExcel, deleteEvents, getEvent]
    );

    return <EventsContext.Provider value={memoValue}>{children}</EventsContext.Provider>;
};

export const useEvents = () => useContext(EventsContext);

export default EventsProvider;
