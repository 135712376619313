import { AddCourse } from 'types';
import { ControlRendererType } from 'enums';
import { RegisterOptions } from 'react-hook-form';
import { GetFile } from 'kl-b2c-ui-kit';
import { fileSize } from 'containers/form-builder/rules';
import { ACCEPTED_IMAGE_FORMATS_EXCEPT_GIF, EVENTS_FILES, TEN_MB_IN_BYTES, THREE_MB_IN_BYTES } from 'consts';

export const courseGeneralRendererType = (
    t: (key: string, options?: Record<string, number | string>) => string
): Record<
    keyof AddCourse,
    { type: ControlRendererType; rules?: RegisterOptions; extraKey?: string; size?: number }
> => ({
    name: {
        type: ControlRendererType.TextBox,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 255,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 255,
                }),
            },
        },
    },
    description: {
        type: ControlRendererType.Wysiwyg,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 2000,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 2000,
                }),
            },
        },
    },
    announcementDescription: {
        type: ControlRendererType.Wysiwyg,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 1000,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 1000,
                }),
            },
        },
    },
    image: {
        type: ControlRendererType.UploadImageExceptGif,
        rules: {
            required: {
                value: true,
                message: t('required-image', {
                    ns: 'common/errors',
                }),
            },
            validate: (file: File | GetFile) => {
                return fileSize(file, THREE_MB_IN_BYTES, ACCEPTED_IMAGE_FORMATS_EXCEPT_GIF, t);
            },
        },
        size: THREE_MB_IN_BYTES,
    },
    additionalImage: {
        type: ControlRendererType.UploadImageExceptGif,
        rules: {
            required: {
                value: true,
                message: t('required-image', {
                    ns: 'common/errors',
                }),
            },
            validate: (file: File | GetFile) => {
                return fileSize(file, THREE_MB_IN_BYTES, ACCEPTED_IMAGE_FORMATS_EXCEPT_GIF, t);
            },
        },
        size: THREE_MB_IN_BYTES,
    },
    additionalProgram: {
        type: ControlRendererType.UploadDocsExtra,
        rules: {
            validate: (file: File | GetFile) => {
                return fileSize(file, TEN_MB_IN_BYTES, EVENTS_FILES, t, false);
            },
        },
    },
    duration: {
        type: ControlRendererType.TextBox,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 100,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 100,
                }),
            },
        },
    },
    result: {
        type: ControlRendererType.TextBox,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 100,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 100,
                }),
            },
        },
    },
    whoIsNecessary: {
        type: ControlRendererType.TextBox,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 100,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 100,
                }),
            },
        },
    },
    topic: {
        type: ControlRendererType.Wysiwyg,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 2000,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 2000,
                }),
            },
        },
    },
    practicePart: {
        type: ControlRendererType.Wysiwyg,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 2000,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 2000,
                }),
            },
        },
    },
    studyForms: {
        type: ControlRendererType.Hidden,
    },
    directionTypes: {
        type: ControlRendererType.Hidden,
    },
    forWhom: {
        type: ControlRendererType.Hidden,
    },
    skill: {
        type: ControlRendererType.CourseSkill,
    },
    durationType: {
        type: ControlRendererType.DurationType,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
        },
    },
    courseLevel: {
        type: ControlRendererType.CourseLevel,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
        },
    },
    formTypes: {
        type: ControlRendererType.Hidden,
    },
    languages: {
        type: ControlRendererType.Hidden,
    },
    isAwareness: {
        type: ControlRendererType.CheckBox,
    },
    isVisible: {
        type: ControlRendererType.CheckBox,
    },
    speakers: {
        type: ControlRendererType.Hidden,
    },
    groupEnId: {
        type: ControlRendererType.Hidden,
    },
    groupRuId: {
        type: ControlRendererType.Hidden,
    },
});
