import React, { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEvent, useEvents, useModal, useSettings, useToaster } from 'contexts';
import { ControlRendererType, DomainType, EventType, FormBuilderKeys, ModalType } from 'enums';
import { Controller, FieldValues, FormProvider, useForm } from 'react-hook-form';
import { Heading, Loader, Select, Textbox, Checkbox, Calendar } from '@kl/components-v6';
import { useTranslation } from 'react-i18next';
import { Event as EventModel, EventGeneralAddOrUpdateType, Tag, Speaker } from 'types';
import { FormBuilder } from 'containers';
import { FormRow } from 'containers/news/news/styled';
import { FormRow as FormOld } from 'containers/form-builder/components/form-row';
import { Map } from 'components';
import { enumsToOptions } from 'containers/form-builder/mappers';
import { ErrorMessage } from 'containers/form-builder/styled';
import { getSwaggerError } from 'utils';
import { DefaultEditor } from 'react-simple-wysiwyg';
import { DATE_FORMAT_WITH_TIME } from 'consts/date';

const INITIAL_DATA: Omit<
    EventModel,
    'id' | 'creationDate' | 'teasers' | 'prizeFunds' | 'targetAudiences' | 'phases' | 'results' | 'files' | 'photos'
> = {
    image: null,
    name: '',
    baseDescription: '',
    additionalDescription: '',
    additionalRequirements: '',
    location: '',
    locationEmail: '',
    locationPhone: '',
    targetAudienceDescription: '',
    startDate: null,
    finishDate: null,
    isRegistrationOpened: false,
    isVisible: false,
    isVisibleLocation: false,
    isVisibleTimeElapsed: false,
    eventType: EventType.Offline,
    isVisibleKidsCyberResilience: false,
    locationCity: '',
    locationCountry: '',
    coordinates: {
        latitude: undefined,
        longitude: undefined,
    },
    formTypes: [],
    groupEn: {
        id: '',
        name: '',
        creationDate: new Date(),
        linkEn: '',
        linkRu: '',
    },
    groupRu: {
        id: '',
        name: '',
        creationDate: new Date(),
        linkEn: '',
        linkRu: '',
    },
    speakers: [],
    tags: [],
    programs: [],
};

const General: FC = () => {
    const { event, updateEvent, addEvent, groups, tags, speakers } = useEvent();
    const { deleteEvents } = useEvents();
    const { setToaster } = useToaster();
    const { id, groupId, domain } = useParams();
    const navigate = useNavigate();
    const { settings } = useSettings();
    const { setModal } = useModal();
    const [loading, setLoading] = useState<boolean>(false);

    const { t } = useTranslation(['pages/events', 'common/shared', 'common/errors']);

    const methods =
        useForm<
            Omit<
                EventGeneralAddOrUpdateType,
                'id' | 'creationDate' | 'teasers' | 'prizeFunds' | 'phases' | 'results' | 'files' | 'photos'
            >
        >();

    const {
        control,
        formState: { errors },
        watch,
    } = methods;

    const onSubmit = async (data: FieldValues) => {
        try {
            setLoading(true);

            if (Number(domain as unknown as DomainType) === DomainType.Ru) {
                data = { ...data, groupRuId: groupId };
            } else {
                data = { ...data, groupEnId: groupId };
            }

            if (id) {
                await updateEvent({ ...data, id } as EventGeneralAddOrUpdateType);
                setToaster({
                    type: 'success',
                    message: t('update-general-success'),
                });
            } else {
                const { id } = await addEvent(data as EventGeneralAddOrUpdateType);
                navigate(`/event/${groupId}/${domain}/${id}`);
            }
        } catch (e) {
            const swagger = getSwaggerError(e);
            if (swagger) {
                setToaster({
                    type: 'error',
                    message: swagger,
                });
            }
            setToaster({
                type: 'error',
                message: t('backend-error', { ns: 'common/shared' }),
            });
        } finally {
            setLoading(false);
        }
    };

    if (id && !event) {
        return <Loader centered size={'large'} tip={t('loading', { ns: 'common/shared' })} />;
    }

    const locationWatch = watch('isVisibleLocation')?.valueOf();
    const kidsWatch = watch('isVisibleKidsCyberResilience')?.valueOf();
    const isVisibleWatch = watch('isVisible')?.valueOf();

    return (
        <>
            <Heading type={'H2'}>{id ? t('update-general') : t('add-general')}</Heading>
            <FormProvider {...methods}>
                <FormBuilder<
                    Omit<
                        EventModel,
                        | 'id'
                        | 'creationDate'
                        | 'speakers'
                        | 'tags'
                        | 'teasers'
                        | 'prizeFunds'
                        | 'targetAudiences'
                        | 'phases'
                        | 'results'
                        | 'files'
                        | 'photos'
                    >
                >
                    data={event || INITIAL_DATA}
                    submit={onSubmit}
                    cancel={() => navigate('/events/all')}
                    onDelete={async () => {
                        if (id) {
                            setModal(
                                ModalType.Confirm,
                                <div>{t('delete-confirm', { ns: 'common/shared' })}</div>,
                                async () => {
                                    setLoading(true);

                                    await deleteEvents([id]);

                                    setLoading(false);
                                    navigate('/events/all');
                                }
                            );
                        }
                    }}
                    formKey={FormBuilderKeys.EventGeneral}
                    isFormEmpty={!id}
                    loading={loading}
                >
                    <>
                        <FormRow>
                            {domain && (
                                <>
                                    {Number(domain as unknown as DomainType) === DomainType.Ru ? (
                                        <span>{t('groupRuId', { ns: 'common/shared' })}</span>
                                    ) : (
                                        <span>{t('groupEnId', { ns: 'common/shared' })}</span>
                                    )}
                                    <span>{groups?.filter((group) => group.id === groupId)[0]?.name}</span>
                                </>
                            )}
                        </FormRow>
                        <FormRow>
                            <span>{t('tags', { ns: 'common/shared' })}*</span>
                            <Controller
                                name={'tags'}
                                control={control}
                                // @ts-ignore
                                defaultValue={event?.tags?.map((tag) => tag.id) || []}
                                rules={{
                                    required: {
                                        value: true,
                                        message: t('required-field', {
                                            ns: 'common/errors',
                                        }),
                                    },
                                    validate: (val) => {
                                        if (val.length <= 3) return true;

                                        return t('max-count', {
                                            ns: 'common/errors',
                                            count: 3,
                                        });
                                    },
                                }}
                                render={({ field: { onChange } }) => (
                                    <Select
                                        showSearch
                                        mode={'multiple'}
                                        onChange={onChange}
                                        defaultValue={event?.tags?.map((tag) => tag.id)}
                                        options={tags?.map((option: Tag) => ({
                                            label: option.name,
                                            value: option.id,
                                        }))}
                                    />
                                )}
                            />
                            {errors.tags && <ErrorMessage>{errors.tags.message}</ErrorMessage>}
                        </FormRow>
                        <FormRow>
                            <span>{t('startDate', { ns: 'common/shared' })}*</span>
                            <Controller
                                name={'startDate'}
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: t('required-calendar', {
                                            ns: 'common/errors',
                                        }),
                                    },
                                }}
                                // @ts-ignore
                                defaultValue={event?.startDate ? new Date(event?.startDate) : null}
                                render={({ field: { onChange, value } }) => (
                                    <Calendar
                                        showTime
                                        // @ts-ignore
                                        value={value}
                                        style={{ width: 150 }}
                                        placeholder={t('displayDate', { ns: 'common/shared' })}
                                        format={DATE_FORMAT_WITH_TIME}
                                        allowClear
                                        onOk={(value: Date | null) => onChange(value)}
                                    />
                                )}
                            />
                            {errors.startDate && <ErrorMessage>{errors.startDate.message}</ErrorMessage>}
                        </FormRow>
                        <FormRow>
                            <span>{t('finishDate', { ns: 'common/shared' })}*</span>
                            <Controller
                                name={'finishDate'}
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: t('required-calendar', {
                                            ns: 'common/errors',
                                        }),
                                    },
                                    validate: (val) => {
                                        if (!val) return true;

                                        const startD = watch('startDate')?.valueOf();

                                        if (!startD) return true;

                                        const finishD = val?.valueOf();

                                        if (finishD > startD) return true;

                                        return t(
                                            t('finish-more-than-start', {
                                                ns: 'common/errors',
                                            })
                                        );
                                    },
                                }}
                                // @ts-ignore
                                defaultValue={event?.finishDate ? new Date(event?.finishDate) : null}
                                render={({ field: { onChange, value } }) => (
                                    <Calendar
                                        showTime
                                        // @ts-ignore
                                        value={value}
                                        style={{ width: 150 }}
                                        placeholder={t('displayDate', { ns: 'common/shared' })}
                                        format={DATE_FORMAT_WITH_TIME}
                                        allowClear
                                        onOk={(value: Date | null) => onChange(value)}
                                    />
                                )}
                            />
                            {errors.finishDate && <ErrorMessage>{errors.finishDate.message}</ErrorMessage>}
                        </FormRow>
                        <FormRow>
                            <span>{t('speakers', { ns: 'common/shared' })}</span>
                            <Controller
                                name={'speakers'}
                                control={control}
                                // @ts-ignore
                                defaultValue={event?.speakers?.map((speaker) => speaker.id) || []}
                                render={({ field: { onChange } }) => (
                                    <Select
                                        showSearch
                                        mode={'multiple'}
                                        onChange={onChange}
                                        defaultValue={event?.speakers?.map((speaker) => speaker.id)}
                                        options={speakers
                                            ?.filter((r) => Number(domain as unknown as DomainType) === r.domainType)
                                            ?.map((option: Speaker) => ({
                                                label: `${option.firstName} ${option.lastName}${
                                                    option.position ? ` (${option.position})` : ''
                                                }`,
                                                value: option.id,
                                            }))}
                                    />
                                )}
                            />
                        </FormRow>
                        <FormRow>
                            <span>{t('formTypes', { ns: 'common/shared' })}*</span>
                            <Controller
                                name={'formTypes'}
                                control={control}
                                defaultValue={event?.formTypes || []}
                                rules={{
                                    required: {
                                        value: true,
                                        message: t('required-field', {
                                            ns: 'common/errors',
                                        }),
                                    },
                                }}
                                render={({ field: { onChange } }) => {
                                    const enumsKeys = enumsToOptions(ControlRendererType.FormTypes);
                                    return (
                                        <Select
                                            showSearch
                                            mode={'multiple'}
                                            onChange={onChange}
                                            defaultValue={event?.formTypes || []}
                                            options={enumsKeys}
                                        />
                                    );
                                }}
                            />
                            {errors.formTypes && <ErrorMessage>{errors.formTypes.message}</ErrorMessage>}
                        </FormRow>

                        <FormOld label={t('isVisible', { ns: 'common/shared' })}>
                            <Controller
                                name="isVisible"
                                control={control}
                                defaultValue={event?.isVisible ?? false}
                                render={({ field: { onChange, value } }) => (
                                    <Checkbox defaultChecked={value} onChange={onChange} />
                                )}
                            />
                        </FormOld>

                        {isVisibleWatch && (
                            <FormOld label={t('isVisibleKidsCyberResilience', { ns: 'common/shared' })}>
                                <Controller
                                    name="isVisibleKidsCyberResilience"
                                    control={control}
                                    defaultValue={event?.isVisibleKidsCyberResilience ?? false}
                                    render={({ field: { onChange, value } }) => (
                                        <Checkbox defaultChecked={value} onChange={onChange} />
                                    )}
                                />
                            </FormOld>
                        )}

                        {kidsWatch && isVisibleWatch && (
                            <>
                                <FormOld label={t('locationCity', { ns: 'common/shared' })}>
                                    <Controller
                                        name="locationCity"
                                        control={control}
                                        defaultValue={event?.locationCity ?? ''}
                                        rules={{
                                            maxLength: {
                                                value: 50,
                                                message: t('max-length', {
                                                    ns: 'common/errors',
                                                    length: 50,
                                                }),
                                            },
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <Textbox
                                                value={value}
                                                invalid={!!errors['locationCity']?.message}
                                                allowClear
                                                onChange={onChange}
                                                placeholder={t('locationCity', { ns: 'common/shared' })}
                                            />
                                        )}
                                    />
                                    {errors.locationCity && <ErrorMessage>{errors.locationCity.message}</ErrorMessage>}
                                </FormOld>

                                <FormOld label={t('locationCountry', { ns: 'common/shared' })}>
                                    <Controller
                                        name="locationCountry"
                                        control={control}
                                        defaultValue={event?.locationCountry ?? ''}
                                        rules={{
                                            maxLength: {
                                                value: 50,
                                                message: t('max-length', {
                                                    ns: 'common/errors',
                                                    length: 50,
                                                }),
                                            },
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <Textbox
                                                value={value}
                                                invalid={!!errors['locationCountry']?.message}
                                                allowClear
                                                onChange={onChange}
                                                placeholder={t('locationCountry', { ns: 'common/shared' })}
                                            />
                                        )}
                                    />
                                    {errors.locationCountry && (
                                        <ErrorMessage>{errors.locationCountry.message}</ErrorMessage>
                                    )}
                                </FormOld>
                                <FormOld label={t('eventType', { ns: 'common/shared' })}>
                                    <Controller
                                        name={'eventType'}
                                        control={control}
                                        //@ts-ignore
                                        defaultValue={event?.eventType ?? ''}
                                        render={({ field: { onChange } }) => (
                                            <Select
                                                defaultValue={event?.eventType ?? ''}
                                                onSelect={(value) => onChange(value as string)}
                                                options={enumsToOptions(ControlRendererType.EventType)}
                                                allowClear
                                                onClear={() => onChange('')}
                                            />
                                        )}
                                    />
                                </FormOld>
                            </>
                        )}

                        <FormOld label={t('isVisibleLocation', { ns: 'common/shared' })}>
                            <Controller
                                name="isVisibleLocation"
                                control={control}
                                defaultValue={event?.isVisibleLocation ?? false}
                                render={({ field: { onChange, value } }) => (
                                    <Checkbox defaultChecked={value} onChange={onChange} />
                                )}
                            />
                        </FormOld>

                        {locationWatch && (
                            <>
                                <FormOld label={t('location', { ns: 'common/shared' })} required>
                                    <Controller
                                        name="location"
                                        control={control}
                                        defaultValue={event?.location ?? ''}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: t('required-field', {
                                                    ns: 'common/errors',
                                                }),
                                            },
                                            maxLength: {
                                                value: 255,
                                                message: t('max-length', {
                                                    ns: 'common/errors',
                                                    length: 255,
                                                }),
                                            },
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <Textbox
                                                value={value}
                                                invalid={!!errors['location']?.message}
                                                allowClear
                                                onChange={onChange}
                                                placeholder={t('location', { ns: 'common/shared' })}
                                            />
                                        )}
                                    />
                                    {errors.location && <ErrorMessage>{errors.location.message}</ErrorMessage>}
                                </FormOld>

                                <FormOld label={t('locationPhone', { ns: 'common/shared' })}>
                                    <Controller
                                        name="locationPhone"
                                        control={control}
                                        defaultValue={event?.locationPhone ?? ''}
                                        rules={{
                                            maxLength: {
                                                value: 255,
                                                message: t('max-length', {
                                                    ns: 'common/errors',
                                                    length: 255,
                                                }),
                                            },
                                            validate: (val: string | null) => {
                                                if (!val) return true;

                                                const valid = /^\d{7,}$/.test(val.replace(/[\s()+\-.]|ext/gi, ''));

                                                if (!valid) {
                                                    return t('phone-format', {
                                                        ns: 'common/errors',
                                                    });
                                                }

                                                return true;
                                            },
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <Textbox
                                                value={value}
                                                invalid={!!errors['locationPhone']?.message}
                                                allowClear
                                                onChange={onChange}
                                                placeholder={t('locationPhone', { ns: 'common/shared' })}
                                            />
                                        )}
                                    />
                                    {errors.locationPhone && (
                                        <ErrorMessage>{errors.locationPhone.message}</ErrorMessage>
                                    )}
                                </FormOld>

                                <FormOld label={t('locationEmail', { ns: 'common/shared' })}>
                                    <Controller
                                        name="locationEmail"
                                        control={control}
                                        defaultValue={event?.locationEmail ?? ''}
                                        rules={{
                                            maxLength: {
                                                value: 255,
                                                message: t('max-length', {
                                                    ns: 'common/errors',
                                                    length: 255,
                                                }),
                                            },
                                            pattern: {
                                                value: /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/,
                                                message: t('email-format', {
                                                    ns: 'common/errors',
                                                }),
                                            },
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <Textbox
                                                value={value}
                                                invalid={!!errors['locationEmail']?.message}
                                                allowClear
                                                onChange={onChange}
                                                placeholder={t('locationEmail', { ns: 'common/shared' })}
                                            />
                                        )}
                                    />
                                    {errors.locationEmail && (
                                        <ErrorMessage>{errors.locationEmail.message}</ErrorMessage>
                                    )}
                                </FormOld>

                                <FormRow>
                                    <span>{t('coordinates', { ns: 'common/shared' })}</span>
                                    <Controller
                                        name="coordinates"
                                        control={control}
                                        defaultValue={event?.coordinates}
                                        render={({ field: { onChange } }) => (
                                            <Map
                                                defaultValue={
                                                    event?.coordinates ?? {
                                                        latitude: undefined,
                                                        longitude: undefined,
                                                    }
                                                }
                                                onChange={onChange}
                                                mapApi={settings!.yandexMapApi}
                                            />
                                        )}
                                    />
                                </FormRow>
                            </>
                        )}
                    </>
                </FormBuilder>
            </FormProvider>
        </>
    );
};

export default General;
