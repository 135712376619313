import React, { FC, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEvent, useToaster } from 'contexts';
import { FormBuilderKeys } from 'enums';
import { Controller, FieldValues, FormProvider, useForm } from 'react-hook-form';
import { Loader, Heading } from '@kl/components-v6';
import { useTranslation } from 'react-i18next';
import { FormBuilder } from 'containers';
import { FormRow } from 'containers/news/news/styled';
import { ImagePreviews } from 'components/gallery-uploader/styled';
import { EmptyList, GalleryUploader, ImagePreview } from '../../../../components';
import { ImageListType } from 'react-images-uploading';
import { Photo } from 'types';

type FormValues = {
    photos: Omit<Photo, 'id' | 'creationDate'>[];
};

const Gallery: FC = () => {
    const { event, updateParticleWithFiles } = useEvent();
    const { setToaster } = useToaster();
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [candidates, setCandidates] = useState<ImageListType>([]);
    const [outerPhotos, setOuterPhotos] = useState<Photo[]>(event?.photos || []);

    const { t } = useTranslation(['pages/events', 'common/shared']);

    const methods = useForm<FormValues>({ defaultValues: { photos: event?.photos || [] } });

    const { control } = methods;

    const onSubmit = async (data: FieldValues) => {
        setLoading(true);

        if (id) {
            setToaster({
                type: 'info',
                message: t('add-photo-to-gallery', { ns: 'common/shared' }),
            });
            await updateParticleWithFiles<Photo>(
                {
                    eventId: id,
                    items: [
                        ...outerPhotos,
                        ...data.photos.map((photo: Photo) => ({ image: photo, id: window.crypto.randomUUID() })),
                    ],
                },
                'updatePhotoGalleries'
            );
            setCandidates([]);
            setToaster({
                type: 'success',
                message: t('update-photos-success'),
            });
        }

        setLoading(false);
    };

    useEffect(() => {
        if (event && event.photos) {
            setOuterPhotos(event?.photos);
        }
    }, [event?.photos]);

    if (id && !event?.photos) {
        return <Loader centered size={'large'} tip={t('loading', { ns: 'common/shared' })} />;
    }

    return (
        <>
            <Heading type={'H2'}>{t('update-photos')}</Heading>
            <FormProvider {...methods}>
                <FormBuilder<FormValues>
                    data={{} as FormValues}
                    submit={onSubmit}
                    cancel={() => navigate('/events/all')}
                    formKey={FormBuilderKeys.EventGeneral}
                    isFormEmpty={!id}
                    loading={loading}
                >
                    <FormRow>
                        <span>
                            {t('photos', { ns: 'common/shared' })}
                            {!outerPhotos.length && <EmptyList />}
                        </span>
                        <Controller
                            name={'photos'}
                            control={control}
                            render={({ field: { onChange } }) => (
                                <div>
                                    <ImagePreviews>
                                        {outerPhotos.map((photo) => (
                                            <ImagePreview
                                                key={photo.id}
                                                clear={() => {
                                                    setOuterPhotos((prevState) => {
                                                        const filtered = prevState.filter(
                                                            (candidate) => candidate.id !== photo.id
                                                        );
                                                        onChange(candidates.map((image) => image.file) || []);

                                                        return filtered;
                                                    });
                                                }}
                                                size={150}
                                                image={photo.image}
                                            />
                                        ))}
                                    </ImagePreviews>

                                    <GalleryUploader
                                        photos={candidates}
                                        onChange={(imageList) => {
                                            setCandidates(imageList);
                                            onChange(imageList.map((image) => image.file));
                                        }}
                                    />
                                </div>
                            )}
                        />
                    </FormRow>
                </FormBuilder>
            </FormProvider>
        </>
    );
};

export default Gallery;
