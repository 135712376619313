import React, { createContext, FC, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { getApiService, getNewsGroupsApiService, getStorageService } from 'services';
import { Group } from 'types';

interface NewsGroupContext {
    newsGroup: Omit<Group, 'id' | 'creationDate'> | null;
    getNewsGroup: (id: string) => void;
    addNewsGroup: (data: Omit<Group, 'id' | 'creationDate'>) => void;
    updateNewsGroup: (data: Omit<Group, 'creationDate'>) => void;
}

const NewsGroupContext = createContext<NewsGroupContext>({} as NewsGroupContext);

const NewsGroupProvider: FC<PropsWithChildren> = ({ children }) => {
    const storageService = getStorageService();
    const [newsGroup, setNewsGroup] = useState<Omit<Group, 'id' | 'creationDate'> | null>(null);
    const apiService = getApiService(storageService);
    const newsGroupsApiService = getNewsGroupsApiService(apiService);

    const getNewsGroup = async (id: string) => {
        const newsGroups = await newsGroupsApiService.get(id);
        setNewsGroup(newsGroups.data);
    };

    const addNewsGroup = async (data: Omit<Group, 'id' | 'creationDate'>) => {
        const newsGroups = await newsGroupsApiService.add(data);
        setNewsGroup(newsGroups.data);
    };

    const updateNewsGroup = async (data: Omit<Group, 'creationDate'>) => {
        const newsGroups = await newsGroupsApiService.update(data);
        setNewsGroup(newsGroups.data);
    };

    const memoValue = useMemo(
        () => ({
            newsGroup,
            getNewsGroup,
            addNewsGroup,
            updateNewsGroup,
        }),
        [getNewsGroup, newsGroup, addNewsGroup, updateNewsGroup]
    );

    return <NewsGroupContext.Provider value={memoValue}>{children}</NewsGroupContext.Provider>;
};

export const useNewsGroup = () => useContext(NewsGroupContext);

export default NewsGroupProvider;
