import React, { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCourse, useEvent, useToaster } from 'contexts';
import { FormBuilderKeys } from 'enums';
import { Controller, FieldValues, FormProvider, useForm, useFieldArray } from 'react-hook-form';
import { Loader, Button, Heading, Textbox } from '@kl/components-v6';
import { useTranslation } from 'react-i18next';
import { AddOrUpdateCourseResult, Result } from 'types';
import { FormBuilder } from 'containers';
import { FormRow } from 'containers/news/news/styled';
import { DefaultEditor } from 'react-simple-wysiwyg';
import { ErrorMessage } from 'containers/form-builder/styled';
import { GetFile } from 'kl-b2c-ui-kit';
import { EmptyList, ImagePreview, ImageUploader } from 'components';
import { ACCEPTED_IMAGE_FORMATS_EXCEPT_GIF, DATE_FORMAT_WITH_TIME, TEN_MB_IN_BYTES } from 'consts';
import { ArrayContainer } from '../../../events/event/features/styled';
import { fileSize } from 'containers/form-builder/rules';
import moment from 'moment';

type FormValues = {
    results: AddOrUpdateCourseResult[];
};

const Results: FC = () => {
    const { item, updateResults, setItem } = useCourse();
    const { setToaster } = useToaster();
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);

    const { t } = useTranslation(['pages/courses', 'common/shared']);

    const methods = useForm<FormValues>({ defaultValues: { results: item?.results || [] } });

    const {
        control,
        formState: { errors },
    } = methods;

    const { fields, append, remove } = useFieldArray<FormValues, 'results'>({
        control,
        name: 'results',
    });

    const onSubmit = async (data: FieldValues) => {
        setLoading(true);

        if (id) {
            const result = await updateResults({ courseId: id, items: data.results });
            setItem(result);
            setToaster({
                type: 'success',
                message: t('update-results-success'),
            });
        }

        setLoading(false);
    };

    if (id && !item?.results) {
        return <Loader centered size={'large'} tip={t('loading', { ns: 'common/shared' })} />;
    }

    return (
        <>
            <Heading type={'H2'}>{t('update-results')}</Heading>
            <FormProvider {...methods}>
                <FormBuilder<FormValues>
                    data={{} as FormValues}
                    submit={onSubmit}
                    cancel={() => navigate('/courses/all')}
                    formKey={FormBuilderKeys.CourseGeneral}
                    isFormEmpty={!id}
                    loading={loading}
                >
                    <>
                        {!fields.length && <EmptyList />}
                        {fields.map((field, index) => {
                            return (
                                <FormRow key={field.id} style={{ margin: '0 0 45px 0' }}>
                                    <span>
                                        {`Result ${index + 1}`}
                                        <br />
                                        {field?.creationDate &&
                                            moment(field?.creationDate).format(DATE_FORMAT_WITH_TIME)}
                                    </span>

                                    <ArrayContainer>
                                        <div style={{ position: 'relative' }}>
                                            <Controller
                                                name={`results.${index}.name`}
                                                control={control}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: t('required-field', {
                                                            ns: 'common/errors',
                                                        }),
                                                    },
                                                    maxLength: {
                                                        value: 100,
                                                        message: t('max-length', {
                                                            ns: 'common/errors',
                                                            length: 100,
                                                        }),
                                                    },
                                                }}
                                                defaultValue={''}
                                                render={({ field: { onChange, value } }) => (
                                                    <Textbox
                                                        value={value}
                                                        allowClear
                                                        onChange={onChange}
                                                        placeholder={t('result-name')}
                                                    />
                                                )}
                                            />

                                            {errors?.results?.[index]?.name && (
                                                <ErrorMessage style={{ left: 0 }}>
                                                    {errors?.results?.[index]?.name?.message}
                                                </ErrorMessage>
                                            )}
                                        </div>

                                        <div style={{ position: 'relative' }}>
                                            <Controller
                                                name={`results.${index}.description`}
                                                control={control}
                                                rules={{
                                                    required: {
                                                        value: false,
                                                        message: t('required-field', {
                                                            ns: 'common/errors',
                                                        }),
                                                    },
                                                    maxLength: {
                                                        value: 500,
                                                        message: t('max-length', {
                                                            ns: 'common/errors',
                                                            length: 500,
                                                        }),
                                                    },
                                                }}
                                                defaultValue={''}
                                                render={({ field: { onChange, value } }) => (
                                                    <DefaultEditor
                                                        containerProps={{ style: { width: '100%' } }}
                                                        value={value as string}
                                                        onChange={(event) => onChange(event.target.value)}
                                                    />
                                                )}
                                            />

                                            {errors?.results?.[index]?.description && (
                                                <ErrorMessage style={{ left: 0 }}>
                                                    {errors?.results?.[index]?.description?.message}
                                                </ErrorMessage>
                                            )}
                                        </div>

                                        <div style={{ position: 'relative' }}>
                                            <Controller
                                                name={`results.${index}.image`}
                                                control={control}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: t('required-image', { ns: 'common/errors' }),
                                                    },
                                                    validate: (file: File | GetFile | null) => {
                                                        return fileSize(
                                                            file,
                                                            TEN_MB_IN_BYTES,
                                                            ACCEPTED_IMAGE_FORMATS_EXCEPT_GIF,
                                                            t
                                                        );
                                                    },
                                                }}
                                                defaultValue={null}
                                                render={({ field: { onChange, value } }) => {
                                                    if ((value as GetFile)?.id) {
                                                        return (
                                                            <ImagePreview
                                                                clear={() => onChange(null)}
                                                                size={150}
                                                                image={value as GetFile}
                                                            />
                                                        );
                                                    }

                                                    return (
                                                        <ImageUploader
                                                            maxSize={TEN_MB_IN_BYTES}
                                                            accept={ACCEPTED_IMAGE_FORMATS_EXCEPT_GIF}
                                                            change={onChange}
                                                        />
                                                    );
                                                }}
                                            />

                                            {errors?.results?.[index]?.image && (
                                                <ErrorMessage style={{ left: 0 }}>
                                                    {errors?.results?.[index]?.image?.message ?? ''}
                                                </ErrorMessage>
                                            )}
                                        </div>
                                    </ArrayContainer>

                                    <Button mode={'dangerOutlined'} onClick={() => remove(index)}>
                                        {t('delete', { ns: 'common/shared' })}
                                    </Button>
                                </FormRow>
                            );
                        })}
                        <Button
                            mode={'primaryBlue'}
                            onClick={() =>
                                append({
                                    name: '',
                                    description: '',
                                    id: null,
                                    image: null,
                                })
                            }
                        >
                            {t('append', { ns: 'common/shared' })}
                        </Button>
                    </>
                </FormBuilder>
            </FormProvider>
        </>
    );
};

export default Results;
