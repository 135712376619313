import { getBaseSingularContext } from 'contexts';
import { useContext } from 'react';
import { getApiService, getProfessionGroupsApiService, getStorageService } from 'services';
import {
    AddProfessionGroup,
    GetProfessionGroupsExcelFilter,
    GetProfessionGroupsFilter,
    ProfessionGroup,
    UpdateProfessionGroup,
} from 'types';

const storageService = getStorageService();
const apiService = getApiService(storageService);
const professionGroupService = getProfessionGroupsApiService(apiService);

const ProviderResult = getBaseSingularContext<
    GetProfessionGroupsFilter,
    GetProfessionGroupsExcelFilter,
    ProfessionGroup,
    ProfessionGroup,
    AddProfessionGroup,
    UpdateProfessionGroup
>(professionGroupService);

export const useProfessionGroup = () => useContext(ProviderResult.Context);
export default ProviderResult.Provider;
