import { Review } from 'types';
import { ControlRendererType } from 'enums';
import { RegisterOptions } from 'react-hook-form';
import { ACCEPTED_IMAGE_FORMATS, ACCEPTED_IMAGE_FORMATS_EXCEPT_GIF_SWG, TEN_MB_IN_BYTES } from 'consts';
import { GetFile } from 'kl-b2c-ui-kit';
import { fileSize } from 'containers/form-builder/rules';

export const reviewRendererTypes = (
    t: (key: string, options?: Record<string, number | string>) => string
): Record<
    keyof Omit<Review, 'id' | 'creationDate'>,
    { type: ControlRendererType; rules?: RegisterOptions; extraKey?: string }
> => ({
    firstName: {
        type: ControlRendererType.TextBox,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 255,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 255,
                }),
            },
        },
    },
    lastName: {
        type: ControlRendererType.TextBox,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 255,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 255,
                }),
            },
        },
    },
    position: {
        type: ControlRendererType.TextBox,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 255,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 255,
                }),
            },
        },
    },
    text: {
        type: ControlRendererType.Wysiwyg,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 350,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 350,
                }),
            },
        },
    },
    image: {
        type: ControlRendererType.UploadImageExceptGifSwgExtra,
        extraKey: 'file',
        rules: {
            validate: (file: File | GetFile) => {
                return fileSize(file, TEN_MB_IN_BYTES, ACCEPTED_IMAGE_FORMATS_EXCEPT_GIF_SWG, t, false);
            },
        },
    },
    domain: {
        type: ControlRendererType.DomainType,
    },
    isVisibleCorporateBusinessPage: {
        type: ControlRendererType.CheckBox,
    },
    isVisibleHomePage: {
        type: ControlRendererType.CheckBox,
    },
    isVisibleGovernmentPage: {
        type: ControlRendererType.CheckBox,
    },
});
