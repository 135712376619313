import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useReview, useToaster } from 'contexts';
import { DomainType, FormBuilderKeys } from 'enums';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { Heading, Loader } from '@kl/components-v6';
import { useTranslation } from 'react-i18next';
import { AddOrUpdateReview, Review as ReviewType } from 'types';
import { FormBuilder } from 'containers';
import { getSwaggerError } from 'utils/swagger-errors';
import { GetFile } from 'kl-b2c-ui-kit';

const INITIAL_DATA: Omit<ReviewType, 'id' | 'creationDate'> = {
    firstName: '',
    lastName: '',
    position: '',
    text: '',
    isVisibleCorporateBusinessPage: false,
    isVisibleGovernmentPage: false,
    isVisibleHomePage: false,
    domain: DomainType.Ru,
    image: {} as GetFile,
};

const Review: FC = () => {
    const { getReview, review, updateReview, addReview } = useReview();
    const { id } = useParams();
    const navigate = useNavigate();
    const { setToaster } = useToaster();
    const [loading, setLoading] = useState<boolean>(false);

    const { t } = useTranslation(['pages/reviews', 'common/shared']);

    const methods = useForm<Omit<ReviewType, 'id' | 'creationDate'>>();

    const onSubmit = async (data: FieldValues) => {
        setLoading(true);
        try {
            if (id) {
                await updateReview({ ...data, id } as AddOrUpdateReview);
            } else {
                await addReview(data as Omit<AddOrUpdateReview, 'id'>);
            }
            setLoading(false);
            navigate('/reviews');
        } catch (e) {
            const swagger = getSwaggerError(e);
            if (swagger) {
                setToaster({
                    type: 'error',
                    message: swagger,
                });
                return;
            }
            setToaster({
                type: 'error',
                message: t('backend-error', { ns: 'common/shared' }),
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (id) {
            getReview(id);
        }
    }, [id]);

    if (id && !review) {
        return <Loader centered size={'large'} tip={t('loading', { ns: 'common/shared' })} />;
    }

    return (
        <>
            <Heading type={'H2'}>{id ? t('update-review') : t('add-review')}</Heading>
            <FormProvider {...methods}>
                <FormBuilder<Omit<ReviewType, 'id' | 'creationDate'>>
                    data={review || INITIAL_DATA}
                    submit={onSubmit}
                    cancel={() => navigate('/reviews')}
                    formKey={FormBuilderKeys.Review}
                    isFormEmpty={!id}
                    loading={loading}
                />
            </FormProvider>
        </>
    );
};

export default Review;
