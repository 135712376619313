import React from 'react';
import { MenuItem } from 'types';
import {
    Active,
    AddContactActivSolid,
    FavoriteSolid,
    Event,
    Settings,
    Tv,
    Sun,
    PhotoFrame,
    Amazon,
    Abstract,
    Apps,
} from '@kl/icons/16';

export const MENU_ITEMS: MenuItem[] = [
    {
        icon: <Active />,
        label: 'Agreements',
        key: '/agreements',
    },
    {
        icon: <AddContactActivSolid />,
        label: 'Cooperations',
        key: '/cooperations',
    },
    {
        icon: <FavoriteSolid />,
        label: 'Subscriptions',
        key: '/subscriptions',
    },
    {
        icon: <Event />,
        label: 'Events',
        key: '/events/all',
    },
    {
        icon: <Sun />,
        label: 'Courses',
        key: '/courses/all',
    },
    {
        icon: <Amazon />,
        label: 'Professions',
        key: '/professions/all',
    },
    {
        icon: <PhotoFrame />,
        label: 'Speakers',
        key: '/speakers',
    },
    {
        icon: <Abstract />,
        label: 'Reviews',
        key: '/reviews',
    },
    {
        icon: <Apps />,
        label: 'Organizations',
        key: '/organizations',
    },
    {
        icon: <Tv />,
        label: 'News',
        key: '/news/all',
    },
    /*{
        icon: <Settings />,
        label: 'Config',
        key: '/config',
    },*/
];
