import styled from 'styled-components';

export const Gallery = styled.div``;

export const ImagePreviews = styled.div`
    margin: 0 0 50px 0;
    width: 800px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
`;

export const ActionButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin: 0 0 20px 0;
`;

export const UploadedImagesContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const UploadedImagePreviewContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
`;
