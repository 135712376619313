import React, { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEvent, useToaster } from 'contexts';
import { FormBuilderKeys } from 'enums';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { Loader, Heading } from '@kl/components-v6';
import { useTranslation } from 'react-i18next';
import { Program } from 'types';
import { FormBuilder } from 'containers';
import ProgramsBase from './ProgramBase';

type FormValues = {
    programs: Omit<Program, 'id' | 'eventProgramEvents'>[];
};

const initialData: Omit<Program, 'id' | 'eventProgramEvents'>[] = [
    {
        name: '',
        description: '',
        startTime: null,
        creationDate: new Date(),
    },
];

const Programs: FC = () => {
    const { event, updateParticle } = useEvent();
    const { setToaster } = useToaster();
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);

    const { t } = useTranslation(['pages/events', 'common/shared']);
    const methods = useForm<FormValues>({
        defaultValues: { programs: event?.programs && event.programs.length > 0 ? event?.programs : initialData },
    });

    const { control } = methods;

    const onSubmit = async (data: FieldValues) => {
        setLoading(true);

        if (id) {
            await updateParticle<Program>({ eventId: id, items: data.programs }, 'updateProgram');
            setToaster({
                type: 'success',
                message: t('update-programs-success'),
            });
        }

        setLoading(false);
    };

    if (id && !event?.programs) {
        return <Loader centered size={'large'} tip={t('loading', { ns: 'common/shared' })} />;
    }

    return (
        <>
            <Heading type={'H2'}>{t('update-programs')}</Heading>
            <FormProvider {...methods}>
                <FormBuilder<FormValues>
                    data={{} as FormValues}
                    submit={onSubmit}
                    cancel={() => navigate('/events/all')}
                    formKey={FormBuilderKeys.EventGeneral}
                    isFormEmpty={!id}
                    loading={loading}
                >
                    {/*// @ts-ignore*/}
                    <ProgramsBase control={control} />
                </FormBuilder>
            </FormProvider>
        </>
    );
};

export default Programs;
