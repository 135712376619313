import styled from 'styled-components';

export const FormWrapper = styled.form`
    padding: 20px 0;
    max-width: 750px;

    &.disabled {
        opacity: 0.6;
        pointer-events: none;
    }
`;
