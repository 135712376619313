import React from 'react';
import { CellRenderType, CourseGroupType } from 'enums';
import { CourseGroup } from 'types';

export const initialColumns: Omit<CourseGroup, 'linkEn' | 'linkRu'> = {
    id: '',
    name: '',
    creationDate: new Date(),
    busyEn: false,
    busyRu: false,
    type: CourseGroupType.None,
};

export const cellRenderMapper: Record<keyof Omit<CourseGroup, 'linkEn' | 'linkRu'>, CellRenderType> = {
    id: CellRenderType.TEXT,
    name: CellRenderType.TEXT,
    creationDate: CellRenderType.DATETIME,
    busyEn: CellRenderType.GROUP_ACTION,
    busyRu: CellRenderType.GROUP_ACTION,
    type: CellRenderType.COURSE_GROUP_TYPE,
};
