import React from 'react';
import { ProfessionApplicationShort } from 'types';
import { DomainType, CourseFormType, CellRenderType } from 'enums';

export type ProfessionApplicationTable = ProfessionApplicationShort;

export const initialColumns: ProfessionApplicationTable = {
    id: '',
    creationDate: new Date(),
    firstName: '',
    lastName: '',
    email: '',
    formType: CourseFormType.Company,
    domainType: DomainType.Ru,
    professionName: '',
    marketingIsSelected: false,
    agreementId: '',
};

export const cellRenderMapper: Record<keyof ProfessionApplicationShort, CellRenderType> = {
    id: CellRenderType.TEXT,
    creationDate: CellRenderType.DATETIME,
    firstName: CellRenderType.TEXT,
    lastName: CellRenderType.TEXT,
    email: CellRenderType.LINK,
    formType: CellRenderType.COURSE_FORM_TYPE,
    domainType: CellRenderType.DOMAIN_TYPE,
    professionName: CellRenderType.TEXT,
    marketingIsSelected: CellRenderType.BOOLEAN,
    agreementId: CellRenderType.AGREEMENT_ID,
};
