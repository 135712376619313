import { IApiService, IBaseApi } from 'interfaces';
import { PaginationOutput, serializeUriParams, omit } from 'kl-b2c-ui-kit';
import { ApiBaseKeys } from 'enums';
import { Key } from 'react';
import { HttpResponse } from 'types';

export class BaseApi<TParams, TType> implements IBaseApi<TParams, TType> {
    protected readonly _basePath: ApiBaseKeys;

    constructor(protected readonly apiService: IApiService, private readonly path: ApiBaseKeys) {
        this._basePath = path;
    }

    public getItems(params: TParams): Promise<HttpResponse<PaginationOutput<TType>>> {
        return this.apiService.request<PaginationOutput<TType>>({
            method: 'GET',
            url: `/${this.basePath}/getItems`,
            params,
        });
    }

    public getItem(id: string): Promise<HttpResponse<TType>> {
        return this.apiService.request<TType>({
            method: 'GET',
            url: `/${this.path}/getItem`,
            params: {
                id,
            },
        });
    }

    public getExcel(params: Omit<TParams, 'page' | 'size'>): Promise<HttpResponse<string>> {
        return this.apiService.request<string>({
            method: 'POST',
            url: `/${this.basePath}/getExcel`,
            data: omit<Omit<TParams, 'page' | 'size'>>(['page', 'size'], params),
        });
    }

    public deleteMany(ids: Key[]): Promise<HttpResponse<string>> {
        return this.apiService.request<string>({
            method: 'DELETE',
            url: `/${this.basePath}/deleteMany${serializeUriParams('ids', ids as string[])}`,
        });
    }

    public get(id: string): Promise<HttpResponse<TType>> {
        return this.apiService.request<TType>({
            method: 'GET',
            url: `/${this.basePath}/getItem`,
            params: {
                id,
            },
        });
    }

    public add(data: Omit<TType, 'id' | 'creationDate'> | FormData): Promise<HttpResponse<TType>> {
        return this.apiService.request<TType>({
            method: 'POST',
            url: `/${this.basePath}/add`,
            data,
        });
    }

    public update(data: Omit<TType, 'creationDate'> | FormData): Promise<HttpResponse<TType>> {
        return this.apiService.request<TType>({
            method: 'PUT',
            url: `/${this.basePath}/update`,
            data,
        });
    }

    get basePath() {
        return this._basePath;
    }
}
