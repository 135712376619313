import React, { createContext, FC, Key, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { getApiService, getProfessionApplicationsApiService, getStorageService } from 'services';
import { GetProfessionApplications, GetProfessionApplicationsFilter, ProfessionApplication } from 'types';
import { downloadFileFromBase64, PaginationOutput } from 'kl-b2c-ui-kit';
import { dropdownToBoolean } from 'contexts/mappers';

interface ProfessionApplicationsContext {
    professionApplications: PaginationOutput<ProfessionApplication>;
    getProfessionApplications: (params: GetProfessionApplicationsFilter) => Promise<void>;
    getProfessionApplication: (id: string) => Promise<void>;
    getExcel: (params: Omit<GetProfessionApplicationsFilter, 'page' | 'size'>) => Promise<void>;
    deleteProfessionApplications: (ids: Key[]) => Promise<void>;
}

const ProfessionApplicationsContext = createContext<ProfessionApplicationsContext>({} as ProfessionApplicationsContext);

const ProfessionApplicationsProvider: FC<PropsWithChildren> = ({ children }) => {
    const storageService = getStorageService();
    const [professionApplications, setProfessionApplications] = useState<PaginationOutput<ProfessionApplication>>({
        count: 0,
        items: null,
    });
    const apiService = getApiService(storageService);
    const professionApplicationsApiService = getProfessionApplicationsApiService(apiService);

    const getProfessionApplications = async (params: GetProfessionApplicationsFilter) => {
        const professionApplications = await professionApplicationsApiService.getItems({
            ...params,
            marketingIsSelected: dropdownToBoolean(params.marketingIsSelected),
        });
        setProfessionApplications(professionApplications.data);
    };

    const getProfessionApplication = async (id: string) => {
        const professions = await professionApplicationsApiService.getItem(id);
        setProfessionApplications({ count: 1, items: [professions.data] });
    };

    const deleteProfessionApplications = async (ids: Key[]) => {
        await professionApplicationsApiService.deleteMany(ids);
    };

    const getExcel = async (params: Omit<GetProfessionApplicationsFilter, 'page' | 'size'>) => {
        const base64 = await professionApplicationsApiService.getExcel({
            ...params,
            marketingIsSelected: dropdownToBoolean(params.marketingIsSelected),
        });
        downloadFileFromBase64(base64.data, 'xlsx', 'ProfessionsApplications');
    };

    const memoValue = useMemo(
        () => ({
            getProfessionApplications,
            professionApplications,
            getExcel,
            deleteProfessionApplications,
            getProfessionApplication,
        }),
        [getProfessionApplications, professionApplications, getProfessionApplication]
    );

    return (
        <ProfessionApplicationsContext.Provider value={memoValue}>{children}</ProfessionApplicationsContext.Provider>
    );
};

export const useProfessionApplications = () => useContext(ProfessionApplicationsContext);

export default ProfessionApplicationsProvider;
