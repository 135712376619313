import {
    AgreementsChapter,
    CourseFormType,
    CourseGroupType,
    DomainType,
    EventFormType,
    FilterType,
    IsVisible,
    LegalType,
    ProfessionGroupType,
} from 'enums';
import { AgreementSelected } from 'enums/agreement-selected';

export type AvailableType =
    | LegalType
    | AgreementsChapter
    | DomainType
    | IsVisible
    | AgreementSelected
    | EventFormType
    | CourseFormType
    | ProfessionGroupType
    | CourseGroupType
    | undefined;

interface ReturnType {
    defValue: AvailableType;
    options: {
        value: AvailableType;
        label: string;
    }[];
}

export const filterToDropdown = (key: FilterType): ReturnType => {
    switch (key) {
        case FilterType.MarketingIsSelected:
        case FilterType.PrivacyPolicyIsSelected: {
            return {
                defValue: AgreementSelected.All,
                options: Object.keys(AgreementSelected)
                    .filter((el) => isNaN(Number(el)))
                    .map((key) => ({
                        value: AgreementSelected[key as keyof typeof AgreementSelected],
                        label: key,
                    })),
            };
        }
        case FilterType.Type: {
            return {
                defValue: LegalType.All,
                options: Object.keys(LegalType)
                    .filter((el) => isNaN(Number(el)))
                    .map((key) => ({
                        value: LegalType[key as keyof typeof LegalType],
                        label: key,
                    })),
            };
        }
        case FilterType.Chapter: {
            return {
                defValue: AgreementsChapter.All,
                options: Object.keys(AgreementsChapter)
                    .filter((el) => isNaN(Number(el)))
                    .map((key) => ({
                        value: AgreementsChapter[key as keyof typeof AgreementsChapter],
                        label: key,
                    })),
            };
        }
        case FilterType.EventFormType: {
            return {
                defValue: EventFormType.All,
                options: Object.keys(EventFormType)
                    .filter((el) => isNaN(Number(el)))
                    .map((key) => ({
                        value: EventFormType[key as keyof typeof EventFormType],
                        label: key,
                    })),
            };
        }
        case FilterType.CourseFormType: {
            return {
                defValue: CourseFormType.All,
                options: Object.keys(CourseFormType)
                    .filter((el) => isNaN(Number(el)))
                    .map((key) => ({
                        value: CourseFormType[key as keyof typeof CourseFormType],
                        label: key,
                    })),
            };
        }
        case FilterType.Domain:
        case FilterType.DomainType: {
            return {
                defValue: DomainType.All,
                options: Object.keys(DomainType)
                    .filter((el) => isNaN(Number(el)))
                    .map((key) => ({
                        value: DomainType[key as keyof typeof DomainType],
                        label: key,
                    })),
            };
        }
        case FilterType.ProfessionGroupType: {
            return {
                defValue: ProfessionGroupType.All,
                options: Object.keys(ProfessionGroupType)
                    .filter((el) => isNaN(Number(el)))
                    .map((key) => ({
                        value: ProfessionGroupType[key as keyof typeof ProfessionGroupType],
                        label: key,
                    })),
            };
        }
        case FilterType.CourseGroupType: {
            return {
                defValue: CourseGroupType.All,
                options: Object.keys(CourseGroupType)
                    .filter((el) => isNaN(Number(el)))
                    .map((key) => ({
                        value: CourseGroupType[key as keyof typeof CourseGroupType],
                        label: key,
                    })),
            };
        }
        case FilterType.IsVisible:
        case FilterType.IsVisibleOnHomePage:
        case FilterType.IsVisibleTimeElapsed:
        case FilterType.IsRegistrationOpened:
        case FilterType.IsVisibleCorporateBusinessPage:
        case FilterType.IsVisibleGovernmentPage:
        case FilterType.IsVisibleHomePage:
        case FilterType.IsShowCorporatePage:
        case FilterType.IsShowGovernmentPage:
        case FilterType.IsShowHomePage:
        case FilterType.IsShownAboutPage: {
            return {
                defValue: IsVisible.All,
                options: Object.keys(IsVisible)
                    .filter((el) => isNaN(Number(el)))
                    .map((key) => ({
                        value: IsVisible[key as keyof typeof IsVisible],
                        label: key,
                    })),
            };
        }
        default: {
            throw new Error(`No filter type for ${key} has been found, consider to add one`);
        }
    }
};
