import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCourseGroup, useToaster } from 'contexts';
import { CourseGroupType, FormBuilderKeys } from 'enums';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { Heading, Loader } from '@kl/components-v6';
import { useTranslation } from 'react-i18next';
import { AddCourseGroup, CourseGroup, UpdateCourseGroup } from 'types';
import { FormBuilder } from 'containers';
import { AxiosError } from 'axios';

const INITIAL_DATA: AddCourseGroup = {
    name: '',
    type: CourseGroupType.None,
};

const Courses: FC = () => {
    const { getItem, item, updateItem, addItem } = useCourseGroup();
    const { setToaster } = useToaster();
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);

    const { t } = useTranslation(['pages/courses', 'common/shared']);

    const methods = useForm<Omit<CourseGroup, 'id' | 'creationDate'>>();

    const onSubmit = async (data: FieldValues) => {
        setLoading(true);
        try {
            if (id) {
                await updateItem({ ...data, id } as UpdateCourseGroup);
                setToaster({
                    type: 'success',
                    message: t('update-group-success'),
                });
            } else {
                await addItem(data as AddCourseGroup);
                navigate('/courses/courses-groups');
            }
        } catch (e: unknown) {
            setToaster({
                type: 'error',
                message: (e as AxiosError).response?.data as string,
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (id) {
            getItem(id);
        }
    }, [id]);

    if (id && !item) {
        return <Loader centered size={'large'} tip={t('loading', { ns: 'common/shared' })} />;
    }

    return (
        <>
            <Heading type={'H2'}>{id ? t('update-group') : t('add-group')}</Heading>
            <FormProvider {...methods}>
                <FormBuilder<AddCourseGroup>
                    data={item || INITIAL_DATA}
                    submit={onSubmit}
                    cancel={() => navigate('/courses/courses-groups')}
                    formKey={FormBuilderKeys.CourseGroup}
                    isFormEmpty={!id}
                    loading={loading}
                />
            </FormProvider>
        </>
    );
};

export default Courses;
