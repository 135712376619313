import { PageBuilder } from 'containers';
import { useProfessionApplications } from 'contexts';
import { FilterType, PageBuilderAdditionalFilters, PageBuilderKey } from 'enums';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { GetProfessionApplications, GetProfessionApplicationsFilter } from 'types';
import { renderCell, getTableColumns } from 'utils';
import { cellRenderMapper, initialColumns } from './mappers';
import { ProfessionApplicationTable } from './mappers/columns';

const ProfessionApplications: FC = () => {
    const {
        professionApplications,
        getProfessionApplications,
        getExcel,
        deleteProfessionApplications,
        getProfessionApplication,
    } = useProfessionApplications();
    const { i18n } = useTranslation(['pages/professions', 'common/shared']);
    const navigate = useNavigate();
    const { professionId } = useParams();
    return (
        <PageBuilder<ProfessionApplicationTable>
            pageKey={PageBuilderKey.ProfessionApplications}
            data={professionApplications}
            getItems={(params) =>
                getProfessionApplications({ ...(params as GetProfessionApplicationsFilter), professionId })
            }
            getItem={(id: string) => getProfessionApplication(id)}
            showItem={(application: ProfessionApplicationTable) => {
                return navigate(`/profession-application/${application.id}`);
            }}
            getExcel={(params) =>
                getExcel({ ...(params as Omit<GetProfessionApplicationsFilter, 'page' | 'size'>), professionId })
            }
            deleteItems={deleteProfessionApplications}
            columns={getTableColumns<ProfessionApplicationTable>(
                initialColumns,
                cellRenderMapper,
                (value, type) => renderCell(value, type, { path: '/agreements', navigate }),
                {
                    ...i18n.getResourceBundle(i18n.language, 'pages/professions'),
                    ...i18n.getResourceBundle(i18n.language, 'common/shared'),
                }
            )}
            additionalFilters={[
                { type: PageBuilderAdditionalFilters.DebounceTextBox, key: FilterType.Email },
                { type: PageBuilderAdditionalFilters.DebounceTextBox, key: FilterType.FirstName },
                { type: PageBuilderAdditionalFilters.DebounceTextBox, key: FilterType.LastName },
                { type: PageBuilderAdditionalFilters.DebounceTextBox, key: FilterType.ProfessionName },
                { type: PageBuilderAdditionalFilters.Dropdown, key: FilterType.CourseFormType },
                { type: PageBuilderAdditionalFilters.Dropdown, key: FilterType.DomainType },
                { type: PageBuilderAdditionalFilters.Dropdown, key: FilterType.MarketingIsSelected },
            ]}
        />
    );
};

export default ProfessionApplications;
