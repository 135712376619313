export enum ControlRendererType {
    Toggle = 'Toggle',
    Text = 'Text',
    DateTime = 'DateTime',
    TextBox = 'TextBox',
    DomainType = 'DomainType',
    CheckBox = 'CheckBox',
    UploadImage = 'UploadImage',
    UploadImageExceptGif = 'UploadImageExceptGif',
    UploadImageExceptGifSwg = 'UploadImageExceptGifSwg',
    UploadImageExceptGifSwgExtra = 'UploadImageExceptGifSwgExtra',
    UploadDocs = 'UploadDocs',
    UploadDocsExtra = 'UploadDocsExtra',
    Calendar = 'Calendar',
    TimePicker = 'TimePicker',
    Wysiwyg = 'Wysiwyg',
    Hidden = 'Hidden',
    FormType = 'FormType',
    FormTypes = 'FormTypes',
    EventType = 'EventType',
    CourseSkill = 'CourseSkill',
    DurationType = 'DurationType',
    CourseLevel = 'CourseLevel',
    CourseFormType = 'CourseFormType',
    CourseFormTypes = 'CourseFormTypes',
    CourseStudyForms = 'CourseStudyForms',
    Languages = 'LanguagesForm',
    CourseDirectionTypes = 'CourseDirectionTypes',
    CourseForWhom = 'CourseForWhom',
    Number = 'Number',
    ConditionCategory = 'ConditionCategory',
    StudyCourse = 'StudyCourse',
    StudyForm = 'StudyForm',
    StudyLevel = 'StudyLevel',
    AgreementId = 'AgreementId',
    CourseId = 'CourseId',
    ProfessionId = 'ProfessionId',
    ProfessionGroupType = 'ProfessionGroupType',
    CourseGroupType = 'CourseGroupType',
}
