import React, { createContext, FC, Key, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { getApiService, getSpeakersApiService, getStorageService } from 'services';
import { Speaker, Speakers } from 'types';
import { downloadFileFromBase64, PaginationOutput } from 'kl-b2c-ui-kit';
import { dropdownToBoolean } from '../mappers';

interface SpeakersContext {
    speakers: PaginationOutput<Speaker>;
    getSpeakers: (params: Speakers) => Promise<void>;
    getExcel: (params: Omit<Speakers, 'page' | 'size'>) => Promise<void>;
    deleteSpeakers: (ids: Key[]) => Promise<void>;
}

const SpeakersContext = createContext<SpeakersContext>({} as SpeakersContext);

const SpeakersProvider: FC<PropsWithChildren> = ({ children }) => {
    const storageService = getStorageService();
    const [speakers, setSpeakers] = useState<PaginationOutput<Speaker>>({
        count: 0,
        items: null,
    });
    const apiService = getApiService(storageService);
    const speakersApiService = getSpeakersApiService(apiService);

    const getSpeakers = async (params: Speakers) => {
        const speakers = await speakersApiService.getItems({
            ...params,
            domainType: params.domainType ? params.domainType : null,
            isShowAboutPage: dropdownToBoolean(params.isShowAboutPage),
            isShowCorporatePage: dropdownToBoolean(params.isShowCorporatePage),
            isShowGovernmentPage: dropdownToBoolean(params.isShowGovernmentPage),
            isShowHomePage: dropdownToBoolean(params.isShowHomePage),
        });
        setSpeakers(speakers.data);
    };

    const deleteSpeakers = async (ids: Key[]) => {
        await speakersApiService.deleteMany(ids);
    };

    const getExcel = async (params: Omit<Speakers, 'page' | 'size'>) => {
        const base64 = await speakersApiService.getExcel({
            ...params,
            domainType: params.domainType ? params.domainType : null,
            isShowAboutPage: dropdownToBoolean(params.isShowAboutPage),
            isShowCorporatePage: dropdownToBoolean(params.isShowCorporatePage),
            isShowGovernmentPage: dropdownToBoolean(params.isShowGovernmentPage),
            isShowHomePage: dropdownToBoolean(params.isShowHomePage),
        });
        downloadFileFromBase64(base64.data, 'xlsx', 'eventSpeakers');
    };

    const memoValue = useMemo(
        () => ({
            getSpeakers,
            speakers,
            getExcel,
            deleteSpeakers,
        }),
        [getSpeakers, speakers]
    );

    return <SpeakersContext.Provider value={memoValue}>{children}</SpeakersContext.Provider>;
};

export const useSpeakers = () => useContext(SpeakersContext);

export default SpeakersProvider;
