import { PageBuilder } from 'containers';
import { useEventApplications } from 'contexts';
import { FilterType, PageBuilderAdditionalFilters, PageBuilderKey } from 'enums';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { EventApplication as EventApplicationModel, GetEventApplicationsFilter } from 'types';
import { renderCell, getTableColumns } from 'utils';
import { cellRenderMapper, initialColumns } from './mappers';

const EventApplications: FC = () => {
    const { eventApplications, getEventApplications, getExcel, deleteEventApplications, getEventApplication } =
        useEventApplications();
    const { i18n } = useTranslation(['pages/events', 'common/shared']);
    const navigate = useNavigate();
    const { eventId } = useParams();

    return (
        <PageBuilder<EventApplicationModel>
            pageKey={PageBuilderKey.EventApplications}
            data={eventApplications}
            getItems={(params) => getEventApplications({ ...(params as GetEventApplicationsFilter), eventId })}
            getItem={(id: string) => getEventApplication(id)}
            getExcel={(params) =>
                getExcel({ ...(params as Omit<GetEventApplicationsFilter, 'page' | 'size'>), eventId })
            }
            deleteItems={deleteEventApplications}
            columns={getTableColumns<EventApplicationModel>(
                initialColumns,
                cellRenderMapper,
                (value, type) => renderCell(value, type, { path: '/agreements', navigate }),
                {
                    ...i18n.getResourceBundle(i18n.language, 'pages/events'),
                    ...i18n.getResourceBundle(i18n.language, 'common/shared'),
                }
            )}
            additionalFilters={[
                { type: PageBuilderAdditionalFilters.DebounceTextBox, key: FilterType.Email },
                { type: PageBuilderAdditionalFilters.Dropdown, key: FilterType.EventFormType },
                { type: PageBuilderAdditionalFilters.Dropdown, key: FilterType.MarketingIsSelected },
            ]}
        />
    );
};

export default EventApplications;
