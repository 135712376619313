import React, { createContext, FC, Key, PropsWithChildren, useMemo, useState } from 'react';
import { downloadFileFromBase64, PaginationOutput } from 'kl-b2c-ui-kit';
import { IBaseApi2 } from 'interfaces';

interface IBaseItemsContext<TFilters, TFiltersExcel, TTable> {
    items: PaginationOutput<TTable>;
    getItems: (params: TFilters) => Promise<void>;
    getItem: (id: string) => Promise<void>;
    getExcel: (params: TFiltersExcel) => Promise<void>;
    deleteItems: (ids: Key[]) => Promise<void>;
}

export const getBaseMultipleContext = <TFilters, TFiltersExcel, TTable, TType, TAdd, TUpdate>(
    apiService: IBaseApi2<TFilters, TFiltersExcel, TTable, TType, TAdd, TUpdate>,
    excelName: string
) => {
    const Context = createContext<IBaseItemsContext<TFilters, TFiltersExcel, TTable>>(
        {} as IBaseItemsContext<TFilters, TFiltersExcel, TTable>
    );

    const Provider: FC<PropsWithChildren> = ({ children }) => {
        const baseApiService = apiService;
        const excelFileName = excelName;
        const [items, setItems] = useState<PaginationOutput<TTable>>({
            count: 0,
            items: null,
        });

        const getItems = async (params: TFilters) => {
            const itemsResult = await baseApiService.getItems(params);
            setItems(itemsResult.data);
        };

        const getItem = async (id: string) => {
            const itemsResult = await baseApiService.get(id);

            // @ts-ignore
            setItems({ count: 1, items: [itemsResult.data] });
        };

        const deleteItems = async (ids: Key[]) => {
            await baseApiService.deleteMany(ids);
        };

        const getExcel = async (params: TFiltersExcel) => {
            const base64 = await baseApiService.getExcel(params);
            downloadFileFromBase64(base64.data, 'xlsx', excelFileName);
        };

        const memoValue = useMemo(
            () => ({
                getItems,
                getItem,
                items,
                getExcel,
                deleteItems,
            }),
            [getItems, getItem, items]
        );

        return <Context.Provider value={memoValue}>{children}</Context.Provider>;
    };

    return {
        Provider,
        Context,
    };
};
