import React, { createContext, FC, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { getApiService, getEventGroupsApiService, getStorageService } from 'services';
import { Group } from 'types';

interface EventGroupContext {
    eventGroup: Omit<Group, 'id' | 'creationDate'> | null;
    getEventGroup: (id: string) => void;
    addEventGroup: (data: Omit<Group, 'id' | 'creationDate'>) => void;
    updateEventGroup: (data: Omit<Group, 'creationDate'>) => void;
}

const EventGroupContext = createContext<EventGroupContext>({} as EventGroupContext);

const EventGroupProvider: FC<PropsWithChildren> = ({ children }) => {
    const storageService = getStorageService();
    const [eventGroup, setEventGroup] = useState<Omit<Group, 'id' | 'creationDate'> | null>(null);
    const apiService = getApiService(storageService);
    const eventGroupsApiService = getEventGroupsApiService(apiService);

    const getEventGroup = async (id: string) => {
        const eventGroups = await eventGroupsApiService.get(id);
        setEventGroup(eventGroups.data);
    };

    const addEventGroup = async (data: Omit<Group, 'id' | 'creationDate'>) => {
        const eventGroups = await eventGroupsApiService.add(data);
        setEventGroup(eventGroups.data);
    };

    const updateEventGroup = async (data: Omit<Group, 'creationDate'>) => {
        const eventGroups = await eventGroupsApiService.update(data);
        setEventGroup(eventGroups.data);
    };

    const memoValue = useMemo(
        () => ({
            eventGroup,
            getEventGroup,
            addEventGroup,
            updateEventGroup,
        }),
        [getEventGroup, eventGroup, addEventGroup, updateEventGroup]
    );

    return <EventGroupContext.Provider value={memoValue}>{children}</EventGroupContext.Provider>;
};

export const useEventGroup = () => useContext(EventGroupContext);

export default EventGroupProvider;
