import { IApiService, IProfessionApi } from 'interfaces';
import {
    HttpResponse,
    GetCourses,
    GetCoursesExcel,
    GetCoursesFilter,
    GetCoursesExcelFilter,
    UpdateProfession,
    AddProfession,
    Profession,
    ProfessionTable,
    GetProfessionExcel,
    GetProfession,
    GetProfessionExcelFilter,
    GetProfessionFilter,
    AddOrUpdateProfessionCondition,
    AddOrUpdateProfessionLevel,
    AddOrUpdateProfessionPlus,
    AddOrUpdateProfessionTargetAudience,
    AddOrUpdateProfessionTeaser,
    UpdateProfessionParticle,
} from 'types';
import { BaseApi2 } from './base-api2.service';
import { generateFormData, PaginationOutput } from 'kl-b2c-ui-kit';
import { ApiBaseKeys } from 'enums/api-base-keys';
import { dropdownToBoolean } from 'contexts/mappers';

class ProfessionApi
    extends BaseApi2<
        GetProfessionFilter,
        GetProfessionExcelFilter,
        GetProfession,
        GetProfessionExcel,
        ProfessionTable,
        Profession,
        AddProfession,
        UpdateProfession
    >
    // eslint-disable-next-line prettier/prettier
    implements IProfessionApi {
    constructor(apiService: IApiService, path: ApiBaseKeys) {
        super(apiService, path);
    }
    public targetAudiencesUpdate(
        data: UpdateProfessionParticle<AddOrUpdateProfessionTargetAudience>
    ): Promise<HttpResponse<Profession>> {
        const form = new FormData();
        form.append('professionId', data.professionId);
        data.items.forEach((item, index) => {
            this.formArrayTransform(form, item, 'items', index);
        });
        return this.apiService.request<Profession>({
            method: 'POST',
            url: `/${this.path}/TargetAudiencesUpdate`,
            data: form,
        });
    }
    public professionConditionUpdate(
        data: UpdateProfessionParticle<AddOrUpdateProfessionCondition>
    ): Promise<HttpResponse<Profession>> {
        return this.apiService.request<Profession>({
            method: 'POST',
            url: `/${this.path}/ProfessionConditionUpdate`,
            data,
        });
    }
    public professionTeaserUpdate(
        data: UpdateProfessionParticle<AddOrUpdateProfessionTeaser>
    ): Promise<HttpResponse<Profession>> {
        const form = new FormData();
        form.append('professionId', data.professionId);
        data.items.forEach((item, index) => {
            this.formArrayTransform(form, item, 'items', index);
        });
        return this.apiService.request<Profession>({
            method: 'POST',
            url: `/${this.path}/ProfessionTeaserUpdate`,
            data: form,
        });
    }
    public professionPlusUpdate(
        data: UpdateProfessionParticle<AddOrUpdateProfessionPlus>
    ): Promise<HttpResponse<Profession>> {
        const form = new FormData();
        form.append('professionId', data.professionId);
        data.items.forEach((item, index) => {
            this.formArrayTransform(form, item, 'items', index);
        });
        return this.apiService.request<Profession>({
            method: 'POST',
            url: `/${this.path}/ProfessionPlusUpdate`,
            data: form,
        });
    }
    public levelUpdate(data: UpdateProfessionParticle<AddOrUpdateProfessionLevel>): Promise<HttpResponse<Profession>> {
        return this.apiService.request<Profession>({
            method: 'POST',
            url: `/${this.path}/LevelUpdate`,
            data,
        });
    }
    protected override getAddData(data: AddProfession): FormData | AddProfession {
        return generateFormData(data as Object);
    }
    protected override getUpdateData(data: UpdateProfession): FormData | UpdateProfession {
        return generateFormData(data as Object);
    }
    protected override getParams(data: GetCoursesFilter): GetCourses {
        return {
            ...data,
            isVisible: dropdownToBoolean(data.isVisible),
            domainType: data.domainType ? data.domainType : null,
        };
    }
    protected override getExcelParams(data: GetCoursesExcelFilter): GetCoursesExcel {
        return {
            ...data,
            isVisible: dropdownToBoolean(data.isVisible),
            domainType: data.domainType ? data.domainType : null,
        };
    }
}

export default ProfessionApi;
