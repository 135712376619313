import React, { createContext, FC, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import {
    getApiService,
    getEventsApiService,
    getEventTagsApiService,
    getStorageService,
    getEventGroupsApiService,
    getSpeakersApiService,
} from 'services';
import { Event, Group, Tag, EventGeneralAddOrUpdateType, Speaker, UpdateEventParticle } from 'types';
import { generateFormData } from 'kl-b2c-ui-kit';
import { DomainType } from 'enums/domain-type';

interface EventContext {
    event: Omit<Event, 'id' | 'creationDate'> | null;
    getEvent: (id: string) => void;
    addEvent: (data: Omit<EventGeneralAddOrUpdateType, 'id'>) => Promise<Event>;
    updateEvent: (data: EventGeneralAddOrUpdateType) => void;
    updateParticle: <T>(data: UpdateEventParticle<T>, key: string, isFormData?: boolean) => void;
    updateParticleWithFiles: <T>(data: UpdateEventParticle<T>, key: string) => void;
    tags: Tag[] | null;
    groups: Group[] | null;
    speakers: Speaker[] | null;
}

const EventContext = createContext<EventContext>({} as EventContext);

const EventProvider: FC<PropsWithChildren> = ({ children }) => {
    const storageService = getStorageService();
    const [event, setEvent] = useState<Omit<Event, 'id' | 'creationDate'> | null>(null);
    const [tags, setTags] = useState<Tag[] | null>(null);
    const [groups, setGroups] = useState<Group[] | null>(null);
    const [speakers, setSpeakers] = useState<Speaker[] | null>(null);
    const apiService = getApiService(storageService);
    const eventsApiService = getEventsApiService(apiService);
    const tagsApiService = getEventTagsApiService(apiService);
    const groupApiService = getEventGroupsApiService(apiService);
    const speakersApiService = getSpeakersApiService(apiService);

    useEffect(() => {
        const tags = tagsApiService.getNewsTags({ page: 0, size: 300 });
        const groups = groupApiService.getItems({ page: 0, size: 300 });
        const speakers = speakersApiService.getItems({
            page: 0,
            size: 300,
            domainType: null,
            isShowAboutPage: null,
            isShowCorporatePage: null,
            isShowGovernmentPage: null,
            isShowHomePage: null,
        });

        Promise.all([tags, groups, speakers])
            .then((result) => {
                setTags(result[0].data.items);
                setGroups(result[1].data.items);
                setSpeakers(result[2].data.items);
            })
            .catch((e) => {
                throw new Error(e);
            });
    }, []);

    const updateParticle = async <T,>(data: UpdateEventParticle<T>, key: string, isFormData = false) => {
        const event = await eventsApiService.updateEventParticle(data, key, isFormData);
        setEvent(event.data);
    };

    const updateParticleWithFiles = async <T,>(data: UpdateEventParticle<T>, key: string) => {
        const event = await eventsApiService.updateParticleWithFiles<T>(data, key);
        setEvent(event.data);
    };

    const getEvent = async (id: string) => {
        const event = await eventsApiService.get(id);
        setEvent(event.data);

        const speakers = await speakersApiService.getItems({
            page: 0,
            size: 300,
            domainType: !event.data.groupRu ? DomainType.En : DomainType.Ru,
            isShowAboutPage: null,
            isShowCorporatePage: null,
            isShowGovernmentPage: null,
            isShowHomePage: null,
        });

        setSpeakers(speakers.data.items);
    };

    const addEvent = async (data: Omit<EventGeneralAddOrUpdateType, 'id'>) => {
        const formData = generateFormData({
            ...data,
            finishDate: data?.finishDate?.toISOString(),
            startDate: data?.startDate?.toISOString(),
        });
        formData.delete('coordinates');

        if (data?.coordinates?.latitude) formData.append('coordinates.latitude', data.coordinates.latitude + '');
        if (data?.coordinates?.longitude) formData.append('coordinates.longitude', data.coordinates.longitude + '');

        const event = await eventsApiService.add(formData);
        setEvent(event.data);

        return event.data;
    };

    const updateEvent = async (data: EventGeneralAddOrUpdateType) => {
        const formData = generateFormData({
            ...data,
            finishDate: data?.finishDate?.toISOString(),
            startDate: data?.startDate?.toISOString(),
        });
        formData.delete('coordinates');

        if (data?.coordinates?.latitude) formData.append('coordinates.latitude', data.coordinates.latitude + '');
        if (data?.coordinates?.longitude) formData.append('coordinates.longitude', data.coordinates.longitude + '');

        const event = await eventsApiService.update(formData);
        setEvent(event.data);
    };

    const memoValue = useMemo(
        () => ({
            event,
            getEvent,
            addEvent,
            updateEvent,
            tags,
            groups,
            speakers,
            updateParticle,
            updateParticleWithFiles,
        }),
        [getEvent, event, addEvent, updateEvent, updateParticle, updateParticleWithFiles, tags, groups, speakers]
    );

    return <EventContext.Provider value={memoValue}>{children}</EventContext.Provider>;
};

export const useEvent = () => useContext(EventContext);

export default EventProvider;
