import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'contexts';
import { getURLParamValue } from 'kl-b2c-ui-kit';
import { Loader } from '@kl/components-v6';
import { useTranslation } from 'react-i18next';

const SignIn: FC = () => {
    const navigate = useNavigate();
    const { signIn } = useAuth();
    const code = getURLParamValue('code');
    const { t } = useTranslation('pages/sign-in');

    useEffect(() => {
        signIn(code);
        navigate('/');
    }, [code]);

    return <Loader centered size={'large'} tip={t('receiving')} />;
};

export default SignIn;
