import { Button, Calendar, Space } from '@kl/components-v6';
import { Download } from '@kl/icons/16';
import { DebounceTextbox, SelectByEnum } from 'components';
import { DATE_FORMAT } from 'consts';
import { FilterType, PageBuilderAdditionalFilters } from 'enums';
import React, { FC, PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { filterToDropdown } from './mappers';

interface FiltersProps {
    setFilter: (newFilter: Record<string, string | number | undefined>) => void;
    disableExcelDownload: boolean;
    downloadExcel?: () => Promise<void>;
    additionalFilters?: { type: PageBuilderAdditionalFilters; key: FilterType; defaultValue?: string | number }[];
    noDefaultFilters?: boolean;
}

export const Filters: FC<PropsWithChildren<FiltersProps>> = ({
    setFilter,
    disableExcelDownload,
    downloadExcel,
    additionalFilters,
    noDefaultFilters,
}) => {
    const [excelLoading, setExcelLoading] = useState<boolean>(false);
    const { t } = useTranslation('common/shared');

    const generateTimezoneDate = (value: Date, hour: number, min: number, sec: number, ms: number) => {
        const currentTimezoneOffset = new Date().getTimezoneOffset();
        return new Date(
            new Date(value.setHours(hour, min, sec, ms)).getTime() - currentTimezoneOffset * 60000
        ).toISOString();
    };

    // only for calendars validation
    const [dates, setDates] = useState<{ startDate: Date | null; finishDate: Date | null }>({
        startDate: null,
        finishDate: null,
    });

    return (
        <Space direction="horizontal" size={15} style={{ marginBottom: 30, display: 'flex' }}>
            {!noDefaultFilters && (
                <>
                    <Calendar
                        style={{ width: 150 }}
                        placeholder={t('startDate')}
                        format={DATE_FORMAT}
                        allowClear
                        disabledDate={(current: Date) => {
                            if (dates?.finishDate) {
                                return current && current.valueOf() > dates?.finishDate.valueOf();
                            }
                            return false;
                        }}
                        onChange={(value: Date | null) => {
                            setDates((prevState) => ({ ...prevState, startDate: value }));
                            if (!value) {
                                setFilter({ startDate: undefined });
                            } else {
                                setFilter({ startDate: generateTimezoneDate(value, 0, 0, 0, 0) });
                            }
                        }}
                    />
                    <Calendar
                        style={{ width: 150 }}
                        placeholder={t('endDate')}
                        format={DATE_FORMAT}
                        allowClear
                        disabledDate={(current: Date) => {
                            if (dates?.startDate) {
                                return current && current.valueOf() < dates?.startDate.valueOf();
                            }
                            return false;
                        }}
                        onChange={(value: Date | null) => {
                            setDates((prevState) => ({ ...prevState, finishDate: value }));
                            if (!value) {
                                setFilter({ finishDate: undefined });
                            } else {
                                setFilter({ finishDate: generateTimezoneDate(value, 23, 59, 59, 0) });
                            }
                        }}
                    />
                </>
            )}

            {additionalFilters?.length &&
                additionalFilters.map(
                    (filter: {
                        type: PageBuilderAdditionalFilters;
                        key: FilterType;
                        defaultValue?: string | number;
                    }) => {
                        const { type, key, defaultValue } = filter;

                        switch (type) {
                            case PageBuilderAdditionalFilters.Dropdown:
                                const { options, defValue } = filterToDropdown(key);

                                return (
                                    <SelectByEnum
                                        key={key}
                                        filterType={key}
                                        defaultValue={defValue}
                                        select={(value: string) => {
                                            const isNull = (value as unknown as number) === 0;
                                            if (isNull) {
                                                setFilter({ [key]: undefined, page: 0 });
                                                return;
                                            }
                                            setFilter({ [key]: value as string, page: 0 });
                                        }}
                                        options={options}
                                    />
                                );
                            case PageBuilderAdditionalFilters.DebounceTextBox:
                                return (
                                    <DebounceTextbox
                                        defaultValue={defaultValue}
                                        key={key}
                                        placeholder={t(key)}
                                        change={(value) => setFilter({ [key]: value, page: 0 })}
                                    />
                                );
                        }
                    }
                )}

            {downloadExcel && (
                <Button
                    loading={excelLoading}
                    disabled={disableExcelDownload}
                    iconAfter={<Download key={'downloadIcon'} color={'#fff'} />}
                    mode="primaryBlack"
                    onClick={async () => {
                        setExcelLoading(true);
                        await downloadExcel();
                        setExcelLoading(false);
                    }}
                >
                    {t('xlsDownload')}
                </Button>
            )}
        </Space>
    );
};
