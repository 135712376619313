import { Organization } from 'types';
import { ControlRendererType } from 'enums';
import { RegisterOptions } from 'react-hook-form';
import { ACCEPTED_IMAGE_FORMATS_EXCEPT_GIF_SWG, TEN_MB_IN_BYTES } from 'consts';
import { GetFile } from 'kl-b2c-ui-kit';
import { fileSize } from 'containers/form-builder/rules';

export const organizationRendererTypes = (
    t: (key: string, options?: Record<string, number | string>) => string
): Record<keyof Omit<Organization, 'id' | 'creationDate'>, { type: ControlRendererType; rules?: RegisterOptions }> => ({
    name: {
        type: ControlRendererType.TextBox,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 255,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 255,
                }),
            },
        },
    },
    image: {
        type: ControlRendererType.UploadImageExceptGifSwg,
        rules: {
            required: {
                value: true,
                message: t('required-image', {
                    ns: 'common/errors',
                }),
            },
            validate: (file: File | GetFile) => {
                return fileSize(file, TEN_MB_IN_BYTES, ACCEPTED_IMAGE_FORMATS_EXCEPT_GIF_SWG, t);
            },
        },
    },
    domain: {
        type: ControlRendererType.DomainType,
    },
    isVisibleGovernmentPage: {
        type: ControlRendererType.CheckBox,
    },
});
