import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { Textbox } from '@kl/components-v6';
import { TextboxContainer } from './styled';
import { useDebounce } from 'kl-b2c-ui-kit';

interface DebounceTextboxProps {
    placeholder: string;
    change: (value: string) => void;
    latency?: number;
    defaultValue?: string | number;
}

const DebounceTextbox: FC<PropsWithChildren<DebounceTextboxProps>> = (props) => {
    const { placeholder, change, latency = 500, defaultValue } = props;

    const [debounce, setDebounce] = useState<string | undefined>(undefined);
    const debounceValue = useDebounce(debounce, latency);

    useEffect(() => {
        if (debounceValue !== undefined) {
            change(debounceValue);
        }
    }, [debounceValue]);

    useEffect(() => {
        if (defaultValue) {
            setDebounce(defaultValue.toString());
        }
    }, []);

    return (
        <TextboxContainer>
            <Textbox
                value={debounce}
                allowClear
                onChange={(value: string | React.ChangeEvent<HTMLInputElement>) => setDebounce(value as string)}
                placeholder={placeholder}
            />
        </TextboxContainer>
    );
};

export default DebounceTextbox;
