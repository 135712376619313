import React, { createContext, FC, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import {
    getApiService,
    getNewsApiService,
    getNewsGroupsApiService,
    getNewsTagsApiService,
    getStorageService,
} from 'services';
import { News, Tag, Group, NewsAddOrUpdateType, NewsAddOrUpdatePhoto } from 'types';
import { generateFormData } from 'kl-b2c-ui-kit';

interface NewsContext {
    news: Omit<News, 'creationDate'> | null;
    newsTags: Tag[] | null;
    newsGroups: Group[] | null;
    getNews: (id: string) => void;
    addNews: (data: NewsAddOrUpdateType) => Promise<News>;
    updateNews: (data: NewsAddOrUpdateType) => void;
    addPhoto: (data: NewsAddOrUpdatePhoto) => void;
    deletePhoto: (id: string) => void;
}

const NewsContext = createContext<NewsContext>({} as NewsContext);

const NewsProvider: FC<PropsWithChildren> = ({ children }) => {
    const storageService = getStorageService();
    const [news, setNews] = useState<Omit<News, 'creationDate'> | null>(null);
    const [newsTags, setNewsTags] = useState<Tag[] | null>(null);
    const [newsGroups, setNewsGroups] = useState<Group[] | null>(null);
    const apiService = getApiService(storageService);
    const newsApiService = getNewsApiService(apiService);
    const newsTagsApiService = getNewsTagsApiService(apiService);
    const newsGroupApiService = getNewsGroupsApiService(apiService);

    const getNews = async (id: string) => {
        const news = await newsApiService.get(id);
        setNews(news.data);
    };

    const addNews = async (data: NewsAddOrUpdateType) => {
        const news = await newsApiService.add(
            generateFormData({ ...data, displayDate: data?.displayDate?.toISOString() })
        );
        setNews(news.data);

        return news.data;
    };

    const updateNews = async (data: NewsAddOrUpdateType) => {
        const news = await newsApiService.update(
            generateFormData({ ...data, displayDate: data?.displayDate?.toISOString() })
        );
        setNews(news.data);
    };

    const addPhoto = async (data: NewsAddOrUpdatePhoto) => {
        const news = await newsApiService.addPhoto(data);
        setNews(news.data);
    };

    const deletePhoto = async (id: string) => {
        const news = await newsApiService.deletePhoto(id);
        setNews(news.data);
    };

    useEffect(() => {
        const newsTags = newsTagsApiService.getNewsTags({ page: 0, size: 300 });
        const newsGroups = newsGroupApiService.getItems({ page: 0, size: 300 });

        Promise.all([newsTags, newsGroups])
            .then((result) => {
                setNewsTags(result[0].data.items);
                setNewsGroups(result[1].data.items);
            })
            .catch((e) => {
                throw new Error(e);
            });
    }, []);

    const memoValue = useMemo(
        () => ({
            news,
            newsTags,
            newsGroups,
            getNews,
            addNews,
            updateNews,
            addPhoto,
            deletePhoto,
        }),
        [news, newsTags, newsGroups, getNews, addNews, updateNews, addPhoto, deletePhoto]
    );

    return <NewsContext.Provider value={memoValue}>{children}</NewsContext.Provider>;
};

export const useNews = () => useContext(NewsContext);

export default NewsProvider;
