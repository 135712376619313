import React, { FC, useState } from 'react';
import { useProfession, useToaster } from 'contexts';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Controller, FieldValues, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { AddOrUpdateProfessionTargetAudience } from 'types';
import { getSwaggerError } from 'utils/swagger-errors';
import { FormBuilderKeys } from 'enums';
import { EmptyList, ImagePreview, ImageUploader } from 'components';
import { FormBuilder } from 'containers/form-builder';

import { Button, Heading, Textbox } from '@kl/components-v6';
import { FormRow } from 'containers/news/news/styled';
import { ArrayContainer } from 'containers/events/event/features/styled';
import { ErrorMessage } from 'containers/form-builder/styled';
import { DefaultEditor } from 'react-simple-wysiwyg';
import { GetFile } from 'kl-b2c-ui-kit';
import { fileSize } from 'containers/form-builder/rules';
import { ACCEPTED_IMAGE_FORMATS_EXCEPT_GIF, TEN_MB_IN_BYTES } from 'consts';

type FormValues = {
    targetAudiences: AddOrUpdateProfessionTargetAudience[];
};

const ProfessionTargetAudience: FC = () => {
    const { item, targetAudiencesUpdate, setItem } = useProfession();
    const { setToaster } = useToaster();
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);

    const { t } = useTranslation(['pages/professions', 'common/shared']);

    const methods = useForm<FormValues>({
        defaultValues: {
            targetAudiences:
                item?.targetAudiences?.map((r) => ({
                    name: r.name,
                    description: r.description,
                    icon: r.icon,
                    id: r.id,
                })) || [],
        },
    });

    const {
        control,
        formState: { errors },
    } = methods;

    const { fields, append, remove } = useFieldArray<FormValues, 'targetAudiences'>({
        control,
        name: 'targetAudiences',
    });

    const onSubmit = async (data: FieldValues) => {
        try {
            setLoading(true);

            if (id) {
                const newItem = await targetAudiencesUpdate({ professionId: id, items: data.targetAudiences });
                setItem(newItem);

                setToaster({
                    type: 'success',
                    message: t('update-target-audience-success'),
                });
            }
        } catch (e) {
            const swagger = getSwaggerError(e);
            if (swagger) {
                setToaster({
                    type: 'error',
                    message: swagger,
                });
            }
            setToaster({
                type: 'error',
                message: t('backend-error', { ns: 'common/shared' }),
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Heading type={'H2'}>{t('update-target-audiences')}</Heading>
            <FormProvider {...methods}>
                <FormBuilder<FormValues>
                    data={{} as FormValues}
                    submit={onSubmit}
                    cancel={() => navigate('/professions/all')}
                    formKey={FormBuilderKeys.ProfessionGeneral}
                    isFormEmpty={!id}
                    loading={loading}
                >
                    {!fields.length && <EmptyList />}
                    {fields.map((field, index) => {
                        return (
                            <FormRow key={field.id} style={{ margin: '0 0 45px 0' }}>
                                <span>{`Audience ${index + 1}`}</span>

                                <ArrayContainer>
                                    <div style={{ position: 'relative' }}>
                                        <Controller
                                            name={`targetAudiences.${index}.name`}
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: t('required-field', { ns: 'common/errors' }),
                                                },
                                                maxLength: {
                                                    value: 100,
                                                    message: t('max-length', {
                                                        ns: 'common/errors',
                                                        length: 100,
                                                    }),
                                                },
                                            }}
                                            defaultValue={''}
                                            render={({ field: { onChange, value } }) => (
                                                <Textbox
                                                    value={value}
                                                    allowClear
                                                    onChange={onChange}
                                                    placeholder={t('target-audience-name')}
                                                />
                                            )}
                                        />

                                        {errors?.targetAudiences?.[index]?.name && (
                                            <ErrorMessage style={{ left: 0 }}>
                                                {errors?.targetAudiences?.[index]?.name?.message}
                                            </ErrorMessage>
                                        )}
                                    </div>

                                    <div style={{ position: 'relative' }}>
                                        <Controller
                                            name={`targetAudiences.${index}.description`}
                                            control={control}
                                            rules={{
                                                required: false,
                                                maxLength: {
                                                    value: 200,
                                                    message: t('max-length', {
                                                        ns: 'common/errors',
                                                        length: 200,
                                                    }),
                                                },
                                            }}
                                            defaultValue={''}
                                            render={({ field: { onChange, value } }) => (
                                                <DefaultEditor
                                                    containerProps={{ style: { width: '100%' } }}
                                                    value={value as string}
                                                    onChange={(event) => onChange(event.target.value)}
                                                />
                                            )}
                                        />

                                        {errors?.targetAudiences?.[index]?.description && (
                                            <ErrorMessage style={{ left: 0 }}>
                                                {errors?.targetAudiences?.[index]?.description?.message}
                                            </ErrorMessage>
                                        )}
                                    </div>

                                    <div style={{ position: 'relative' }}>
                                        <Controller
                                            name={`targetAudiences.${index}.icon`}
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: t('required-image', { ns: 'common/errors' }),
                                                },
                                                validate: (file: File | GetFile | null) => {
                                                    return fileSize(
                                                        file,
                                                        TEN_MB_IN_BYTES,
                                                        ACCEPTED_IMAGE_FORMATS_EXCEPT_GIF,
                                                        t
                                                    );
                                                },
                                            }}
                                            defaultValue={null}
                                            render={({ field: { onChange, value } }) => {
                                                if ((value as GetFile)?.id) {
                                                    return (
                                                        <ImagePreview
                                                            clear={() => onChange(null)}
                                                            size={150}
                                                            image={value as GetFile}
                                                        />
                                                    );
                                                }

                                                return (
                                                    <ImageUploader
                                                        maxSize={TEN_MB_IN_BYTES}
                                                        accept={ACCEPTED_IMAGE_FORMATS_EXCEPT_GIF}
                                                        change={onChange}
                                                    />
                                                );
                                            }}
                                        />

                                        {errors?.targetAudiences?.[index]?.icon && (
                                            <ErrorMessage style={{ left: 0 }}>
                                                {errors?.targetAudiences?.[index]?.icon?.message}
                                            </ErrorMessage>
                                        )}
                                    </div>
                                </ArrayContainer>

                                <Button mode={'dangerOutlined'} onClick={() => remove(index)}>
                                    {t('delete', { ns: 'common/shared' })}
                                </Button>
                            </FormRow>
                        );
                    })}
                    <Button
                        mode={'primaryBlue'}
                        onClick={() =>
                            append({
                                id: null,
                                name: '',
                                description: '',
                                icon: null,
                            })
                        }
                    >
                        {t('append', { ns: 'common/shared' })}
                    </Button>
                </FormBuilder>
            </FormProvider>
        </>
    );
};

export default ProfessionTargetAudience;
