import React, { useEffect } from 'react';
import { Tabs } from '@kl/components-v6';
import { useParams } from 'react-router-dom';
import {
    General,
    ProfessionConditions,
    ProfessionLevels,
    ProfessionPluses,
    ProfessionTargetAudiences,
    ProfessionTeasers,
} from './features';
import { useProfession } from 'contexts';

const Event = () => {
    const { id } = useParams();
    const { getItem, item } = useProfession();

    useEffect(() => {
        if (id) {
            getItem(id);
        }
    }, [id]);

    if (!id) {
        return <General />;
    }

    return (
        <Tabs defaultActiveKey={'profession/general'}>
            <Tabs.TabPane disabled={!item} tab="General" key={'profession/general'}>
                <General />
            </Tabs.TabPane>
            <Tabs.TabPane disabled={!item} tab="Target Audiences" key={'profession/target-audiences'}>
                <ProfessionTargetAudiences />
            </Tabs.TabPane>
            <Tabs.TabPane disabled={!item} tab="Conditions" key={'profession/conditions'}>
                <ProfessionConditions />
            </Tabs.TabPane>
            <Tabs.TabPane disabled={!item} tab="Teasers" key={'profession/teasers'}>
                <ProfessionTeasers />
            </Tabs.TabPane>
            <Tabs.TabPane disabled={!item} tab="Pluses" key={'profession/pluses'}>
                <ProfessionPluses />
            </Tabs.TabPane>
            <Tabs.TabPane disabled={!item} tab="Levels" key={'profession/levels'}>
                <ProfessionLevels />
            </Tabs.TabPane>
        </Tabs>
    );
};

export default Event;
