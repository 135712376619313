import React, { FC } from 'react';
import { useProfessionGroups } from 'contexts';
import { cellRenderMapper, initialColumns } from './mappers';
import { GroupWithBusy, GetProfessionGroupsFilter, GetProfessionGroupsExcelFilter, ProfessionGroup } from 'types';
import { renderCell, getTableColumns } from 'utils';
import { PageBuilder } from 'containers';
import { DomainType, FilterType, PageBuilderAdditionalFilters, PageBuilderKey } from 'enums';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const ProfessionsGroups: FC = () => {
    const { items, getItems, getExcel, deleteItems } = useProfessionGroups();
    const { i18n } = useTranslation('pages/professions');
    const navigate = useNavigate();

    return (
        <PageBuilder<GroupWithBusy>
            pageKey={PageBuilderKey.ProfessionGroups}
            data={{
                count: items.count,
                items:
                    items.items?.map((item) => ({
                        ...item,
                        busyEn: {
                            busy: item.busyEn,
                            id: item.id,
                            domain: DomainType.En,
                            newsId: item.linkEn,
                        },
                        busyRu: {
                            busy: item.busyRu,
                            id: item.id,
                            domain: DomainType.Ru,
                            newsId: item.linkRu,
                        },
                    })) || [],
            }}
            getItems={(params) => getItems(params as GetProfessionGroupsFilter)}
            deleteItems={deleteItems}
            columns={getTableColumns<Omit<ProfessionGroup, 'busyRu' | 'busyEn' | 'linkRu' | 'linkEn'>>(
                initialColumns,
                cellRenderMapper,
                (value, type) => renderCell(value, type, { path: '/profession', navigate }),
                i18n.getResourceBundle(i18n.language, 'pages/professions')
            )}
            addItem={() => navigate('/profession-group')}
            updateItem={(group: GroupWithBusy) => navigate(`/profession-group/${group.id}`)}
            additionalFilters={[
                { type: PageBuilderAdditionalFilters.DebounceTextBox, key: FilterType.Name },
                { type: PageBuilderAdditionalFilters.Dropdown, key: FilterType.ProfessionGroupType },
            ]}
        />
    );
};

export default ProfessionsGroups;
