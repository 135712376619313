import React, { createContext, FC, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { getApiService, getSpeakersApiService, getStorageService } from 'services';
import { Speaker } from 'types';
import { generateFormData } from 'kl-b2c-ui-kit';

interface SpeakerContext {
    speaker: Omit<Speaker, 'id' | 'creationDate'> | null;
    getSpeaker: (id: string) => void;
    addSpeaker: (data: Omit<Speaker, 'id' | 'creationDate'>) => void;
    updateSpeaker: (data: Omit<Speaker, 'creationDate'>) => void;
}

const SpeakerContext = createContext<SpeakerContext>({} as SpeakerContext);

const SpeakerProvider: FC<PropsWithChildren> = ({ children }) => {
    const storageService = getStorageService();
    const [speaker, setSpeaker] = useState<Omit<Speaker, 'id' | 'creationDate'> | null>(null);
    const apiService = getApiService(storageService);
    const speakersApiService = getSpeakersApiService(apiService);

    const getSpeaker = async (id: string) => {
        const speakers = await speakersApiService.get(id);
        setSpeaker(speakers.data);
    };

    const addSpeaker = async (data: Omit<Speaker, 'id' | 'creationDate'>) => {
        const speakers = await speakersApiService.add(generateFormData(data));
        setSpeaker(speakers.data);
    };

    const updateSpeaker = async (data: Omit<Speaker, 'creationDate'>) => {
        const speakers = await speakersApiService.update(generateFormData(data));
        setSpeaker(speakers.data);
    };

    const memoValue = useMemo(
        () => ({
            speaker,
            getSpeaker,
            addSpeaker,
            updateSpeaker,
        }),
        [getSpeaker, speaker, addSpeaker, updateSpeaker]
    );

    return <SpeakerContext.Provider value={memoValue}>{children}</SpeakerContext.Provider>;
};

export const useSpeaker = () => useContext(SpeakerContext);

export default SpeakerProvider;
