import {
    ApiService,
    BaseApi,
    ConfigApi,
    NewsApi,
    TagsApi,
    TokenApi,
    EventsApi,
    BaseApi2,
    ProfessionApi,
    CoursesApi,
    SettingsService,
    StorageService,
} from 'services';
import {
    IApiService,
    IBaseApi,
    IConfigApi,
    INewsApi,
    ITagsApi,
    ISettingsService,
    IStorageService,
    ITokenApi,
    IEventsApi,
    ICourseApi,
    ICourseGroupApi,
    IProfessionGroupApi,
    IProfessionApi,
} from 'interfaces';

import {
    Agreement,
    Cooperation,
    EventGroups,
    Speaker,
    Filters,
    GetCooperation,
    GetSpeakers,
    GetNewsSequence,
    Group,
    News,
    NewsGroups,
    NewsTable,
    Subscription,
    GetAgreement,
    GetCourseGroupsFilter,
    GetCourseGroupsExcelFilter,
    GetCourseGroups,
    GetCourseGroupsExcel,
    CourseGroup,
    AddCourseGroup,
    UpdateCourseGroup,
    GetEventApplications,
    EventApplication,
    GetCourseApplications,
    CourseApplication,
    GetProfessionGroupsFilter,
    GetProfessionGroupsExcelFilter,
    GetProfessionGroups,
    GetProfessionGroupsExcel,
    ProfessionGroup,
    AddProfessionGroup,
    UpdateProfessionGroup,
    ProfessionApplication,
    GetProfessionApplications,
    GetReviews,
    Review,
    GetOrganizations,
    Organization,
} from 'types';
import { ApiBaseKeys } from 'enums';

export const getApiService = (tokenStorageService: IStorageService): IApiService => new ApiService(tokenStorageService);
export const geTokenApiService = (apiService: IApiService): ITokenApi => new TokenApi(apiService);
export const getConfigApiService = (apiService: IApiService): IConfigApi => new ConfigApi(apiService);
export const getAgreementsApiService = (apiService: IApiService): IBaseApi<GetAgreement, Agreement> =>
    new BaseApi(apiService, ApiBaseKeys.Agreements);
export const getStorageService = (): IStorageService => new StorageService();
export const getSettingsService = (): ISettingsService => new SettingsService();
export const getCooperationApiService = (apiService: IApiService): IBaseApi<GetCooperation, Cooperation> =>
    new BaseApi(apiService, ApiBaseKeys.Cooperation);
export const getSubscriptionApiService = (apiService: IApiService): IBaseApi<Filters, Subscription> =>
    new BaseApi(apiService, ApiBaseKeys.Subscription);
export const getSpeakersApiService = (apiService: IApiService): IBaseApi<GetSpeakers, Speaker> =>
    new BaseApi(apiService, ApiBaseKeys.Speakers);
export const getReviewsApiService = (apiService: IApiService): IBaseApi<GetReviews, Review> =>
    new BaseApi(apiService, ApiBaseKeys.Reviews);
export const getOrganizationsApiService = (apiService: IApiService): IBaseApi<GetOrganizations, Organization> =>
    new BaseApi(apiService, ApiBaseKeys.Organizations);
export const getNewsGroupsApiService = (apiService: IApiService): IBaseApi<NewsGroups, Group> =>
    new BaseApi(apiService, ApiBaseKeys.NewsGroups);
export const getNewsSequenceApiService = (apiService: IApiService): IBaseApi<GetNewsSequence, NewsTable> =>
    new BaseApi<GetNewsSequence, NewsTable>(apiService, ApiBaseKeys.NewsSequence);
export const getNewsApiService = (apiService: IApiService): INewsApi<GetNewsSequence, News> =>
    new NewsApi(apiService, ApiBaseKeys.News);
export const getNewsTagsApiService = (apiService: IApiService): ITagsApi =>
    new TagsApi(apiService, ApiBaseKeys.NewsTags);
export const getEventGroupsApiService = (apiService: IApiService): IBaseApi<EventGroups, Group> =>
    new BaseApi(apiService, ApiBaseKeys.EventGroups);
export const getEventTagsApiService = (apiService: IApiService): ITagsApi =>
    new TagsApi(apiService, ApiBaseKeys.EventTags);
export const getEventsApiService = (apiService: IApiService): IEventsApi =>
    new EventsApi(apiService, ApiBaseKeys.Events);
export const getCoursesApiService = (apiService: IApiService): ICourseApi =>
    new CoursesApi(apiService, ApiBaseKeys.Courses);
export const getCourseGroupsApiService = (apiService: IApiService): ICourseGroupApi =>
    new BaseApi2<
        GetCourseGroupsFilter,
        GetCourseGroupsExcelFilter,
        GetCourseGroups,
        GetCourseGroupsExcel,
        CourseGroup,
        CourseGroup,
        AddCourseGroup,
        UpdateCourseGroup
    >(apiService, ApiBaseKeys.CourseGroups);
export const getEventApplicationsApiService = (
    apiService: IApiService
): IBaseApi<GetEventApplications, EventApplication> => new BaseApi(apiService, ApiBaseKeys.EventApplications);
export const getCourseApplicationsApiService = (
    apiService: IApiService
): IBaseApi<GetCourseApplications, CourseApplication> => new BaseApi(apiService, ApiBaseKeys.CourseApplications);

export const getProfessionApplicationsApiService = (
    apiService: IApiService
): IBaseApi<GetProfessionApplications, ProfessionApplication> =>
    new BaseApi(apiService, ApiBaseKeys.ProfessionApplications);

export const getProfessionGroupsApiService = (apiService: IApiService): IProfessionGroupApi =>
    new BaseApi2<
        GetProfessionGroupsFilter,
        GetProfessionGroupsExcelFilter,
        GetProfessionGroups,
        GetProfessionGroupsExcel,
        ProfessionGroup,
        ProfessionGroup,
        AddProfessionGroup,
        UpdateProfessionGroup
    >(apiService, ApiBaseKeys.ProfessionGroup);
export const getProfessionApiService = (apiService: IApiService): IProfessionApi =>
    new ProfessionApi(apiService, ApiBaseKeys.Professions);
