import React from 'react';
import { CellRenderType } from 'enums';
import { Group } from 'types';

export const initialColumns: Omit<Group, 'linkRu' | 'linkEn'> = {
    id: '',
    name: '',
    creationDate: new Date(),
    busyEn: false,
    busyRu: false,
};

export const cellRenderMapper: Record<keyof Omit<Group, 'linkRu' | 'linkEn'>, CellRenderType> = {
    id: CellRenderType.TEXT,
    name: CellRenderType.TEXT,
    creationDate: CellRenderType.DATETIME,
    busyEn: CellRenderType.GROUP_ACTION,
    busyRu: CellRenderType.GROUP_ACTION,
};
