import React, { FC, useEffect, useState } from 'react';
import { useProfession, useToaster } from 'contexts';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Controller, FieldErrors, FieldValues, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { AddOrUpdateProfessionLevel } from 'types';
import { getSwaggerError } from 'utils/swagger-errors';
import { DomainType, FormBuilderKeys } from 'enums';
import { EmptyList } from 'components';
import { FormBuilder } from 'containers/form-builder';

import { Button, Heading, InformationCard, Textbox } from '@kl/components-v6';
import { FormRow } from 'containers/news/news/styled';
import { ArrayContainer } from 'containers/events/event/features/styled';
import { ErrorMessage } from 'containers/form-builder/styled';
import { DefaultEditor } from 'react-simple-wysiwyg';
import CourseProfessionLevel from './CourseProfessionLevel';
import moment from 'moment';
import { DATE_FORMAT_WITH_TIME } from 'consts/date';

type FormValues = {
    levels: AddOrUpdateProfessionLevel[];
};

const ProfessionLevels: FC = () => {
    const { item, setItem, levelUpdate, courseSelect, getSelectedCoursesProfessions } = useProfession();
    const { setToaster } = useToaster();
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);

    const { t } = useTranslation(['pages/professions', 'common/shared']);

    useEffect(() => {
        getSelectedCoursesProfessions(item?.groupEn ? DomainType.En : DomainType.Ru);
    }, []);

    const methods = useForm<FormValues>({
        defaultValues: {
            levels:
                item?.levels?.map((r) => ({
                    name: r.name,
                    description: r.description,
                    duration: r.duration,
                    id: r.id,
                    creationDate: r.creationDate,
                    courseProfessionLevels: r.courseProfessionLevels.map((t) => ({
                        number: t.number,
                        courseId: t.course.id,
                        creationDate: t.creationDate,
                    })),
                })) || [],
        },
    });

    const {
        control,
        formState: { errors },
    } = methods;

    const { fields, append, remove } = useFieldArray<FormValues, 'levels'>({
        control,
        name: 'levels',
    });

    const onSubmit = async (data: FieldValues) => {
        try {
            setLoading(true);

            if (id) {
                const newItem = await levelUpdate({ professionId: id, items: data.levels });
                setItem(newItem);

                setToaster({
                    type: 'success',
                    message: t('update-level-success'),
                });
            }
        } catch (e) {
            const swagger = getSwaggerError(e);
            if (swagger) {
                setToaster({
                    type: 'error',
                    message: swagger,
                });
            }
            setToaster({
                type: 'error',
                message: t('backend-error', { ns: 'common/shared' }),
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Heading type={'H2'}>{t('update-level')}</Heading>
            <FormProvider {...methods}>
                <FormBuilder<FormValues>
                    data={{} as FormValues}
                    submit={onSubmit}
                    cancel={() => navigate('/professions/all')}
                    formKey={FormBuilderKeys.ProfessionGeneral}
                    isFormEmpty={!id}
                    loading={loading}
                >
                    {!fields.length && <EmptyList />}
                    {fields.map((field, index) => {
                        return (
                            <InformationCard key={field.id} style={{ marginBottom: 40, width: '100%' }}>
                                <FormRow style={{ margin: '0 0 45px 0' }}>
                                    <span>
                                        {`Level ${index + 1}`}
                                        <br />
                                        {field?.creationDate &&
                                            moment(field?.creationDate).format(DATE_FORMAT_WITH_TIME)}
                                    </span>

                                    <ArrayContainer>
                                        <div style={{ position: 'relative' }}>
                                            <Controller
                                                name={`levels.${index}.name`}
                                                control={control}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: t('required-field', { ns: 'common/errors' }),
                                                    },
                                                    maxLength: {
                                                        value: 100,
                                                        message: t('max-length', {
                                                            ns: 'common/errors',
                                                            length: 100,
                                                        }),
                                                    },
                                                }}
                                                defaultValue={''}
                                                render={({ field: { onChange, value } }) => (
                                                    <Textbox
                                                        value={value}
                                                        allowClear
                                                        onChange={onChange}
                                                        placeholder={t('level-name')}
                                                    />
                                                )}
                                            />

                                            {errors?.levels?.[index]?.name && (
                                                <ErrorMessage style={{ left: 0 }}>
                                                    {errors?.levels?.[index]?.name?.message}
                                                </ErrorMessage>
                                            )}
                                        </div>

                                        <div style={{ position: 'relative' }}>
                                            <Controller
                                                name={`levels.${index}.duration`}
                                                control={control}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: t('required-field', { ns: 'common/errors' }),
                                                    },
                                                    maxLength: {
                                                        value: 100,
                                                        message: t('max-length', {
                                                            ns: 'common/errors',
                                                            length: 100,
                                                        }),
                                                    },
                                                }}
                                                defaultValue={''}
                                                render={({ field: { onChange, value } }) => (
                                                    <Textbox
                                                        value={value}
                                                        allowClear
                                                        onChange={onChange}
                                                        placeholder={t('duration')}
                                                    />
                                                )}
                                            />

                                            {errors?.levels?.[index]?.duration && (
                                                <ErrorMessage style={{ left: 0 }}>
                                                    {errors?.levels?.[index]?.duration?.message}
                                                </ErrorMessage>
                                            )}
                                        </div>

                                        <div style={{ position: 'relative' }}>
                                            <Controller
                                                name={`levels.${index}.description`}
                                                control={control}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: t('required-field', { ns: 'common/errors' }),
                                                    },
                                                    maxLength: {
                                                        value: 250,
                                                        message: t('max-length', {
                                                            ns: 'common/errors',
                                                            length: 250,
                                                        }),
                                                    },
                                                }}
                                                defaultValue={''}
                                                render={({ field: { onChange, value } }) => (
                                                    <DefaultEditor
                                                        containerProps={{ style: { width: '100%' } }}
                                                        value={value as string}
                                                        onChange={(event) => onChange(event.target.value)}
                                                    />
                                                )}
                                            />

                                            {errors?.levels?.[index]?.description && (
                                                <ErrorMessage style={{ left: 0 }}>
                                                    {errors?.levels?.[index]?.description?.message}
                                                </ErrorMessage>
                                            )}
                                        </div>
                                    </ArrayContainer>

                                    <Button mode={'dangerOutlined'} onClick={() => remove(index)}>
                                        {t('delete', { ns: 'common/shared' })}
                                    </Button>
                                </FormRow>
                                <CourseProfessionLevel
                                    courses={courseSelect}
                                    parentIndex={index}
                                    /*@ts-ignore*/
                                    control={control}
                                    errors={errors.levels?.[index] as FieldErrors}
                                />
                            </InformationCard>
                        );
                    })}
                    <Button
                        mode={'primaryBlue'}
                        onClick={() =>
                            append({
                                id: null,
                                name: '',
                                description: '',
                                duration: '',
                                courseProfessionLevels: [],
                            })
                        }
                    >
                        {t('append', { ns: 'common/shared' })}
                    </Button>
                </FormBuilder>
            </FormProvider>
        </>
    );
};

export default ProfessionLevels;
