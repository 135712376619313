import styled from 'styled-components';

export const Label = styled.label`
    margin: 0 0 25px 0;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    position: relative;

    &:hover {
        cursor: pointer;
    }
`;
