import { ITokenApi, IApiService } from 'interfaces';
import { HttpResponse, TokenResponse, UserInfo } from 'types';

class TokenApi implements ITokenApi {
    constructor(private readonly apiService: IApiService) {}

    public getToken(code: string): Promise<HttpResponse<TokenResponse>> {
        return this.apiService.request<TokenResponse>({
            method: 'GET',
            url: '/auth/getToken',
            params: { code },
        });
    }

    public getUserInfo(): Promise<HttpResponse<UserInfo>> {
        return this.apiService.request<UserInfo>({
            method: 'GET',
            url: '/auth/GetUserInfo',
        });
    }
}

export default TokenApi;
