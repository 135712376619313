import { GetFile, formatBytes } from 'kl-b2c-ui-kit';
import { ACCEPTED_IMAGE_FORMATS, HUNDRED_MB_IN_BYTES } from 'consts';
import { ImageType } from 'react-images-uploading';

type ImageFileType = { image: File };
type GetImageFileType = { image: GetFile };

export const fileSize = (
    file: ImageFileType | GetImageFileType | File | GetFile | ImageType | null,
    maxSize = HUNDRED_MB_IN_BYTES,
    acceptedFormats = ACCEPTED_IMAGE_FORMATS,
    t: (key: string, options?: Record<string, number | string>) => string,
    required = true
): boolean | string => {
    if ((!(file as File)?.name && !(file as ImageFileType).image) || (file as GetFile).id) return true;

    const fileSize = (file as File)?.size ?? (file as ImageFileType)?.image?.size ?? 0;

    if (fileSize > maxSize) {
        return t('image-size', {
            ns: 'common/errors',
            size: formatBytes(maxSize),
        });
    }

    if (!required) {
        return true;
    }

    const regex = /(\.[a-zA-Z]{2,5})$/g;
    const fileName =
        (file as File)?.name ??
        (file as ImageFileType)?.image?.name ??
        (file as GetImageFileType)?.image?.fileName ??
        '';
    const extensionRequest = regex.exec(fileName);
    const extension = !extensionRequest ? 'none' : extensionRequest[0];

    if (!acceptedFormats.includes(extension) && !acceptedFormats.toUpperCase().includes(extension.toUpperCase())) {
        return t('file-format', {
            ns: 'common/errors',
            formats: acceptedFormats,
        });
    }

    return true;
};
