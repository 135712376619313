import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEventGroup, useToaster } from 'contexts';
import { FormBuilderKeys } from 'enums';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { Heading, Loader } from '@kl/components-v6';
import { useTranslation } from 'react-i18next';
import { Group as EventGroupModel } from 'types';
import { FormBuilder } from 'containers';
import { AxiosError } from 'axios';

const INITIAL_DATA: Omit<EventGroupModel, 'id' | 'creationDate' | 'busyEn' | 'busyRu' | 'linkRu' | 'linkEn'> = {
    name: '',
};

const EventGroup: FC = () => {
    const { getEventGroup, eventGroup, updateEventGroup, addEventGroup } = useEventGroup();
    const { setToaster } = useToaster();
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);

    const { t } = useTranslation(['pages/news-groups', 'common/shared']);

    const methods = useForm<Omit<EventGroupModel, 'id' | 'creationDate'>>();

    const onSubmit = async (data: FieldValues) => {
        setLoading(true);
        try {
            if (id) {
                await updateEventGroup({ ...data, id } as EventGroupModel);
                setToaster({
                    type: 'success',
                    message: t('update-group-success-event'),
                });
            } else {
                await addEventGroup(data as EventGroupModel);
                navigate('/events/event-groups');
            }
        } catch (e: unknown) {
            setToaster({
                type: 'error',
                message: (e as AxiosError).response?.data as string,
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (id) {
            getEventGroup(id);
        }
    }, [id]);

    if (id && !eventGroup) {
        return <Loader centered size={'large'} tip={t('loading', { ns: 'common/shared' })} />;
    }

    return (
        <>
            <Heading type={'H2'}>{id ? t('update-group-event') : t('add-group-event')}</Heading>
            <FormProvider {...methods}>
                <FormBuilder<Omit<EventGroupModel, 'id' | 'creationDate' | 'busyEn' | 'busyRu' | 'linkRu' | 'linkEn'>>
                    data={eventGroup || INITIAL_DATA}
                    submit={onSubmit}
                    cancel={() => navigate('/events/event-groups')}
                    formKey={FormBuilderKeys.EventGroup}
                    isFormEmpty={!id}
                    loading={loading}
                />
            </FormProvider>
        </>
    );
};

export default EventGroup;
