import { Speaker } from 'types';
import { ControlRendererType } from 'enums';
import { RegisterOptions } from 'react-hook-form';
import { ACCEPTED_IMAGE_FORMATS, TEN_MB_IN_BYTES } from 'consts';
import { GetFile } from 'kl-b2c-ui-kit';
import { fileSize } from 'containers/form-builder/rules';

export const speakerRendererTypes = (
    t: (key: string, options?: Record<string, number | string>) => string
): Record<keyof Omit<Speaker, 'id' | 'creationDate'>, { type: ControlRendererType; rules?: RegisterOptions }> => ({
    firstName: {
        type: ControlRendererType.TextBox,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 255,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 255,
                }),
            },
        },
    },
    lastName: {
        type: ControlRendererType.TextBox,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 255,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 255,
                }),
            },
        },
    },
    position: {
        type: ControlRendererType.TextBox,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 255,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 255,
                }),
            },
        },
    },
    image: {
        type: ControlRendererType.UploadImage,
        rules: {
            required: {
                value: true,
                message: t('required-image', {
                    ns: 'common/errors',
                }),
            },
            validate: (file: File | GetFile) => {
                return fileSize(file, TEN_MB_IN_BYTES, ACCEPTED_IMAGE_FORMATS, t);
            },
        },
    },
    domainType: {
        type: ControlRendererType.DomainType,
    },
    isShowAboutPage: {
        type: ControlRendererType.CheckBox,
    },
    isShowHomePage: {
        type: ControlRendererType.CheckBox,
    },
    isShowCorporatePage: {
        type: ControlRendererType.CheckBox,
    },
    isShowGovernmentPage: {
        type: ControlRendererType.CheckBox,
    },
});
