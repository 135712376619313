import React, { createContext, FC, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { getApiService, getReviewsApiService, getStorageService } from 'services';
import { AddOrUpdateReview, Review } from 'types';
import { generateFormData } from 'kl-b2c-ui-kit';

interface ReviewContext {
    review: Omit<Review, 'id' | 'creationDate'> | null;
    getReview: (id: string) => void;
    addReview: (data: Omit<AddOrUpdateReview, 'id'>) => void;
    updateReview: (data: AddOrUpdateReview) => void;
}

const ReviewContext = createContext<ReviewContext>({} as ReviewContext);

const ReviewProvider: FC<PropsWithChildren> = ({ children }) => {
    const storageService = getStorageService();
    const [review, setReview] = useState<Omit<Review, 'id' | 'creationDate'> | null>(null);
    const apiService = getApiService(storageService);
    const reviewsApiService = getReviewsApiService(apiService);

    const getReview = async (id: string) => {
        const reviews = await reviewsApiService.get(id);
        setReview(reviews.data);
    };

    const addReview = async (data: Omit<AddOrUpdateReview, 'id'>) => {
        const formData = generateFormData(data);
        formData.delete('file');
        formData.append('file.isDelete', `${data.file?.isDelete ?? 'false'}`);
        formData.append('file.image', data.file?.image ?? '');
        const reviews = await reviewsApiService.add(formData);
        setReview(reviews.data);
    };

    const updateReview = async (data: AddOrUpdateReview) => {
        const formData = generateFormData(data);
        formData.delete('file');
        formData.append('file.isDelete', `${data.file?.isDelete ?? 'false'}`);
        formData.append('file.image', data.file?.image ?? '');
        const reviews = await reviewsApiService.update(formData);
        setReview(reviews.data);
    };

    const memoValue = useMemo(
        () => ({
            review,
            getReview,
            addReview,
            updateReview,
        }),
        [getReview, review, addReview, updateReview]
    );

    return <ReviewContext.Provider value={memoValue}>{children}</ReviewContext.Provider>;
};

export const useReview = () => useContext(ReviewContext);

export default ReviewProvider;
