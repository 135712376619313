import { IApiService, IBaseApi2 } from 'interfaces';
import { PaginationOutput, serializeUriParams } from 'kl-b2c-ui-kit';
import { ApiBaseKeys } from 'enums';
import { Key } from 'react';
import { HttpResponse } from 'types';

export class BaseApi2<TFilters, TFiltersExcel, TRequest, TRequestExcel, TTable, TType, TAdd, TUpdate>
    // eslint-disable-next-line prettier/prettier
    implements IBaseApi2<TFilters, TFiltersExcel, TTable, TType, TAdd, TUpdate> {
    constructor(protected readonly apiService: IApiService, protected readonly path: ApiBaseKeys) {}

    public getItems(params: TFilters): Promise<HttpResponse<PaginationOutput<TTable>>> {
        return this.apiService.request<PaginationOutput<TTable>>({
            method: 'GET',
            url: `/${this.path}/getItems`,
            params: this.getParams(params),
        });
    }

    public getExcel(params: TFiltersExcel): Promise<HttpResponse<string>> {
        return this.apiService.request<string>({
            method: 'POST',
            url: `/${this.path}/getExcel`,
            data: this.getExcelParams(params),
        });
    }

    public deleteMany(ids: Key[]): Promise<HttpResponse<string>> {
        return this.apiService.request<string>({
            method: 'DELETE',
            url: `/${this.path}/deleteMany${serializeUriParams('ids', ids as string[])}`,
        });
    }

    public get(id: string): Promise<HttpResponse<TType>> {
        return this.apiService.request<TType>({
            method: 'GET',
            url: `/${this.path}/getItem`,
            params: {
                id,
            },
        });
    }

    public add(data: TAdd): Promise<HttpResponse<TType>> {
        return this.apiService.request<TType>({
            method: 'POST',
            url: `/${this.path}/add`,
            data: this.getAddData(data),
        });
    }

    public update(data: TUpdate): Promise<HttpResponse<TType>> {
        return this.apiService.request<TType>({
            method: 'PUT',
            url: `/${this.path}/update`,
            data: this.getUpdateData(data),
        });
    }

    protected getAddData(data: TAdd): TAdd | FormData {
        return data;
    }

    protected getUpdateData(data: TUpdate): TUpdate | FormData {
        return data;
    }

    protected getParams(data: TFilters): TRequest | TFilters {
        return data;
    }

    protected getExcelParams(data: TFiltersExcel): TRequestExcel | TFiltersExcel {
        return data;
    }

    protected formArrayTransform<T extends {}>(form: FormData, data: T, propName: string, index: number) {
        Object.keys(data).forEach((key) => {
            // @ts-ignore
            if (!data[key]) return;
            // @ts-ignore
            form.append(`${propName}[${index}].${key}`, data[key]);
        });
    }
}
