import React, { FC } from 'react';
import { useSpeakers } from 'contexts';
import { cellRenderMapper, initialColumns } from './mappers';
import { Speaker, Speakers as SpeakersType } from 'types';
import { renderCell, getTableColumns } from 'utils';
import { PageBuilder } from 'containers';
import { FilterType, PageBuilderAdditionalFilters, PageBuilderKey } from 'enums';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Speakers: FC = () => {
    const { speakers, getSpeakers, deleteSpeakers } = useSpeakers();
    const { i18n } = useTranslation('pages/speakers');
    const navigate = useNavigate();

    return (
        <PageBuilder<Speaker>
            pageKey={PageBuilderKey.Speakers}
            data={speakers}
            getItems={(params) => getSpeakers(params as SpeakersType)}
            deleteItems={deleteSpeakers}
            columns={getTableColumns<Omit<Speaker, 'id'>>(
                initialColumns,
                cellRenderMapper,
                renderCell,
                i18n.getResourceBundle(i18n.language, 'pages/speakers')
            )}
            additionalFilters={[
                { type: PageBuilderAdditionalFilters.Dropdown, key: FilterType.DomainType },
                { type: PageBuilderAdditionalFilters.Dropdown, key: FilterType.IsShownAboutPage },
                { type: PageBuilderAdditionalFilters.Dropdown, key: FilterType.IsShowCorporatePage },
                { type: PageBuilderAdditionalFilters.Dropdown, key: FilterType.IsShowGovernmentPage },
                { type: PageBuilderAdditionalFilters.Dropdown, key: FilterType.IsShowHomePage },
            ]}
            addItem={() => navigate('/speaker')}
            updateItem={(speaker: Speaker) => navigate(`/speaker/${speaker.id}`)}
        />
    );
};

export default Speakers;
