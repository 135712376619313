import React, { FC, PropsWithChildren } from 'react';
import { GetFile } from 'kl-b2c-ui-kit';
import { Container, ImageBlock, ImageContainer, LinkFile, CloseButton } from './styled';
import moment from 'moment';
import { DATE_FORMAT_WITH_TIME } from 'consts';

interface ImagePreviewProps {
    image: GetFile;
    size?: number | string;
    clear?: () => void;
}

const ImagePreview: FC<PropsWithChildren<ImagePreviewProps>> = (props) => {
    const {
        image: { fileLink, contentType, fileName, creationDate },
        clear,
        size = 100,
    } = props;

    return (
        <Container
            style={{
                width: typeof size === 'string' ? size : size + 30,
                height: typeof size === 'string' ? size : size + 20,
            }}
        >
            <LinkFile target={'__blank'} href={fileLink}>
                {contentType.includes('image') ? (
                    <ImageBlock>
                        <ImageContainer
                            style={{
                                backgroundImage: `url(${fileLink})`,
                                width: size,
                                height: size,
                            }}
                        />
                        <p>{moment(creationDate).format(DATE_FORMAT_WITH_TIME)}</p>
                    </ImageBlock>
                ) : (
                    <ImageContainer>
                        {fileName}
                        <br />
                        <span>{moment(creationDate).format(DATE_FORMAT_WITH_TIME)}</span>
                    </ImageContainer>
                )}
            </LinkFile>
            {clear && <CloseButton onClick={clear} style={{ cursor: 'pointer' }} />}
        </Container>
    );
};

export default ImagePreview;
