import { getBaseMultipleContext } from 'contexts';
import { useContext } from 'react';
import { getApiService, getCourseGroupsApiService, getStorageService } from 'services';
import {
    AddCourseGroup,
    CourseGroup,
    GetCourseGroupsExcelFilter,
    GetCourseGroupsFilter,
    UpdateCourseGroup,
} from 'types/courses';

const storageService = getStorageService();
const apiService = getApiService(storageService);
const courseApiService = getCourseGroupsApiService(apiService);

const ProviderResult = getBaseMultipleContext<
    GetCourseGroupsFilter,
    GetCourseGroupsExcelFilter,
    CourseGroup,
    CourseGroup,
    AddCourseGroup,
    UpdateCourseGroup
>(courseApiService, 'courseGroups');

export const useCourseGroups = () => useContext(ProviderResult.Context);
export default ProviderResult.Provider;
