import { Link } from '@kl/components-v6';
import { Close2 } from '@kl/icons/12';
import styled from 'styled-components';

export const CloseButton = styled(Close2)`
    width: 12px;
    height: 12px;
    min-width: 12px;
`;

export const LinkFile = styled(Link)`
    max-width: 100%;
`;

export const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 15px;
`;

export const ImageContainer = styled.div`
    background-size: cover;
    background-position: center;
    overflow-wrap: break-word;
    span {
        font-size: 12px;
    }
`;

export const ImageBlock = styled.div`
    display: flex;
    flex-direction: column;
    p {
        font-size: 12px;
    }
`;
