import React, { useEffect } from 'react';
import { Tabs } from '@kl/components-v6';
import { useParams } from 'react-router-dom';
import { General, Teasers, Results, TargetAudiences, Prices } from './features';
import { useCourse } from 'contexts';

const Event = () => {
    const { id } = useParams();
    const { getItem, item } = useCourse();

    useEffect(() => {
        if (id) {
            getItem(id);
        }
    }, [id]);

    if (!id) {
        return <General />;
    }

    return (
        <Tabs defaultActiveKey={'course/general'}>
            <Tabs.TabPane disabled={!item} tab="General" key={'course/general'}>
                <General />
            </Tabs.TabPane>
            <Tabs.TabPane disabled={!item} tab="Teasers" key={'course/teasers'}>
                <Teasers />
            </Tabs.TabPane>
            <Tabs.TabPane disabled={!item} tab="Target audiences" key={'course/targetAudiences'}>
                <TargetAudiences />
            </Tabs.TabPane>
            <Tabs.TabPane disabled={!item} tab="Results" key={'course/results'}>
                <Results />
            </Tabs.TabPane>
            <Tabs.TabPane disabled={!item} tab="Prices" key={'course/prices'}>
                <Prices />
            </Tabs.TabPane>
        </Tabs>
    );
};

export default Event;
