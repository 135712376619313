export enum EventFormType {
    All = 0,
    Business = 1,
    Student = 2,
    Teacher = 3,
}

export enum EventType {
    Online = 1,
    Offline = 2,
}
