import React from 'react';
import { EventApplication } from 'types';
import { EventFormType, CellRenderType } from 'enums';

export const initialColumns: EventApplication = {
    id: '',
    creationDate: new Date(),
    firstName: '',
    lastName: '',
    email: '',
    nickname: '',
    phone: '',
    city: '',
    company: '',
    position: '',
    formType: EventFormType.Business,
    agreementId: '',
    eventId: '',
    studyCourse: undefined,
    studyLevel: undefined,
    utmTerm: '',
    utmMedium: '',
    utmCampaign: '',
    utmSource: '',
    utmContent: '',
    marketingIsSelected: false,
};

export const cellRenderMapper: Record<keyof EventApplication, CellRenderType> = {
    id: CellRenderType.TEXT,
    creationDate: CellRenderType.DATETIME,
    firstName: CellRenderType.TEXT,
    lastName: CellRenderType.TEXT,
    email: CellRenderType.LINK,
    nickname: CellRenderType.TEXT,
    phone: CellRenderType.TEXT,
    city: CellRenderType.TEXT,
    company: CellRenderType.TEXT,
    position: CellRenderType.TEXT,
    formType: CellRenderType.EVENT_FORM_TYPE,
    agreementId: CellRenderType.AGREEMENT_ID,
    eventId: CellRenderType.EVENT_ID,
    utmTerm: CellRenderType.TEXT,
    utmMedium: CellRenderType.TEXT,
    utmCampaign: CellRenderType.TEXT,
    utmSource: CellRenderType.TEXT,
    utmContent: CellRenderType.TEXT,
    studyCourse: CellRenderType.COURSE_STUDY,
    studyLevel: CellRenderType.LEVEL_STUDY,
    marketingIsSelected: CellRenderType.BOOLEAN,
};
