import { News } from 'types';
import { ControlRendererType } from 'enums';
import { RegisterOptions } from 'react-hook-form';
import { GetFile } from 'kl-b2c-ui-kit';
import { fileSize } from 'containers/form-builder/rules';
import { ACCEPTED_IMAGE_FORMATS_EXCEPT_GIF, TEN_MB_IN_BYTES, THREE_MB_IN_BYTES } from 'consts';

export const newsRendererTypes = (
    t: (key: string, options?: Record<string, number | string>) => string
): Record<keyof News, { type: ControlRendererType; rules?: RegisterOptions; size?: number }> => ({
    id: {
        type: ControlRendererType.Text,
    },
    name: {
        type: ControlRendererType.TextBox,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 255,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 255,
                }),
            },
        },
    },
    displayDate: {
        type: ControlRendererType.Calendar,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
        },
    },
    baseImage: {
        type: ControlRendererType.UploadImageExceptGif,
        rules: {
            required: {
                value: true,
                message: t('required-image', {
                    ns: 'common/errors',
                }),
            },
            validate: (file: File | GetFile) => {
                return fileSize(file, THREE_MB_IN_BYTES, ACCEPTED_IMAGE_FORMATS_EXCEPT_GIF, t);
            },
        },
        size: THREE_MB_IN_BYTES,
    },
    additionalImage: {
        type: ControlRendererType.UploadImageExceptGif,
        rules: {
            required: {
                value: true,
                message: t('required-image', {
                    ns: 'common/errors',
                }),
            },
            validate: (file: File | GetFile) => {
                return fileSize(file, THREE_MB_IN_BYTES, ACCEPTED_IMAGE_FORMATS_EXCEPT_GIF, t);
            },
        },
        size: THREE_MB_IN_BYTES,
    },
    isVisibleOnHomePage: {
        type: ControlRendererType.CheckBox,
    },
    isVisible: {
        type: ControlRendererType.CheckBox,
    },
    baseDescription: {
        type: ControlRendererType.Wysiwyg,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 6000,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 6000,
                }),
            },
        },
    },
    groupEn: {
        type: ControlRendererType.Hidden,
    },
    groupRu: {
        type: ControlRendererType.Hidden,
    },
    photos: {
        type: ControlRendererType.Hidden,
    },
    tags: {
        type: ControlRendererType.Hidden,
    },
});
