export enum CellRenderType {
    BOOLEAN = 'BOOLEAN',
    DATETIME = 'DATETIME',
    TEXT = 'TEXT',
    MARKUP = 'MARKUP',
    CHAPTER = 'CHAPTER',
    IS_LEGAL = 'IS_LEGAL',
    FILE = 'FILE',
    DOMAIN_TYPE = 'DOMAIN_TYPE',
    IMAGE_PREVIEW = 'IMAGE_PREVIEW',
    AGREEMENT_ID = 'AGREEMENT_ID',
    EVENT_ID = 'EVENT_ID',
    ORGANIZATION_ID = 'ORGANIZATION_ID',
    COURSE_ID = 'COURSE_ID',
    EVENT_FORM_TYPE = 'EVENT_FORM_TYPE',
    COURSE_FORM_TYPE = 'COURSE_FORM_TYPE',
    GROUP = 'GROUP',
    GROUP_ACTION = 'GROUP_ACTION',
    CONFERENCE = 'CONFERENCE',
    LINK = 'LINK',
    COURSE_STUDY = 'COURSE_STUDY',
    LEVEL_STUDY = 'LEVEL_STUDY',
    ARRAY_OF_VALUES = 'ARRAY_OF_VALUES',
    PROFESSION_GROUP_TYPE = 'PROFESSION_GROUP_TYPE',
    COURSE_GROUP_TYPE = 'COURSE_GROUP_TYPE',
}
