import { PageBuilder } from 'containers';
import { useAgreements } from 'contexts';
import { FilterType, PageBuilderAdditionalFilters, PageBuilderKey } from 'enums';
import { useQuery } from 'hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Agreement, GetAgreementsFilters } from 'types/agreements';
import { renderCell, getTableColumns } from 'utils';
import { cellRenderMapper, initialColumns } from './mappers';

const Agreements: FC = () => {
    const { agreements, getAgreements, getExcel, deleteAgreements, getAgreement } = useAgreements();
    const { i18n } = useTranslation('pages/agreements');

    const query = useQuery();

    return (
        <PageBuilder<Agreement>
            pageKey={PageBuilderKey.Agreements}
            data={agreements}
            getItems={(params) => getAgreements(params as GetAgreementsFilters)}
            getItem={(id: string) => getAgreement(id)}
            getExcel={(params) => getExcel(params as Omit<GetAgreementsFilters, 'page' | 'size'>)}
            deleteItems={deleteAgreements}
            columns={getTableColumns<Omit<Agreement, 'id'>>(
                initialColumns,
                cellRenderMapper,
                renderCell,
                i18n.getResourceBundle(i18n.language, 'pages/agreements')
            )}
            additionalFilters={[
                { type: PageBuilderAdditionalFilters.Dropdown, key: FilterType.Chapter },
                { type: PageBuilderAdditionalFilters.Dropdown, key: FilterType.MarketingIsSelected },
                { type: PageBuilderAdditionalFilters.Dropdown, key: FilterType.PrivacyPolicyIsSelected },
                {
                    type: PageBuilderAdditionalFilters.DebounceTextBox,
                    key: FilterType.Email,
                    defaultValue: query.get('email') || '',
                },
            ]}
        />
    );
};

export default Agreements;
