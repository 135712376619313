import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Controller, FieldValues, useFormContext } from 'react-hook-form';
import { DefaultEditor } from 'react-simple-wysiwyg';
import { Footer, FormRow } from './components';
import { ImagePreview, ImageUploader } from 'components';
import { enumsToOptions, getControlRenderer } from './mappers';
import { ControlRendererType, FormBuilderKeys } from 'enums';
import { ControlRendererConfig } from 'types';
import { ErrorMessage, FormWrapper } from './styled';
import { useTranslation } from 'react-i18next';
import { Calendar, Checkbox, Icon, Select, Textbox, Toggle } from '@kl/components-v6';
import { GetFile } from 'kl-b2c-ui-kit';
import {
    ACCEPTED_IMAGE_FORMATS,
    ACCEPTED_IMAGE_FORMATS_EXCEPT_GIF,
    ACCEPTED_IMAGE_FORMATS_EXCEPT_GIF_SWG,
    DATE_FORMAT,
    DATE_FORMAT_WITH_TIME,
    EVENTS_FILES,
} from 'consts';
import DOMPurify from 'isomorphic-dompurify';
import { colors } from '@kl/components-v6/design-system/theme/themes/dark/colors';
import { Link, useNavigate } from 'react-router-dom';

interface FormBuilderProps<TData> {
    data: TData;
    submit?: (params: FieldValues) => void;
    formKey: FormBuilderKeys;
    loading: boolean;
    cancel?: () => void;
    onDelete?: () => void;
    isFormEmpty?: boolean;
}

const FormBuilder = <TData,>(props: PropsWithChildren<FormBuilderProps<TData>>) => {
    const [config, setConfig] = useState<Record<string, ControlRendererConfig> | null>(null);
    const { data, submit, formKey, cancel, isFormEmpty, loading, children, onDelete } = props;
    const { t } = useTranslation(['pages/form-builder', 'common/shared', 'common/errors']);

    const {
        handleSubmit,
        reset,
        // watch,
        control,
        register,
        setValue,
        formState: { errors },
    } = useFormContext();

    useEffect(() => {
        if (data) {
            const conf = getControlRenderer<TData>(formKey, data, t);
            setConfig(conf);
        }
    }, [data]);

    if (!config) {
        return null;
    }

    const innerSubmit = (params: FieldValues) => {
        if (submit) {
            submit(params);
        }
        // reset(watch(), { keepValues: false, keepDirty: false, keepDefaultValues: false });
    };

    return (
        <FormWrapper className={loading ? 'disabled' : ''}>
            {Object.values(config).map((value: ControlRendererConfig) => {
                const {
                    config: { type, rules, size, readonly, extraKey },
                    controlValue,
                    key,
                } = value;

                const error = errors[key]?.message as string;

                if (type === ControlRendererType.Hidden) return null;

                return (
                    <FormRow key={key} label={t(key, { ns: 'common/shared' })} required={!!rules?.required}>
                        {(() => {
                            switch (type) {
                                case ControlRendererType.Toggle:
                                    return (
                                        <Controller
                                            name={key}
                                            control={control}
                                            rules={rules}
                                            defaultValue={controlValue}
                                            render={({ field: { onChange, value } }) => (
                                                <Toggle
                                                    checked={value !== undefined ? value : controlValue}
                                                    onChange={(val: boolean) => onChange(val)}
                                                />
                                            )}
                                        />
                                    );
                                case ControlRendererType.Number:
                                    return (
                                        <Controller
                                            name={key}
                                            control={control}
                                            rules={rules}
                                            defaultValue={controlValue || ''}
                                            render={({ field: { onChange, value } }) => (
                                                <Textbox
                                                    type={'number'}
                                                    value={value}
                                                    invalid={!!errors[key]?.message}
                                                    allowClear
                                                    onChange={onChange}
                                                    placeholder={t(key, { ns: 'common/shared' })}
                                                />
                                            )}
                                        />
                                    );
                                case ControlRendererType.DateTime:
                                    return <span>{new Date(controlValue as Date).toLocaleString('ru')}</span>;
                                case ControlRendererType.Text:
                                    return (
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: DOMPurify.sanitize(controlValue as string),
                                            }}
                                        />
                                    );
                                case ControlRendererType.TimePicker:
                                    return (
                                        <Controller
                                            name={key}
                                            control={control}
                                            rules={rules}
                                            defaultValue={controlValue ? new Date(controlValue as string) : null}
                                            render={({ field: { onChange, value } }) => (
                                                <Calendar
                                                    showTime
                                                    value={value}
                                                    style={{ width: 150 }}
                                                    placeholder={t('displayDate', { ns: 'common/shared' })}
                                                    format={DATE_FORMAT_WITH_TIME}
                                                    allowClear
                                                    onOk={(value: Date | null) => onChange(value)}
                                                />
                                            )}
                                        />
                                    );
                                case ControlRendererType.Calendar:
                                    return (
                                        <Controller
                                            name={key}
                                            control={control}
                                            rules={rules}
                                            defaultValue={controlValue ? new Date(controlValue as string) : null}
                                            render={({ field: { onChange, value } }) => (
                                                <Calendar
                                                    value={value}
                                                    style={{ width: 150 }}
                                                    placeholder={t('displayDate', { ns: 'common/shared' })}
                                                    format={DATE_FORMAT}
                                                    allowClear
                                                    onChange={(value: Date | null) => onChange(value)}
                                                />
                                            )}
                                        />
                                    );
                                case ControlRendererType.CheckBox:
                                    if (readonly) {
                                        const config = {
                                            name: controlValue ? 'Check' : 'Minus',
                                            color: controlValue
                                                ? colors['criticalitystatuses'].positive
                                                : colors['criticalitystatuses'].critical,
                                        };

                                        return (
                                            <Icon
                                                key={window.crypto.randomUUID()}
                                                size={'small'}
                                                // @ts-ignore
                                                name={config.name}
                                                color={config.color}
                                            />
                                        );
                                    }
                                    return (
                                        <Controller
                                            name={key}
                                            control={control}
                                            rules={rules}
                                            defaultValue={controlValue}
                                            render={({ field: { onChange, value } }) => (
                                                <Checkbox defaultChecked={value} onChange={onChange} />
                                            )}
                                        />
                                    );
                                case ControlRendererType.TextBox:
                                    return (
                                        <Controller
                                            name={key}
                                            control={control}
                                            rules={rules}
                                            defaultValue={controlValue || ''}
                                            render={({ field: { onChange, value } }) => (
                                                <Textbox
                                                    value={value}
                                                    invalid={!!errors[key]?.message}
                                                    allowClear
                                                    onChange={onChange}
                                                    placeholder={t(key, { ns: 'common/shared' })}
                                                />
                                            )}
                                        />
                                    );
                                case ControlRendererType.UploadDocs: {
                                    return (
                                        <Controller
                                            name={key}
                                            control={control}
                                            rules={rules}
                                            defaultValue={controlValue}
                                            render={({ field: { onChange, value } }) => {
                                                if ((value as GetFile)?.id) {
                                                    return (
                                                        <ImagePreview
                                                            clear={() => {
                                                                if (
                                                                    window.confirm(
                                                                        `${t('delete-confirm', {
                                                                            ns: 'common/shared',
                                                                        })}`
                                                                    )
                                                                ) {
                                                                    onChange(null);
                                                                }
                                                            }}
                                                            size={150}
                                                            image={controlValue as GetFile}
                                                        />
                                                    );
                                                }

                                                return (
                                                    <ImageUploader
                                                        accept={EVENTS_FILES}
                                                        maxSize={size}
                                                        change={onChange}
                                                    />
                                                );
                                            }}
                                        />
                                    );
                                }
                                case ControlRendererType.UploadDocsExtra: {
                                    return (
                                        <Controller
                                            name={extraKey ?? key}
                                            control={control}
                                            rules={rules}
                                            defaultValue={{
                                                isDelete: false,
                                                image: controlValue,
                                            }}
                                            render={({ field: { onChange, value } }) => {
                                                if ((value?.image as GetFile)?.id) {
                                                    return (
                                                        <ImagePreview
                                                            clear={() => {
                                                                if (
                                                                    window.confirm(
                                                                        `${t('delete-confirm', {
                                                                            ns: 'common/shared',
                                                                        })}`
                                                                    )
                                                                ) {
                                                                    onChange({
                                                                        isDelete: true,
                                                                        image: null,
                                                                    });
                                                                }
                                                            }}
                                                            size={150}
                                                            image={controlValue as GetFile}
                                                        />
                                                    );
                                                }

                                                return (
                                                    <ImageUploader
                                                        maxSize={size}
                                                        accept={EVENTS_FILES}
                                                        change={(v) =>
                                                            onChange({
                                                                isDelete: true,
                                                                image: v,
                                                            })
                                                        }
                                                    />
                                                );
                                            }}
                                        />
                                    );
                                }
                                case ControlRendererType.UploadImage: {
                                    return (
                                        <Controller
                                            name={key}
                                            control={control}
                                            rules={rules}
                                            defaultValue={controlValue}
                                            render={({ field: { onChange, value } }) => {
                                                if ((value as GetFile)?.id) {
                                                    return (
                                                        <ImagePreview
                                                            clear={() => {
                                                                if (
                                                                    window.confirm(
                                                                        `${t('delete-confirm', {
                                                                            ns: 'common/shared',
                                                                        })}`
                                                                    )
                                                                ) {
                                                                    onChange(null);
                                                                }
                                                            }}
                                                            size={150}
                                                            image={controlValue as GetFile}
                                                        />
                                                    );
                                                }

                                                return (
                                                    <ImageUploader
                                                        accept={ACCEPTED_IMAGE_FORMATS}
                                                        maxSize={size}
                                                        change={onChange}
                                                    />
                                                );
                                            }}
                                        />
                                    );
                                }
                                case ControlRendererType.UploadImageExceptGif: {
                                    return (
                                        <Controller
                                            name={key}
                                            control={control}
                                            rules={rules}
                                            defaultValue={controlValue}
                                            render={({ field: { onChange, value } }) => {
                                                if ((value as GetFile)?.id) {
                                                    return (
                                                        <ImagePreview
                                                            clear={() => {
                                                                if (
                                                                    window.confirm(
                                                                        `${t('delete-confirm', {
                                                                            ns: 'common/shared',
                                                                        })}`
                                                                    )
                                                                ) {
                                                                    onChange(null);
                                                                }
                                                            }}
                                                            size={150}
                                                            image={controlValue as GetFile}
                                                        />
                                                    );
                                                }

                                                return (
                                                    <ImageUploader
                                                        maxSize={size}
                                                        accept={ACCEPTED_IMAGE_FORMATS_EXCEPT_GIF}
                                                        change={onChange}
                                                    />
                                                );
                                            }}
                                        />
                                    );
                                }
                                case ControlRendererType.UploadImageExceptGifSwg: {
                                    return (
                                        <Controller
                                            name={key}
                                            control={control}
                                            rules={rules}
                                            defaultValue={controlValue}
                                            render={({ field: { onChange, value } }) => {
                                                if ((value as GetFile)?.id) {
                                                    return (
                                                        <ImagePreview
                                                            clear={() => {
                                                                if (
                                                                    window.confirm(
                                                                        `${t('delete-confirm', {
                                                                            ns: 'common/shared',
                                                                        })}`
                                                                    )
                                                                ) {
                                                                    onChange(null);
                                                                }
                                                            }}
                                                            size={150}
                                                            image={controlValue as GetFile}
                                                        />
                                                    );
                                                }

                                                return (
                                                    <ImageUploader
                                                        maxSize={size}
                                                        accept={ACCEPTED_IMAGE_FORMATS_EXCEPT_GIF_SWG}
                                                        change={onChange}
                                                    />
                                                );
                                            }}
                                        />
                                    );
                                }
                                case ControlRendererType.UploadImageExceptGifSwgExtra: {
                                    return (
                                        <Controller
                                            name={extraKey ?? key}
                                            control={control}
                                            rules={rules}
                                            defaultValue={{
                                                isDelete: false,
                                                image: controlValue,
                                            }}
                                            render={({ field: { onChange, value } }) => {
                                                if ((value?.image as GetFile)?.id) {
                                                    return (
                                                        <ImagePreview
                                                            clear={() => {
                                                                if (
                                                                    window.confirm(
                                                                        `${t('delete-confirm', {
                                                                            ns: 'common/shared',
                                                                        })}`
                                                                    )
                                                                ) {
                                                                    onChange({
                                                                        isDelete: true,
                                                                        image: null,
                                                                    });
                                                                }
                                                            }}
                                                            size={150}
                                                            image={controlValue as GetFile}
                                                        />
                                                    );
                                                }

                                                return (
                                                    <ImageUploader
                                                        maxSize={size}
                                                        accept={ACCEPTED_IMAGE_FORMATS_EXCEPT_GIF_SWG}
                                                        change={(v) =>
                                                            onChange({
                                                                isDelete: true,
                                                                image: v,
                                                            })
                                                        }
                                                    />
                                                );
                                            }}
                                        />
                                    );
                                }
                                case ControlRendererType.DomainType:
                                    const domainOptions = enumsToOptions(type);

                                    return (
                                        <Controller
                                            name={key}
                                            control={control}
                                            rules={rules}
                                            defaultValue={controlValue}
                                            render={({ field: { onChange } }) => (
                                                <Select
                                                    disabled={readonly}
                                                    defaultValue={controlValue as string}
                                                    onSelect={(value) => onChange(value as string)}
                                                    options={domainOptions}
                                                />
                                            )}
                                        />
                                    );
                                case ControlRendererType.ProfessionGroupType:
                                    const professionGroupTypeOptions = enumsToOptions(type);
                                    return (
                                        <Controller
                                            name={key}
                                            control={control}
                                            rules={rules}
                                            defaultValue={controlValue}
                                            render={({ field: { onChange } }) => (
                                                <Select
                                                    disabled={readonly}
                                                    defaultValue={controlValue as string}
                                                    onSelect={(value) => onChange(value as string)}
                                                    options={professionGroupTypeOptions}
                                                />
                                            )}
                                        />
                                    );
                                case ControlRendererType.CourseGroupType:
                                    const courseGroupTypeOptions = enumsToOptions(type);
                                    return (
                                        <Controller
                                            name={key}
                                            control={control}
                                            rules={rules}
                                            defaultValue={controlValue}
                                            render={({ field: { onChange } }) => (
                                                <Select
                                                    disabled={readonly}
                                                    defaultValue={controlValue as string}
                                                    onSelect={(value) => onChange(value as string)}
                                                    options={courseGroupTypeOptions}
                                                />
                                            )}
                                        />
                                    );
                                case ControlRendererType.AgreementId:
                                    return <Link to={`/agreements/${controlValue}`}>{controlValue as string}</Link>;
                                case ControlRendererType.CourseId:
                                    return <Link to={`/courses/all/${controlValue}`}>{controlValue as string}</Link>;
                                case ControlRendererType.ProfessionId:
                                    return (
                                        <Link to={`/professions/all/${controlValue}`}>{controlValue as string}</Link>
                                    );
                                case ControlRendererType.DurationType:
                                case ControlRendererType.CourseSkill:
                                case ControlRendererType.CourseLevel:
                                case ControlRendererType.CourseFormType:
                                case ControlRendererType.StudyCourse:
                                case ControlRendererType.StudyLevel:
                                case ControlRendererType.EventType:
                                case ControlRendererType.FormType:
                                    const eventTypeOptions = enumsToOptions(type);

                                    return (
                                        <Controller
                                            name={key}
                                            control={control}
                                            rules={rules}
                                            defaultValue={controlValue}
                                            render={({ field: { onChange } }) => (
                                                <Select
                                                    disabled={readonly}
                                                    defaultValue={controlValue as string}
                                                    onSelect={(value) => onChange(value as string)}
                                                    options={eventTypeOptions}
                                                />
                                            )}
                                        />
                                    );
                                case ControlRendererType.Wysiwyg:
                                    return (
                                        <Controller
                                            name={key}
                                            control={control}
                                            rules={rules}
                                            defaultValue={controlValue ?? ''}
                                            render={({ field: { onChange, value } }) => {
                                                return (
                                                    <DefaultEditor
                                                        containerProps={{ style: { width: '100%' } }}
                                                        onClick={(e) => {
                                                            // Strange behaviour for cursor, so I have to prevent it
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                        }}
                                                        value={value}
                                                        onChange={(event) => onChange(event.target.value)}
                                                    />
                                                );
                                            }}
                                        />
                                    );
                                default:
                                    throw new Error(
                                        `There is no control type for ${type}, consider to add one in to switch`
                                    );
                            }
                        })()}
                        {error && <ErrorMessage>{t(error, { ns: 'common/errors' })}</ErrorMessage>}
                    </FormRow>
                );
            })}

            {children}

            <Footer
                submit={handleSubmit(innerSubmit)}
                cancel={cancel || reset}
                onDelete={onDelete}
                res={{
                    submitBtnText: isFormEmpty ? t('addBtnText') : t('updateBtnText'),
                    cancelBtnText: t('cancelBtnText'),
                    deleteBtnText: t('deleteBtnText') || 'Delete',
                }}
                loading={loading}
            />
        </FormWrapper>
    );
};

export default FormBuilder;
