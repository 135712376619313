export enum FormBuilderKeys {
    Config = 'Config',
    Speaker = 'Speaker',
    NewsGroup = 'NewsGroup',
    News = 'News',
    EventGroup = 'EventGroup',
    EventGeneral = 'EventGeneral',
    CourseGroup = 'CourseGroup',
    ProfessionGroup = 'ProfessionGroup',
    CourseGeneral = 'CourseGeneral',
    ProfessionGeneral = 'ProfessionGeneral',
    Review = 'Review',
    Organization = 'Organization',
    CourseApplication = 'CourseApplication',
}
