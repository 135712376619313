import React from 'react';
import { Review } from 'types';
import { DomainType, CellRenderType } from 'enums';
import { GetFile } from 'kl-b2c-ui-kit';

export const initialColumns: Omit<Review, 'id'> = {
    creationDate: new Date(),
    firstName: '',
    lastName: '',
    position: '',
    isVisibleCorporateBusinessPage: false,
    isVisibleGovernmentPage: false,
    isVisibleHomePage: false,
    text: '',
    domain: DomainType.Ru,
    image: {} as GetFile,
};

export const cellRenderMapper: Record<keyof Omit<Review, 'id'>, CellRenderType> = {
    creationDate: CellRenderType.DATETIME,
    firstName: CellRenderType.TEXT,
    lastName: CellRenderType.TEXT,
    position: CellRenderType.TEXT,
    text: CellRenderType.MARKUP,
    isVisibleCorporateBusinessPage: CellRenderType.BOOLEAN,
    isVisibleGovernmentPage: CellRenderType.BOOLEAN,
    isVisibleHomePage: CellRenderType.BOOLEAN,
    domain: CellRenderType.DOMAIN_TYPE,
    image: CellRenderType.IMAGE_PREVIEW,
};
