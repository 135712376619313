import {
    ConditionCategory,
    ControlRendererType,
    CourseDirection,
    CourseFormType,
    CourseForWhom,
    CourseGroupType,
    CourseLevel,
    CourseSkill,
    DomainType,
    DurationType,
    EventFormType,
    EventType,
    LanguageType,
    ProfessionGroupType,
    StudyCourse,
    StudyLevel,
} from 'enums';

export const enumsToOptions = (type: ControlRendererType): { value: string | number; label: string }[] => {
    switch (type) {
        case ControlRendererType.DomainType: {
            return [
                {
                    label: 'Ru',
                    value: DomainType.Ru,
                },
                {
                    label: 'En',
                    value: DomainType.En,
                },
            ];
        }
        case ControlRendererType.CourseStudyForms:
        case ControlRendererType.EventType: {
            return [
                {
                    label: 'Online',
                    value: EventType.Online,
                },
                {
                    label: 'Offline',
                    value: EventType.Offline,
                },
            ];
        }
        case ControlRendererType.Languages: {
            return [
                {
                    label: 'Russian',
                    value: LanguageType.Russian,
                },
                {
                    label: 'English',
                    value: LanguageType.English,
                },
            ];
        }
        case ControlRendererType.DurationType: {
            return [
                {
                    label: 'long-term',
                    value: DurationType.LongTerm,
                },
                {
                    label: 'short-term',
                    value: DurationType.ShortTerm,
                },
            ];
        }
        case ControlRendererType.CourseForWhom: {
            return [
                {
                    label: 'For your employees',
                    value: CourseForWhom.ForEmployers,
                },
                {
                    label: 'For myself',
                    value: CourseForWhom.ForMySelf,
                },
                {
                    label: 'For students',
                    value: CourseForWhom.ForStudents,
                },
            ];
        }
        case ControlRendererType.ConditionCategory: {
            return [
                {
                    label: 'Knowledge',
                    value: ConditionCategory.Knowledge,
                },
                {
                    label: 'Skill',
                    value: ConditionCategory.Skill,
                },
            ];
        }
        case ControlRendererType.CourseDirectionTypes: {
            return [
                {
                    label: 'Advanced cybersecurity',
                    value: CourseDirection.AdvancedCybersecurity,
                },
                {
                    label: 'Cybersecurity for IT professionals',
                    value: CourseDirection.ForItProfessionals,
                },
                {
                    label: 'Management and communications',
                    value: CourseDirection.ManagementAndCommunications,
                },
                {
                    label: 'Sales and marketing',
                    value: CourseDirection.SalesAndMarketing,
                },
                {
                    label: 'Cybersecurity for top managers',
                    value: CourseDirection.ForTopManagers,
                },
                {
                    label: 'Cybersecurity for students and internships',
                    value: CourseDirection.ForStudents,
                },
                {
                    label: 'Cybersecurity awareness for employees',
                    value: CourseDirection.ForEmployeesAwareness,
                },
                {
                    label: 'Free cyber hygiene course',
                    value: CourseDirection.FreeCourse,
                },
            ];
        }
        case ControlRendererType.CourseSkill: {
            return [
                {
                    label: 'Communication and management',
                    value: CourseSkill.CommunicatesManagers,
                },
                {
                    label: 'Cybersecurity awareness',
                    value: CourseSkill.KnowledgeCyberSecurity,
                },
                {
                    label: 'Technical',
                    value: CourseSkill.Technical,
                },
            ];
        }
        case ControlRendererType.CourseLevel: {
            return [
                {
                    label: 'Advanced',
                    value: CourseLevel.Advanced,
                },
                {
                    label: 'Basic',
                    value: CourseLevel.Basic,
                },
                {
                    label: 'Expert',
                    value: CourseLevel.Expert,
                },
                {
                    label: 'For Everyone',
                    value: CourseLevel.ForEveryOne,
                },
            ];
        }
        case ControlRendererType.CourseFormTypes:
        case ControlRendererType.CourseFormType: {
            return [
                {
                    label: 'Company',
                    value: CourseFormType.Company,
                },
                {
                    label: 'Individual',
                    value: CourseFormType.Individual,
                },
                {
                    label: 'Student',
                    value: CourseFormType.Student,
                },
                {
                    label: 'Teacher',
                    value: CourseFormType.Teacher,
                },
                {
                    label: 'XTraining',
                    value: CourseFormType.XTraining,
                },
            ];
        }
        case ControlRendererType.FormTypes:
        case ControlRendererType.FormType: {
            return [
                {
                    label: 'Business',
                    value: EventFormType.Business,
                },
                {
                    label: 'Student',
                    value: EventFormType.Student,
                },
                {
                    label: 'Teacher',
                    value: EventFormType.Teacher,
                },
            ];
        }
        case ControlRendererType.StudyCourse: {
            return [
                {
                    label: 'First course',
                    value: StudyCourse.Course1,
                },
                {
                    label: 'Second course',
                    value: StudyCourse.Course2,
                },
                {
                    label: 'Third course',
                    value: StudyCourse.Course3,
                },
                {
                    label: 'Fourth course',
                    value: StudyCourse.Course4,
                },
                {
                    label: 'Fifth course',
                    value: StudyCourse.Course5,
                },
                {
                    label: 'SIxth course',
                    value: StudyCourse.Course6,
                },
            ];
        }
        case ControlRendererType.StudyLevel: {
            return [
                {
                    label: 'Bachelor',
                    value: StudyLevel.Bachelor,
                },
                {
                    label: 'Magistracy',
                    value: StudyLevel.Magistracy,
                },
                {
                    label: 'Specialty',
                    value: StudyLevel.Specialty,
                },
                {
                    label: 'Postgraduate',
                    value: StudyLevel.Postgraduate,
                },
            ];
        }
        case ControlRendererType.ProfessionGroupType: {
            return [
                {
                    label: 'None',
                    value: ProfessionGroupType.None,
                },
                {
                    label: 'Safeboard',
                    value: ProfessionGroupType.Safeboard,
                },
                {
                    label: 'Alliance',
                    value: ProfessionGroupType.Alliance,
                },
            ];
        }
        case ControlRendererType.CourseGroupType: {
            return [
                {
                    label: 'None',
                    value: CourseGroupType.None,
                },
                {
                    label: 'Free',
                    value: CourseGroupType.Free,
                },
            ];
        }
        default: {
            throw new Error(`There is no options provided for ${type}. Consider to add one to switch.`);
        }
    }
};
