import { Button, Heading, Loader, Textbox } from '@kl/components-v6';
import EmptyList from 'components/empty-list';
import { DATE_FORMAT_WITH_TIME } from 'consts/date';
import { FormBuilder } from 'containers';
import { ErrorMessage } from 'containers/form-builder/styled';
import { FormRow } from 'containers/news/news/styled';
import { useCourse, useToaster } from 'contexts';
import { FormBuilderKeys } from 'enums';
import moment from 'moment';
import React, { FC, useState } from 'react';
import { Controller, FieldValues, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { DefaultEditor } from 'react-simple-wysiwyg';
import { AddOrUpdateCoursePrice } from 'types';
import { ArrayContainer } from '../../../events/event/features/styled';

type FormValues = {
    prices: AddOrUpdateCoursePrice[];
};

const Prices: FC = () => {
    const { item, updatePrices, setItem } = useCourse();
    const { setToaster } = useToaster();
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);

    const { t } = useTranslation(['pages/courses', 'common/shared']);

    const methods = useForm<FormValues>({ defaultValues: { prices: item?.prices || [] } });

    const {
        control,
        formState: { errors },
    } = methods;

    const { fields, append, remove } = useFieldArray<FormValues, 'prices'>({
        control,
        name: 'prices',
    });

    const onSubmit = async (data: FieldValues) => {
        setLoading(true);

        if (id) {
            const result = await updatePrices({
                courseId: id,
                items: data.prices,
            });
            setItem(result);
            setToaster({
                type: 'success',
                message: t('update-prices-success'),
            });
        }

        setLoading(false);
    };

    if (id && !item?.prices) {
        return <Loader centered size={'large'} tip={t('loading', { ns: 'common/shared' })} />;
    }
    return (
        <>
            <Heading type={'H2'}>{t('update-prices')}</Heading>
            <FormProvider {...methods}>
                <FormBuilder<FormValues>
                    data={{} as FormValues}
                    submit={onSubmit}
                    cancel={() => navigate('/courses/all')}
                    formKey={FormBuilderKeys.CourseGeneral}
                    isFormEmpty={!id}
                    loading={loading}
                >
                    <>
                        {!fields.length && <EmptyList />}
                        {fields.map((field, index) => {
                            return (
                                <FormRow key={field.id} style={{ margin: '0 0 45px 0' }}>
                                    <span>
                                        {`Target price ${index + 1}`}
                                        <br />
                                        {field?.creationDate &&
                                            moment(field?.creationDate).format(DATE_FORMAT_WITH_TIME)}
                                    </span>

                                    <ArrayContainer>
                                        <div style={{ position: 'relative' }}>
                                            <Controller
                                                name={`prices.${index}.name`}
                                                control={control}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: t('required-field', {
                                                            ns: 'common/errors',
                                                        }),
                                                    },
                                                    maxLength: {
                                                        value: 100,
                                                        message: t('max-length', {
                                                            ns: 'common/errors',
                                                            length: 100,
                                                        }),
                                                    },
                                                }}
                                                defaultValue={''}
                                                render={({ field: { onChange, value } }) => (
                                                    <Textbox
                                                        value={value}
                                                        allowClear
                                                        onChange={onChange}
                                                        placeholder={t('price-name')}
                                                    />
                                                )}
                                            />

                                            {errors?.prices?.[index]?.name && (
                                                <ErrorMessage style={{ left: 0 }}>
                                                    {errors?.prices?.[index]?.name?.message}
                                                </ErrorMessage>
                                            )}
                                        </div>

                                        <div style={{ position: 'relative' }}>
                                            <Controller
                                                name={`prices.${index}.description`}
                                                control={control}
                                                rules={{
                                                    required: {
                                                        value: false,
                                                        message: t('required-field', {
                                                            ns: 'common/errors',
                                                        }),
                                                    },
                                                    maxLength: {
                                                        value: 200,
                                                        message: t('max-length', {
                                                            ns: 'common/errors',
                                                            length: 200,
                                                        }),
                                                    },
                                                }}
                                                defaultValue={''}
                                                render={({ field: { onChange, value } }) => (
                                                    <DefaultEditor
                                                        containerProps={{ style: { width: '100%' } }}
                                                        value={value as string}
                                                        onChange={(event) => onChange(event.target.value)}
                                                    />
                                                )}
                                            />

                                            {errors?.prices?.[index]?.description && (
                                                <ErrorMessage style={{ left: 0 }}>
                                                    {errors?.prices?.[index]?.description?.message}
                                                </ErrorMessage>
                                            )}
                                        </div>
                                    </ArrayContainer>

                                    <Button mode={'dangerOutlined'} onClick={() => remove(index)}>
                                        {t('delete', { ns: 'common/shared' })}
                                    </Button>
                                </FormRow>
                            );
                        })}
                        <Button
                            mode={'primaryBlue'}
                            onClick={() =>
                                append({
                                    name: '',
                                    description: '',
                                    id: null,
                                    creationDate: new Date(),
                                })
                            }
                        >
                            {t('append', { ns: 'common/shared' })}
                        </Button>
                    </>
                </FormBuilder>
            </FormProvider>
        </>
    );
};

export default Prices;
