import React, { createContext, FC, Key, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { getApiService, getSubscriptionApiService, getStorageService } from 'services';
import { Subscription, Filters } from 'types';
import { downloadFileFromBase64, PaginationOutput } from 'kl-b2c-ui-kit';

interface SubscriptionContext {
    subscription: PaginationOutput<Subscription>;
    getSubscription: (params: Filters) => Promise<void>;
    getExcel: (params: Omit<Filters, 'page' | 'size'>) => Promise<void>;
    deleteSubscription: (ids: Key[]) => Promise<void>;
}

const SubscriptionContext = createContext<SubscriptionContext>({} as SubscriptionContext);

const SubscriptionProvider: FC<PropsWithChildren> = ({ children }) => {
    const storageService = getStorageService();
    const [subscription, setSubscription] = useState<PaginationOutput<Subscription>>({
        count: 0,
        items: null,
    });
    const apiService = getApiService(storageService);
    const subscriptionFormApiService = getSubscriptionApiService(apiService);

    const getSubscription = async (params: Filters) => {
        const subscriptionForm = await subscriptionFormApiService.getItems({
            ...params,
        });
        setSubscription(subscriptionForm.data);
    };

    const deleteSubscription = async (ids: Key[]) => {
        await subscriptionFormApiService.deleteMany(ids);
    };

    const getExcel = async (params: Omit<Filters, 'page' | 'size'>) => {
        const base64 = await subscriptionFormApiService.getExcel({
            ...params,
        });
        downloadFileFromBase64(base64.data, 'xlsx', 'subscription');
    };

    const memoValue = useMemo(
        () => ({
            getSubscription,
            subscription,
            getExcel,
            deleteSubscription,
        }),
        [getSubscription, subscription]
    );

    return <SubscriptionContext.Provider value={memoValue}>{children}</SubscriptionContext.Provider>;
};

export const useSubscription = () => useContext(SubscriptionContext);

export default SubscriptionProvider;
