import { getBaseMultipleContext } from 'contexts';
import { useContext } from 'react';
import { getApiService, getCoursesApiService, getStorageService } from 'services';
import { AddCourse, Course, CourseTable, GetCoursesExcelFilter, GetCoursesFilter, UpdateCourse } from 'types';

const storageService = getStorageService();
const apiService = getApiService(storageService);
const courseApiService = getCoursesApiService(apiService);

const ProviderResult = getBaseMultipleContext<
    GetCoursesFilter,
    GetCoursesExcelFilter,
    CourseTable,
    Course,
    AddCourse,
    UpdateCourse
>(courseApiService, 'courses');

export const useCourses = () => useContext(ProviderResult.Context);
export default ProviderResult.Provider;
