import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ImageListType, ImageType } from 'react-images-uploading';
import { useModal, useNews, useNewsSequence, useToaster } from 'contexts';
import { DomainType, FormBuilderKeys, ModalType } from 'enums';
import { Controller, FieldValues, FormProvider, useForm } from 'react-hook-form';
import { Heading, Loader, Select } from '@kl/components-v6';
import { useTranslation } from 'react-i18next';
import { News, NewsAddOrUpdateType, Tag } from 'types';
import { FormBuilder } from 'containers';
import { FormRow } from './styled';
import { ImagePreview, GalleryUploader } from 'components';
import { ImagePreviews } from 'components/gallery-uploader/styled';
import { ErrorMessage } from 'containers/form-builder/styled';
import { TEN_MB_IN_BYTES } from 'consts/images';
import { getSwaggerError } from 'utils/swagger-errors';

export const INITIAL_DATA: Omit<News, 'creationDate'> = {
    id: '',
    baseDescription: '',
    baseImage: null,
    additionalImage: null,
    name: '',
    displayDate: null,
    isVisible: false,
    isVisibleOnHomePage: false,
    groupRu: {
        id: '',
        name: '',
        creationDate: new Date(),
        linkEn: '',
        linkRu: '',
    },
    groupEn: {
        id: '',
        name: '',
        creationDate: new Date(),
        linkEn: '',
        linkRu: '',
    },
    tags: null,
    photos: [],
};

const SingleNews: FC = () => {
    const { news, newsTags, newsGroups, getNews, updateNews, addNews, addPhoto, deletePhoto } = useNews();
    const { deleteNewsSequence } = useNewsSequence();
    const { setToaster } = useToaster();
    const { setModal } = useModal();
    const { id, groupId, domain } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [outcomePhotos, setOutcomePhotos] = useState<ImageListType>([]);

    const { t } = useTranslation(['pages/news', 'common/shared']);

    const methods = useForm<NewsAddOrUpdateType>();

    const {
        control,
        formState: { errors },
        watch,
    } = methods;

    const onSubmit = async (data: FieldValues) => {
        try {
            setLoading(true);

            if (outcomePhotos.length > 0 && id) {
                setToaster({
                    type: 'info',
                    message: t('add-photo-to-gallery', { ns: 'common/shared' }),
                });

                const promises = outcomePhotos.map((photo: ImageType) => {
                    if (photo.file) {
                        return addPhoto({ photo: photo.file, newsId: id });
                    }
                });

                await Promise.all(promises)
                    .then(() => {
                        setToaster({
                            type: 'success',
                            message: t('photos-added-to-gallery', { ns: 'common/shared' }),
                        });
                        setOutcomePhotos([]);
                    })
                    .catch((e) => console.log(e));
            }

            if (Number(domain as unknown as DomainType) === DomainType.Ru) {
                data = { ...data, groupRuId: groupId };
            } else {
                data = { ...data, groupEnId: groupId };
            }

            if (id) {
                await updateNews({ ...data, id } as NewsAddOrUpdateType);
            } else {
                const { id } = await addNews(data as NewsAddOrUpdateType);
                navigate(`/single-news/${groupId}/${domain}/${id}`);
            }

            setToaster({
                type: 'success',
                message: t('news-updated'),
            });
        } catch (e) {
            const swagger = getSwaggerError(e);
            if (swagger) {
                setToaster({
                    type: 'error',
                    message: swagger,
                });
                return;
            }
            setToaster({
                type: 'error',
                message: t('backend-error', { ns: 'common/shared' }),
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (id) {
            getNews(id);
        }
    }, [id]);

    if (id && !news) {
        return <Loader centered size={'large'} tip={t('loading', { ns: 'common/shared' })} />;
    }

    return (
        <>
            <Heading type={'H2'}>{id ? t('update-news') : t('add-news')}</Heading>
            <FormProvider {...methods}>
                <FormBuilder<Omit<News, 'id' | 'creationDate' | 'groupRu' | 'groupEn' | 'tags'>>
                    data={news || INITIAL_DATA}
                    submit={onSubmit}
                    cancel={() => navigate('/news/all')}
                    onDelete={async () => {
                        if (id) {
                            setModal(ModalType.Confirm, <div>{t('delete-confirm')}</div>, async () => {
                                setLoading(true);

                                await deleteNewsSequence([id]);

                                setLoading(false);
                                navigate('/news/news-groups');
                            });
                        }
                    }}
                    formKey={FormBuilderKeys.News}
                    isFormEmpty={!id}
                    loading={loading}
                >
                    <>
                        <FormRow>
                            {domain && (
                                <>
                                    {Number(domain as unknown as DomainType) === DomainType.Ru ? (
                                        <span>{t('groupRuId', { ns: 'common/shared' })}</span>
                                    ) : (
                                        <span>{t('groupEnId', { ns: 'common/shared' })}</span>
                                    )}
                                    <span>{newsGroups?.filter((group) => group.id === groupId)[0]?.name}</span>
                                </>
                            )}
                        </FormRow>
                        <FormRow>
                            <span>{t('tags', { ns: 'common/shared' })}*</span>
                            <Controller
                                name={'tags'}
                                rules={{ required: true }}
                                control={control}
                                defaultValue={news?.tags?.map((tag) => tag.id) || []}
                                render={({ field: { onChange } }) => (
                                    <Select
                                        mode={'multiple'}
                                        showSearch
                                        onChange={onChange}
                                        defaultValue={news?.tags?.map((tag) => tag.id)}
                                        options={newsTags?.map((option: Tag) => ({
                                            label: option.name,
                                            value: option.id,
                                            disabled: watch('tags').length >= 5 && !watch('tags').includes(option.id),
                                        }))}
                                    />
                                )}
                            />
                            {errors?.tags && (
                                <ErrorMessage>{t('required-field', { ns: 'common/errors' })}</ErrorMessage>
                            )}
                        </FormRow>
                        {news?.photos && (
                            <FormRow>
                                <span>{t('photos', { ns: 'common/shared' })}</span>

                                <div>
                                    <ImagePreviews>
                                        {news.photos.map((photo) => (
                                            <ImagePreview
                                                key={photo.id}
                                                clear={async () => {
                                                    if (
                                                        window.confirm(
                                                            `${t('delete-confirm', { ns: 'common/shared' })}`
                                                        )
                                                    ) {
                                                        await deletePhoto(photo.id);
                                                        setToaster({
                                                            type: 'warning',
                                                            message: t('gallery-photo-deleted', {
                                                                ns: 'common/shared',
                                                            }),
                                                        });
                                                    }
                                                }}
                                                size={150}
                                                image={photo.image}
                                            />
                                        ))}
                                    </ImagePreviews>

                                    {id && (
                                        <Controller
                                            name={'photos'}
                                            control={control}
                                            render={({ field: { onChange } }) => (
                                                <GalleryUploader
                                                    photos={outcomePhotos}
                                                    onChange={(imageList) => {
                                                        setOutcomePhotos(imageList);
                                                        onChange(imageList.map((image) => image.file));
                                                    }}
                                                />
                                            )}
                                        />
                                    )}
                                </div>
                            </FormRow>
                        )}
                    </>
                </FormBuilder>
            </FormProvider>
        </>
    );
};

export default SingleNews;
