import styled from 'styled-components';

export const LabelContainer = styled.div`
    display: flex;
    flex-direction: column;

    span {
        font-size: 12px;
    }
`;

export const DeleteContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
`;
