import React, { Key, ReactElement, ReactNode, useEffect, useState } from 'react';
import { Button, Heading, Loader, Pagination, Table, Space } from '@kl/components-v6';
import { useTranslation } from 'react-i18next';
import { PageBuilderFilterType } from 'types';
import { PaginationOutput } from 'kl-b2c-ui-kit';
import { Filters } from './components';
import { getFilterMapper } from './mappers';
import { FilterType, ModalType, PageBuilderAdditionalFilters, PageBuilderKey } from 'enums';
import { useModal, useToaster } from 'contexts';
import { useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';

interface PageBuilderProps<TData extends { id: string }> {
    data: PaginationOutput<TData>;
    getItems: <TFilterParams>(params: TFilterParams) => Promise<void>;
    getExcel?: <TFilterParams>(params: TFilterParams) => Promise<void>;
    deleteItems?: (ids: Key[]) => Promise<void>;
    canDelete?: (data: TData) => boolean;
    pageKey: PageBuilderKey;
    columns: { key: string; dataIndex: string; title: string }[];
    additionalFilters?: { type: PageBuilderAdditionalFilters; key: FilterType; defaultValue?: number | string }[];
    addItem?: () => void;
    getItem?: (id: string) => Promise<void>;
    updateItem?: (record: TData) => void;
    additionalButtons?: {
        title: string;
        dataIndex: string;
        key: string;
        width: string;
        render: (_: unknown, record: TData) => JSX.Element;
    }[];
    noDefaultFilters?: boolean;
    showItem?: (record: TData) => void;
    noFilters?: boolean;
}

const PageBuilder = <TData extends { id: string }>(props: PageBuilderProps<TData>) => {
    const {
        data,
        getItems,
        getItem,
        deleteItems,
        getExcel,
        pageKey,
        additionalFilters,
        columns,
        addItem,
        updateItem,
        canDelete,
        additionalButtons = [],
        noDefaultFilters,
        showItem,
        noFilters,
    } = props;
    const [tableLoading, setTableLoading] = useState<boolean>(false);
    const [filters, setFilters] = useState<PageBuilderFilterType>(getFilterMapper(pageKey));
    const [deleteCandidates, setDeleteCandidates] = useState<Key[]>([]);
    const { setModal } = useModal();
    const { setToaster } = useToaster();
    const { t } = useTranslation(['pages/page-builder', 'common/shared', 'common/delete-notifications']);
    const { id } = useParams();

    const setFilter = (newFilter: Record<string, string | number | undefined>) =>
        setFilters({
            ...filters,
            ...newFilter,
        });

    const downloadExcel = async () => {
        if (getExcel) await getExcel(filters);
        setToaster({
            type: 'success',
            message: t('xls-downloaded'),
        });
    };

    const getAdditionalButtons = (
        length: number
    ): { title: string; dataIndex: string; key: string; width: string }[] => {
        if (length < 1) {
            return [];
        }

        let buttons: {
            title: string;
            dataIndex: string;
            key: string;
            width: string;
            render: (_: unknown, record: TData) => ReactNode;
        }[] = [];

        if (deleteItems) {
            buttons = [
                {
                    title: '',
                    dataIndex: 'delete',
                    key: 'delete',
                    width: '5%',
                    render: (_: unknown, record: TData) =>
                        !canDelete || canDelete(record) ? (
                            <Button
                                mode="dangerFilled"
                                onClick={async () => {
                                    const modalContent = (
                                        <div>
                                            {deleteCandidates.length >= 1
                                                ? t('deleteMany', {
                                                      message: `${deleteCandidates.length.toString()} ${t(
                                                          `${pageKey}_many`,
                                                          {
                                                              ns: 'common/delete-notifications',
                                                          }
                                                      )}`,
                                                  })
                                                : t('deleteOne', {
                                                      type: t(pageKey, {
                                                          ns: 'common/delete-notifications',
                                                      }),
                                                  })}
                                        </div>
                                    );

                                    setModal(ModalType.Confirm, modalContent, async () => {
                                        setTableLoading(true);

                                        try {
                                            const { id } = record;
                                            const hasDeleteCandidate = deleteCandidates.some((key) => key === id);
                                            if (deleteItems) {
                                                await deleteItems(
                                                    hasDeleteCandidate ? deleteCandidates : [...deleteCandidates, id]
                                                );
                                            }
                                            await getItems({ ...filters });
                                            setDeleteCandidates([]);

                                            setToaster({
                                                type: 'warning',
                                                message: t('items-deleted'),
                                            });
                                        } catch (e) {
                                            const res = e as {
                                                response?: {
                                                    status?: number;
                                                    data?: string;
                                                };
                                            };
                                            if (
                                                res?.response &&
                                                res?.response?.status &&
                                                res?.response?.status === 409 &&
                                                res?.response?.data
                                            ) {
                                                setToaster({
                                                    type: 'error',
                                                    message: res?.response?.data ?? '',
                                                });
                                                return;
                                            }
                                            setToaster({
                                                type: 'error',
                                                message: t('network-problem'),
                                            });
                                        } finally {
                                            setTableLoading(false);
                                        }
                                    });
                                }}
                            >
                                {t('delete', { ns: 'common/shared' })}
                            </Button>
                        ) : (
                            <></>
                        ),
                },
            ];
        }

        if (updateItem) {
            buttons = [
                {
                    title: '',
                    dataIndex: 'update',
                    key: 'update',
                    width: '5%',
                    render: (_: unknown, record: TData) => (
                        <Button mode={'primaryBlue'} onClick={() => updateItem(record)}>
                            {t('update', { ns: 'common/shared' })}
                        </Button>
                    ),
                },
                ...buttons,
            ];
        }

        if (showItem) {
            buttons = [
                {
                    title: '',
                    dataIndex: 'show',
                    key: 'show',
                    width: '5%',
                    render: (_: unknown, record: TData) => {
                        const button = (
                            <Button mode={'secondary'} onClick={() => showItem(record)}>
                                {t('show', { ns: 'common/shared' })}
                            </Button>
                        );
                        return button;
                    },
                },
                ...buttons,
            ];
        }

        return [...additionalButtons, ...buttons];
    };

    useEffect(() => {
        setTableLoading(true);
        if (id) {
            if (!getItem) {
                throw new Error('Provide function for single item request!');
            }

            setTableLoading(true);
            getItem(id)
                .catch((e) => {
                    setToaster({
                        type: 'error',
                        message: e.message,
                    });
                })
                .finally(() => setTableLoading(false));
        } else {
            getItems(filters)
                .catch((e) => {
                    // TODO: Show toaster here.
                    throw new Error(e);
                })
                .finally(() => setTableLoading(false));
        }
    }, [filters]);

    return data?.items !== null ? (
        <>
            <Heading style={{ marginBottom: 30 }} type={'H2'}>
                {t(pageKey)}
            </Heading>

            {addItem && (
                <Space direction="horizontal" size={15} style={{ marginBottom: 30 }}>
                    <Button mode={'danger'} onClick={addItem}>
                        {t('add')}
                    </Button>
                </Space>
            )}

            {!id && !noFilters && (
                <Filters
                    noDefaultFilters={noDefaultFilters}
                    setFilter={setFilter}
                    downloadExcel={getExcel ? downloadExcel : undefined}
                    disableExcelDownload={!data?.items.length}
                    additionalFilters={additionalFilters}
                />
            )}

            <Table
                scroll={{ scrollToFirstRowOnChange: true, x: true }}
                rowSelection={{
                    type: 'checkbox',
                    onChange: async (deleteCandidates: Key[]) => setDeleteCandidates(deleteCandidates),
                }}
                loading={tableLoading}
                style={{ marginBottom: 20 }}
                locale={{ emptyText: t('emptyTable', { title: pageKey.toLowerCase() }) }}
                toolbar={{ showFilter: true }}
                pagination={false}
                dataSource={
                    data?.items.map((item: TData) => ({
                        ...item,
                        key: item.id,
                    })) || []
                }
                columns={[...columns, ...getAdditionalButtons(data?.items.length)]}
            />

            <Pagination
                onChange={(page, size) => {
                    setFilters((prevState: PageBuilderFilterType) => ({ ...prevState, page: page - 1, size }));
                }}
                onShowSizeChange={(page, size) => {
                    setFilters((prevState: PageBuilderFilterType) => ({
                        ...prevState,
                        page: page === 0 ? 0 : page - 1,
                        size,
                    }));
                }}
                total={data?.count || 0}
                current={filters.page + 1}
                pageSize={filters.size}
            />
        </>
    ) : (
        <Loader centered size={'large'} tip={t('loading', { ns: 'common/shared' })} />
    );
};

export default PageBuilder;
