import { ControlRendererType } from 'enums';
import { RegisterOptions } from 'react-hook-form';
import { CourseApplication } from 'types';

export const courseApplicationRendererType = (
    t: (key: string, options?: Record<string, number | string>) => string
): Record<keyof CourseApplication, { type: ControlRendererType; rules?: RegisterOptions; readonly?: true }> => ({
    id: {
        type: ControlRendererType.Text,
    },
    creationDate: {
        type: ControlRendererType.DateTime,
    },
    firstName: {
        type: ControlRendererType.Text,
    },
    lastName: {
        type: ControlRendererType.Text,
    },
    position: {
        type: ControlRendererType.Text,
    },
    comment: {
        type: ControlRendererType.Text,
    },
    company: {
        type: ControlRendererType.Text,
    },
    country: {
        type: ControlRendererType.Text,
    },
    countOfPeople: {
        type: ControlRendererType.Text,
    },
    courseId: {
        type: ControlRendererType.CourseId,
    },
    courseName: {
        type: ControlRendererType.Text,
    },
    professionId: {
        type: ControlRendererType.ProfessionId,
    },
    professionName: {
        type: ControlRendererType.Text,
    },
    email: {
        type: ControlRendererType.Text,
    },
    phone: {
        type: ControlRendererType.Text,
    },
    domainType: {
        type: ControlRendererType.DomainType,
        readonly: true,
    },
    formType: {
        type: ControlRendererType.CourseFormType,
        readonly: true,
    },
    university: {
        type: ControlRendererType.Text,
    },
    studyCourse: {
        type: ControlRendererType.StudyCourse,
        readonly: true,
    },
    studyForm: {
        type: ControlRendererType.EventType,
        readonly: true,
    },
    studyLevel: {
        type: ControlRendererType.StudyLevel,
        readonly: true,
    },
    utmCampaign: {
        type: ControlRendererType.Text,
    },
    utmContent: {
        type: ControlRendererType.Text,
    },
    utmMedium: {
        type: ControlRendererType.Text,
    },
    utmSource: {
        type: ControlRendererType.Text,
    },
    utmTerm: {
        type: ControlRendererType.Text,
    },
    marketingIsSelected: {
        type: ControlRendererType.CheckBox,
        readonly: true,
    },
    agreementId: {
        type: ControlRendererType.AgreementId,
    },
});
