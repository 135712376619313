import React, { FC, PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { getBaseSingularContext } from 'contexts';
import { getApiService, getCoursesApiService, getSpeakersApiService, getStorageService } from 'services';
import {
    AddCourse,
    Course,
    CourseTable,
    GetCoursesExcelFilter,
    GetCoursesFilter,
    Speaker,
    UpdateCourse,
    UpdateCoursePrices,
    UpdateCourseResults,
    UpdateCourseTargetAudiences,
    UpdateCourseTeasers,
} from 'types';

const storageService = getStorageService();
const apiService = getApiService(storageService);
const courseApiService = getCoursesApiService(apiService);
const speakersApiService = getSpeakersApiService(apiService);

const BaseProvider = getBaseSingularContext<
    GetCoursesFilter,
    GetCoursesExcelFilter,
    CourseTable,
    Course,
    AddCourse,
    UpdateCourse
>(courseApiService);

const useCourseBase = () => useContext(BaseProvider.Context);

interface ICourseContext {
    updateTeasers: (data: UpdateCourseTeasers) => Promise<Course>;
    updateTargetAudiences: (data: UpdateCourseTargetAudiences) => Promise<Course>;
    updatePrices: (data: UpdateCoursePrices) => Promise<Course>;
    updateResults: (data: UpdateCourseResults) => Promise<Course>;
    speakers: Speaker[];
}

const CourseContext = createContext<ICourseContext>({} as ICourseContext);

const CourseProvider: FC<PropsWithChildren> = ({ children }) => {
    const updateTeasers = async (data: UpdateCourseTeasers) => {
        const result = await courseApiService.updateTeasers(data);
        return result.data;
    };
    const updateTargetAudiences = async (data: UpdateCourseTargetAudiences) => {
        const result = await courseApiService.updateTargetAudiences(data);
        return result.data;
    };
    const updatePrices = async (data: UpdateCoursePrices) => {
        const result = await courseApiService.updatePrices(data);
        return result.data;
    };
    const updateResults = async (data: UpdateCourseResults) => {
        const result = await courseApiService.updateResults(data);
        return result.data;
    };

    const [speakers, setSpeakers] = useState<Speaker[]>([]);

    useEffect(() => {
        const speakersCall = speakersApiService.getItems({
            page: 0,
            size: 300,
            domainType: null,
            isShowAboutPage: null,
            isShowCorporatePage: null,
            isShowGovernmentPage: null,
            isShowHomePage: null,
        });

        Promise.all([speakersCall])
            .then(([speakerResult]) => {
                setSpeakers(speakerResult?.data?.items ?? []);
            })
            .catch((e) => {
                throw new Error(e);
            });
    }, []);

    const memoValue = useMemo(
        () => ({
            updateTeasers,
            updateTargetAudiences,
            updateResults,
            updatePrices,
            speakers,
        }),
        [updateTeasers, updateTargetAudiences, updateResults, speakers]
    );

    return (
        <BaseProvider.Provider>
            <CourseContext.Provider value={memoValue}>{children}</CourseContext.Provider>
        </BaseProvider.Provider>
    );
};

export const useCourse = () => ({ ...useContext(CourseContext), ...useCourseBase() });
export default CourseProvider;
