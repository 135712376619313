import { IApiService, ITagsApi } from 'interfaces';
import { HttpResponse, Tag } from 'types';
import { ApiBaseKeys } from 'enums/api-base-keys';

class NewTagsApi implements ITagsApi {
    protected readonly _basePath: ApiBaseKeys;

    constructor(private readonly apiService: IApiService, private readonly path: ApiBaseKeys) {
        this._basePath = path;
    }

    public getNewsTags(params: { page: number; size: number }): Promise<HttpResponse<{ count: number; items: Tag[] }>> {
        return this.apiService.request<{ count: number; items: Tag[] }>({
            method: 'GET',
            url: `/${this.basePath}/getItems`,
            params,
        });
    }

    get basePath() {
        return this._basePath;
    }
}

export default NewTagsApi;
