import React, { createContext, FC, Key, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { getAgreementsApiService, getApiService, getStorageService } from 'services';
import { Agreement, GetAgreementsFilters } from 'types';
import { downloadFileFromBase64, PaginationOutput } from 'kl-b2c-ui-kit';
import { AgreementsChapter } from 'enums/agreements-chapter';
import { AgreementSelected } from 'enums/agreement-selected';
import { dropdownToBoolean } from 'contexts/mappers';

interface AgreementsContext {
    agreements: PaginationOutput<Agreement>;
    getAgreements: (params: GetAgreementsFilters) => Promise<void>;
    getAgreement: (id: string) => Promise<void>;
    getExcel: (params: Omit<GetAgreementsFilters, 'page' | 'size'>) => Promise<void>;
    deleteAgreements: (ids: Key[]) => Promise<void>;
}

const AgreementsContext = createContext<AgreementsContext>({} as AgreementsContext);

const AgreementsProvider: FC<PropsWithChildren> = ({ children }) => {
    const storageService = getStorageService();
    const [agreements, setAgreements] = useState<PaginationOutput<Agreement>>({
        count: 0,
        items: null,
    });
    const apiService = getApiService(storageService);
    const agreementsApiService = getAgreementsApiService(apiService);

    const getAgreements = async (params: GetAgreementsFilters) => {
        const agreements = await agreementsApiService.getItems({
            ...params,
            chapter: params.chapter !== AgreementsChapter.All ? params.chapter : null,
            marketingIsSelected: dropdownToBoolean(params.marketingIsSelected),
            privacyPolicyIsSelected: dropdownToBoolean(params.privacyPolicyIsSelected),
        });
        const mappedAgreements = agreements.data.items?.map((agreement: Agreement) => {
            return {
                ...agreement,
            };
        });

        // @ts-ignore
        setAgreements({ ...agreements.data, items: mappedAgreements });
    };

    const getAgreement = async (id: string) => {
        const agreement = await agreementsApiService.getItem(id);
        setAgreements({ count: 1, items: [agreement.data] });
    };

    const deleteAgreements = async (ids: Key[]) => {
        await agreementsApiService.deleteMany(ids);
    };

    const getExcel = async (params: Omit<GetAgreementsFilters, 'page' | 'size'>) => {
        const base64 = await agreementsApiService.getExcel({
            ...params,
            chapter: params.chapter !== AgreementsChapter.All ? params.chapter : null,
            marketingIsSelected: dropdownToBoolean(params.marketingIsSelected),
            privacyPolicyIsSelected: dropdownToBoolean(params.privacyPolicyIsSelected),
        });
        downloadFileFromBase64(base64.data, 'xlsx', 'agreements');
    };

    const memoValue = useMemo(
        () => ({
            getAgreements,
            agreements,
            getExcel,
            deleteAgreements,
            getAgreement,
        }),
        [getAgreements, agreements, getAgreement]
    );

    return <AgreementsContext.Provider value={memoValue}>{children}</AgreementsContext.Provider>;
};

export const useAgreements = () => useContext(AgreementsContext);

export default AgreementsProvider;
