export enum DurationType {
    ShortTerm = 1,
    LongTerm = 2,
}

export enum CourseLevel {
    Basic = 1,
    Advanced = 2,
    Expert = 3,
    ForEveryOne = 5,
}

export enum CourseFormType {
    All = 0,
    Individual = 1,
    Student = 2,
    Company = 3,
    Teacher = 4,
    XTraining = 5,
}

export enum CourseSkill {
    Technical = 1,
    KnowledgeCyberSecurity = 2,
    CommunicatesManagers = 3,
}

export enum CourseForWhom {
    ForMySelf = 1,
    ForEmployers = 2,
    ForStudents = 3,
}

export enum CourseDirection {
    AdvancedCybersecurity = 1,
    ForItProfessionals = 2,
    ManagementAndCommunications = 3,
    SalesAndMarketing = 4,
    ForTopManagers = 5,
    ForStudents = 6,
    ForEmployeesAwareness = 7,
    FreeCourse = 8,
}

export enum ConditionCategory {
    Knowledge = 1,
    Skill = 2,
}
