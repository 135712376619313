import React, { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEvent, useToaster } from 'contexts';
import { FormBuilderKeys } from 'enums';
import { Controller, FieldValues, FormProvider, useForm, useFieldArray } from 'react-hook-form';
import { Loader, Button, Heading, Textbox } from '@kl/components-v6';
import { useTranslation } from 'react-i18next';
import { EventParticle, UpdateEventParticle } from 'types';
import { FormBuilder } from 'containers';
import { FormRow } from 'containers/news/news/styled';
import { DefaultEditor } from 'react-simple-wysiwyg';
import { ErrorMessage } from 'containers/form-builder/styled';
import { ArrayContainer } from './styled';
import moment from 'moment';
import { DATE_FORMAT_WITH_TIME } from 'consts/date';
import { EmptyList } from 'components/empty-list';

type FormValues = {
    targetAudiences: Omit<EventParticle, 'id'>[];
};

const TargetAudiences: FC = () => {
    const { event, updateParticle } = useEvent();
    const { setToaster } = useToaster();
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);

    const { t } = useTranslation(['pages/events', 'common/shared']);

    const methods = useForm<FormValues>({ defaultValues: { targetAudiences: event?.targetAudiences || [] } });

    const {
        control,
        formState: { errors },
    } = methods;

    const { fields, append, remove } = useFieldArray<FormValues, 'targetAudiences'>({
        control,
        name: 'targetAudiences',
    });

    const onSubmit = async (data: FieldValues) => {
        setLoading(true);

        if (id) {
            await updateParticle<UpdateEventParticle<EventParticle>>(
                {
                    eventId: id,
                    items: data.targetAudiences,
                },
                'updateTargetAudiences'
            );
            setToaster({
                type: 'success',
                message: t('update-audiences-success'),
            });
        }

        setLoading(false);
    };

    if (id && !event?.targetAudiences) {
        return <Loader centered size={'large'} tip={t('loading', { ns: 'common/shared' })} />;
    }

    return (
        <>
            <Heading type={'H2'}>{t('update-audiences')}</Heading>
            <FormProvider {...methods}>
                <FormBuilder<FormValues>
                    data={{} as FormValues}
                    submit={onSubmit}
                    cancel={() => navigate('/events/all')}
                    formKey={FormBuilderKeys.EventGeneral}
                    isFormEmpty={!id}
                    loading={loading}
                >
                    <>
                        {!fields.length && <EmptyList />}
                        {fields.map((field, index) => {
                            return (
                                <FormRow key={field.id} style={{ margin: '0 0 45px 0' }}>
                                    <span>
                                        {`Target audience ${index + 1}`}
                                        <br />
                                        {
                                            //@ts-ignore
                                            field.creationDate &&
                                                moment(field?.creationDate).format(DATE_FORMAT_WITH_TIME)
                                        }
                                    </span>

                                    <ArrayContainer>
                                        <div style={{ position: 'relative' }}>
                                            <Controller
                                                name={`targetAudiences.${index}.name`}
                                                control={control}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: t('required-field', { ns: 'common/errors' }),
                                                    },
                                                    maxLength: {
                                                        value: 100,
                                                        message: t('max-length', {
                                                            ns: 'common/errors',
                                                            length: 100,
                                                        }),
                                                    },
                                                }}
                                                defaultValue={''}
                                                render={({ field: { onChange, value } }) => (
                                                    <Textbox
                                                        value={value}
                                                        allowClear
                                                        onChange={onChange}
                                                        placeholder={t('audience-name')}
                                                    />
                                                )}
                                            />

                                            {errors?.targetAudiences?.[index]?.name && (
                                                <ErrorMessage style={{ left: 0 }}>
                                                    {errors?.targetAudiences?.[index]?.name?.message}
                                                </ErrorMessage>
                                            )}
                                        </div>

                                        <div style={{ position: 'relative' }}>
                                            <Controller
                                                name={`targetAudiences.${index}.description`}
                                                control={control}
                                                rules={{
                                                    required: false,
                                                    maxLength: {
                                                        value: 200,
                                                        message: t('max-length', {
                                                            ns: 'common/errors',
                                                            length: 200,
                                                        }),
                                                    },
                                                }}
                                                defaultValue={''}
                                                render={({ field: { onChange, value } }) => (
                                                    <DefaultEditor
                                                        containerProps={{ style: { width: '100%' } }}
                                                        value={value as string}
                                                        onChange={(event) => onChange(event.target.value)}
                                                    />
                                                )}
                                            />

                                            {errors?.targetAudiences?.[index]?.description && (
                                                <ErrorMessage style={{ left: 0 }}>
                                                    {errors?.targetAudiences?.[index]?.description?.message}
                                                </ErrorMessage>
                                            )}
                                        </div>
                                        <div style={{ position: 'relative' }}>
                                            <Controller
                                                name={`targetAudiences.${index}.number`}
                                                control={control}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: t('required-field', { ns: 'common/errors' }),
                                                    },
                                                    min: {
                                                        value: 1,
                                                        message: t('min-value', {
                                                            ns: 'common/errors',
                                                            value: 1,
                                                        }),
                                                    },
                                                }}
                                                defaultValue={1}
                                                render={({ field: { onChange, value } }) => (
                                                    <Textbox
                                                        type={'number'}
                                                        value={value as unknown as string}
                                                        allowClear
                                                        onChange={(val) => {
                                                            if (!val) {
                                                                onChange(val);
                                                                return;
                                                            }
                                                            const newVal = `${val}`.replace('.', '').replace(',', '');
                                                            onChange(newVal);
                                                        }}
                                                        placeholder={t('prize-fund-number')}
                                                    />
                                                )}
                                            />

                                            {errors?.targetAudiences?.[index]?.number && (
                                                <ErrorMessage style={{ left: 0 }}>
                                                    {errors?.targetAudiences?.[index]?.number?.message}
                                                </ErrorMessage>
                                            )}
                                        </div>
                                    </ArrayContainer>

                                    <Button mode={'dangerOutlined'} onClick={() => remove(index)}>
                                        {t('delete', { ns: 'common/shared' })}
                                    </Button>
                                </FormRow>
                            );
                        })}
                        <Button
                            mode={'primaryBlue'}
                            onClick={() =>
                                append({
                                    name: '',
                                    description: '',
                                    number: fields.length + 1,
                                    creationDate: new Date(),
                                })
                            }
                        >
                            {t('append', { ns: 'common/shared' })}
                        </Button>
                    </>
                </FormBuilder>
            </FormProvider>
        </>
    );
};

export default TargetAudiences;
