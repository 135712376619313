import React, { FC, PropsWithChildren } from 'react';
import { Button } from '@kl/components-v6';
import { UserInfoContainer } from './styled';
import { useAuth } from 'contexts/auth.context';
import { SignOut } from '@kl/icons/16';

interface SiderUserInfoItemProps {
    collapsed: boolean;
}

const SiderUserInfo: FC<PropsWithChildren<SiderUserInfoItemProps>> = ({ collapsed }) => {
    const { userInfo, signOut } = useAuth();

    if (!userInfo) return null;

    return (
        <UserInfoContainer collapsed={collapsed}>
            <Button
                onClick={signOut}
                size={'large'}
                iconAfter={<SignOut key={'signOutIcon'} />}
                mode="primaryBlack"
                text={userInfo.fullName}
            />
        </UserInfoContainer>
    );
};

export default SiderUserInfo;
