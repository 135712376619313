import React, { createContext, FC, Key, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { getApiService, getCourseApplicationsApiService, getStorageService } from 'services';
import { CourseApplication, GetCourseApplications, GetCourseApplicationsFilter } from 'types';
import { downloadFileFromBase64, PaginationOutput } from 'kl-b2c-ui-kit';
import { dropdownToBoolean } from 'contexts/mappers';

interface CourseApplicationsContext {
    courseApplications: PaginationOutput<CourseApplication>;
    getCourseApplications: (params: GetCourseApplicationsFilter) => Promise<void>;
    getCourseApplication: (id: string) => Promise<void>;
    getExcel: (params: Omit<GetCourseApplicationsFilter, 'page' | 'size'>) => Promise<void>;
    deleteCourseApplications: (ids: Key[]) => Promise<void>;
}

const CourseApplicationsContext = createContext<CourseApplicationsContext>({} as CourseApplicationsContext);

const CourseApplicationsProvider: FC<PropsWithChildren> = ({ children }) => {
    const storageService = getStorageService();
    const [courseApplications, setCourseApplications] = useState<PaginationOutput<CourseApplication>>({
        count: 0,
        items: null,
    });
    const apiService = getApiService(storageService);
    const courseApplicationsApiService = getCourseApplicationsApiService(apiService);

    const getCourseApplications = async (params: GetCourseApplicationsFilter) => {
        const courseApplications = await courseApplicationsApiService.getItems({
            ...params,
            marketingIsSelected: dropdownToBoolean(params.marketingIsSelected),
        });
        setCourseApplications(courseApplications.data);
    };

    const getCourseApplication = async (id: string) => {
        const course = await courseApplicationsApiService.getItem(id);
        setCourseApplications({ count: 1, items: [course.data] });
    };

    const deleteCourseApplications = async (ids: Key[]) => {
        await courseApplicationsApiService.deleteMany(ids);
    };

    const getExcel = async (params: Omit<GetCourseApplicationsFilter, 'page' | 'size'>) => {
        const base64 = await courseApplicationsApiService.getExcel({
            ...params,
            marketingIsSelected: dropdownToBoolean(params.marketingIsSelected),
        });
        downloadFileFromBase64(base64.data, 'xlsx', 'CourseApplications');
    };

    const memoValue = useMemo(
        () => ({
            getCourseApplications,
            courseApplications,
            getExcel,
            deleteCourseApplications,
            getCourseApplication,
        }),
        [getCourseApplications, courseApplications, getCourseApplication]
    );

    return <CourseApplicationsContext.Provider value={memoValue}>{children}</CourseApplicationsContext.Provider>;
};

export const useCourseApplications = () => useContext(CourseApplicationsContext);

export default CourseApplicationsProvider;
