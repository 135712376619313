import { getStorageService } from 'services/api.factory';
import { AppSettings } from 'types/core';
import { LS_KEYS } from 'consts/storage-keys';
import { getSignInUrl, getSignOutUrl } from 'kl-b2c-ui-kit';
import { useMemo } from 'react';

export const useAuthUrls = () => {
    const storageService = getStorageService();

    const { adfsClientId, adfsAuthorizeBasePath, adfsLogoutBasePath } = storageService.getItem<AppSettings>(
        LS_KEYS['appSettings']
    );

    const token = storageService.getItem(LS_KEYS['authToken']);

    const redirectToADFSSignIn = () => {
        const signInUrl = getSignInUrl({
            adfsClientId,
            adfsAuthorizeBasePath,
        });

        window.location.assign(signInUrl);
        return;
    };

    const redirectToADFSSignOut = () => {
        const signOutUrl = getSignOutUrl({
            userKey: token as string,
            adfsLogoutBasePath,
        });

        window.location.assign(signOutUrl);
        return;
    };

    return useMemo(
        () => ({
            redirectToADFSSignIn,
            redirectToADFSSignOut,
        }),
        [redirectToADFSSignIn, redirectToADFSSignOut]
    );
};
