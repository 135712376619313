import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { IApiService, IStorageService } from 'interfaces';
import { LS_KEYS } from 'consts';
import { AppSettings } from 'types/core';
import { getSignOutUrl } from 'kl-b2c-ui-kit';
import { HttpResponse } from 'types';

class ApiService implements IApiService {
    private readonly axios: AxiosInstance;

    constructor(private storageService: IStorageService) {
        const { apiAddress } = storageService.getItem<AppSettings>(LS_KEYS['appSettings']) || {};
        this.axios = axios.create({
            baseURL: apiAddress,
        });
        // intercept requests and add token
        this.axios.interceptors.request.use((config: AxiosRequestConfig) => {
            const token = storageService.getItem(LS_KEYS['authToken']);
            if (token) {
                config.headers!.Authorization = `Bearer ${token}`;
            }

            return config;
        });

        // intercept 401 and 403 response types
        this.axios.interceptors.response.use(
            (response: AxiosResponse) => {
                return response;
            },
            (error: AxiosError) => {
                if (error.response?.status === 401 || error.response?.status === 403) {
                    const token = storageService.getItem(LS_KEYS['authToken']);
                    const { adfsLogoutBasePath } = storageService.getItem<AppSettings>(LS_KEYS['appSettings']);
                    const signOutUrl = getSignOutUrl({
                        userKey: token as string,
                        adfsLogoutBasePath,
                    });

                    if (token) {
                        storageService.clearItem(LS_KEYS['authToken']);
                        window.location.assign(signOutUrl);
                        return;
                    }
                } else {
                    return Promise.reject(error);
                }
            }
        );
    }

    public async request<T>(requestConfig: AxiosRequestConfig): Promise<HttpResponse<T>> {
        const response: AxiosResponse = await this.axios.request<T>(requestConfig);
        return {
            data: response?.data,
            status: response?.status ?? 0,
        };
    }
}

export default ApiService;
