import React, { createContext, useContext, FC, PropsWithChildren } from 'react';
import { getApiService, getStorageService } from 'services';

const api = getApiService(getStorageService());
const ApiContext = createContext<typeof api>(api);

const ApiProvider: FC<PropsWithChildren> = ({ children }) => {
    return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
};

export const useApi = () => useContext(ApiContext);

export default ApiProvider;
