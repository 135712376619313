import React, { createContext, FC, Key, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { getApiService, getReviewsApiService, getStorageService } from 'services';
import { Review, Reviews } from 'types';
import { downloadFileFromBase64, PaginationOutput } from 'kl-b2c-ui-kit';
import { dropdownToBoolean } from '../mappers';

interface ReviewsContext {
    reviews: PaginationOutput<Review>;
    getReviews: (params: Reviews) => Promise<void>;
    getExcel: (params: Omit<Reviews, 'page' | 'size'>) => Promise<void>;
    deleteReviews: (ids: Key[]) => Promise<void>;
}

const ReviewsContext = createContext<ReviewsContext>({} as ReviewsContext);

const ReviewsProvider: FC<PropsWithChildren> = ({ children }) => {
    const storageService = getStorageService();
    const [reviews, setReviews] = useState<PaginationOutput<Review>>({
        count: 0,
        items: null,
    });
    const apiService = getApiService(storageService);
    const reviewsApiService = getReviewsApiService(apiService);

    const getReviews = async (params: Reviews) => {
        const reviews = await reviewsApiService.getItems({
            ...params,
            domain: params.domain ? params.domain : null,
            isVisibleCorporateBusinessPage: dropdownToBoolean(params.isVisibleCorporateBusinessPage),
            isVisibleGovernmentPage: dropdownToBoolean(params.isVisibleGovernmentPage),
            isVisibleHomePage: dropdownToBoolean(params.isVisibleHomePage),
        });
        setReviews(reviews.data);
    };

    const deleteReviews = async (ids: Key[]) => {
        await reviewsApiService.deleteMany(ids);
    };

    const getExcel = async (params: Omit<Reviews, 'page' | 'size'>) => {
        const base64 = await reviewsApiService.getExcel({
            ...params,
            domain: params.domain ? params.domain : null,
            isVisibleCorporateBusinessPage: dropdownToBoolean(params.isVisibleCorporateBusinessPage),
            isVisibleGovernmentPage: dropdownToBoolean(params.isVisibleGovernmentPage),
            isVisibleHomePage: dropdownToBoolean(params.isVisibleHomePage),
        });
        downloadFileFromBase64(base64.data, 'xlsx', 'eventReviews');
    };

    const memoValue = useMemo(
        () => ({
            getReviews,
            reviews,
            getExcel,
            deleteReviews,
        }),
        [getReviews, reviews]
    );

    return <ReviewsContext.Provider value={memoValue}>{children}</ReviewsContext.Provider>;
};

export const useReviews = () => useContext(ReviewsContext);

export default ReviewsProvider;
