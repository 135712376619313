import React, { FC } from 'react';
import { useCooperation } from 'contexts';
import { cellRenderMapper, initialColumns } from './mappers';
import { Cooperation, GetCooperation } from 'types';
import { renderCell, getTableColumns } from 'utils';
import { PageBuilder } from 'containers';
import { FilterType, PageBuilderAdditionalFilters, PageBuilderKey } from 'enums';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Cooperations: FC = () => {
    const { cooperation, getCooperation, getExcel, deleteCooperation } = useCooperation();
    const { i18n } = useTranslation('common/shared');
    const navigate = useNavigate();

    return (
        <PageBuilder<Cooperation>
            pageKey={PageBuilderKey.Cooperation}
            data={cooperation}
            getItems={(params) => getCooperation(params as GetCooperation)}
            getExcel={(params) => getExcel(params as Omit<GetCooperation, 'page' | 'size'>)}
            deleteItems={deleteCooperation}
            columns={getTableColumns<Omit<Cooperation, 'id'>>(
                initialColumns,
                cellRenderMapper,
                (value, type) =>
                    renderCell(value, type, {
                        path: '/agreements',
                    }),
                i18n.getResourceBundle(i18n.language, 'common/shared')
            )}
            additionalFilters={[
                { type: PageBuilderAdditionalFilters.DebounceTextBox, key: FilterType.Country },
                { type: PageBuilderAdditionalFilters.DebounceTextBox, key: FilterType.Email },
                { type: PageBuilderAdditionalFilters.Dropdown, key: FilterType.Type },
            ]}
        />
    );
};

export default Cooperations;
