import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSpeaker, useToaster } from 'contexts';
import { DomainType, FormBuilderKeys } from 'enums';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { Heading, Loader } from '@kl/components-v6';
import { useTranslation } from 'react-i18next';
import { Speaker as SpeakerType } from 'types';
import { FormBuilder } from 'containers';
import { getSwaggerError } from 'utils/swagger-errors';

const INITIAL_DATA: Omit<SpeakerType, 'id' | 'creationDate'> = {
    firstName: '',
    lastName: '',
    position: '',
    isShowAboutPage: false,
    isShowHomePage: false,
    isShowCorporatePage: false,
    isShowGovernmentPage: false,
    domainType: DomainType.Ru,
    image: null,
};

const Speaker: FC = () => {
    const { getSpeaker, speaker, updateSpeaker, addSpeaker } = useSpeaker();
    const { id } = useParams();
    const navigate = useNavigate();
    const { setToaster } = useToaster();
    const [loading, setLoading] = useState<boolean>(false);

    const { t } = useTranslation(['pages/speakers', 'common/shared']);

    const methods = useForm<Omit<SpeakerType, 'id' | 'creationDate'>>();

    const onSubmit = async (data: FieldValues) => {
        setLoading(true);
        try {
            if (id) {
                await updateSpeaker({ ...data, id } as SpeakerType);
                setToaster({
                    type: 'success',
                    message: t('update-speaker-success'),
                });
            } else {
                await addSpeaker(data as SpeakerType);
                setToaster({
                    type: 'success',
                    message: t('add-speaker-success'),
                });
                navigate('/speakers');
            }
            setLoading(false);
        } catch (e) {
            const swagger = getSwaggerError(e);
            if (swagger) {
                setToaster({
                    type: 'error',
                    message: swagger,
                });
                return;
            }
            setToaster({
                type: 'error',
                message: t('backend-error', { ns: 'common/shared' }),
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (id) {
            getSpeaker(id);
        }
    }, [id]);

    if (id && !speaker) {
        return <Loader centered size={'large'} tip={t('loading', { ns: 'common/shared' })} />;
    }

    return (
        <>
            <Heading type={'H2'}>{id ? t('update-speaker') : t('add-speaker')}</Heading>
            <FormProvider {...methods}>
                <FormBuilder<Omit<SpeakerType, 'id' | 'creationDate'>>
                    data={speaker || INITIAL_DATA}
                    submit={onSubmit}
                    cancel={() => navigate('/speakers')}
                    formKey={FormBuilderKeys.Speaker}
                    isFormEmpty={!id}
                    loading={loading}
                />
            </FormProvider>
        </>
    );
};

export default Speaker;
