import React, { createContext, FC, PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react';
import { LS_KEYS } from 'consts';
import { getApiService, geTokenApiService, getStorageService } from 'services';
import { UserInfo } from 'types';
import { useAuthUrls } from 'hooks';

interface AuthContext {
    signIn: (code: string | null) => void;
    signOut: () => void;
    getUserInfo: () => void;
    isAuth: boolean;
    isLoading: boolean;
    userInfo: UserInfo | null;
}

const AuthContext = createContext<AuthContext>({} as AuthContext);

const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
    const storageService = getStorageService();
    const [isAuth, setIsAuth] = useState<boolean>(!!storageService.getItem(LS_KEYS['authToken']));
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
    const apiService = getApiService(storageService);
    const tokenService = geTokenApiService(apiService);
    const { redirectToADFSSignIn, redirectToADFSSignOut } = useAuthUrls();

    const signIn = useCallback(async (code: string | null) => {
        if (!code) {
            redirectToADFSSignIn();
        }

        const token = await tokenService.getToken(code);
        setIsLoading(true);
        storageService.setItem(LS_KEYS['authToken'], token.data.token);
        setIsLoading(false);
        setIsAuth(true);
    }, []);

    const signOut = () => {
        setIsAuth(false);
        const token = storageService.getItem(LS_KEYS['authToken']);

        if (token) {
            storageService.clearItem(LS_KEYS['authToken']);

            redirectToADFSSignOut();
        }
    };

    const getUserInfo = async () => {
        const userInfo = await tokenService.getUserInfo();
        setUserInfo(userInfo.data);
    };

    const memoValue = useMemo(
        () => ({
            isAuth,
            isLoading,
            signIn,
            signOut,
            getUserInfo,
            userInfo,
        }),
        [isAuth, isLoading, signIn, signOut, getUserInfo, userInfo]
    );

    return <AuthContext.Provider value={memoValue}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;
