import React, { FC } from 'react';
import { Controller, useFieldArray, Control, FieldErrors, useFormContext } from 'react-hook-form';
import { Button, Textbox, InformationCard, Calendar } from '@kl/components-v6';
import { useTranslation } from 'react-i18next';
import { Program } from 'types';
import { FormRow } from 'containers/news/news/styled';
import { DefaultEditor } from 'react-simple-wysiwyg';
import { ErrorMessage } from 'containers/form-builder/styled';
import { ArrayContainer } from './styled';
import ProgramEvents from './ProgramEvents';
import { DATE_FORMAT_WITH_TIME } from 'consts';
import moment from 'moment';

type FormValues = {
    programs: Program[];
};

interface ProgramsBaseProps {
    control: Control;
}

const ProgramsBase: FC<ProgramsBaseProps> = ({ control }) => {
    const { t } = useTranslation(['pages/events', 'common/shared']);

    const { fields, remove, append } = useFieldArray({
        control,
        name: 'programs',
    });

    const {
        formState: { errors },
        getValues,
        setValue,
        register,
        clearErrors,
    } = useFormContext<FormValues>();

    return (
        <>
            {fields.map((field, index) => {
                return (
                    <InformationCard key={field.id} style={{ marginBottom: 40, width: '100%' }}>
                        <FormRow style={{ margin: '0 0 40px 0', alignItems: 'flex-start' }}>
                            <span>
                                {`Program ${index + 1}`}
                                <br />
                                {
                                    //@ts-ignore
                                    field.creationDate && moment(field?.creationDate).format(DATE_FORMAT_WITH_TIME)
                                }
                            </span>

                            <ArrayContainer>
                                <div style={{ position: 'relative' }}>
                                    <Controller
                                        name={`programs[${index}].name`}
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: t('required-field', { ns: 'common/errors' }),
                                            },
                                            maxLength: {
                                                value: 100,
                                                message: t('max-length', {
                                                    ns: 'common/errors',
                                                    length: 100,
                                                }),
                                            },
                                        }}
                                        defaultValue={''}
                                        render={({ field: { onChange, value } }) => (
                                            <Textbox
                                                value={value}
                                                allowClear
                                                onChange={onChange}
                                                placeholder={t('program-name')}
                                            />
                                        )}
                                    />

                                    {errors?.programs?.[index]?.name && (
                                        <ErrorMessage style={{ left: 0 }}>
                                            {errors?.programs?.[index]?.name?.message ?? ''}
                                        </ErrorMessage>
                                    )}
                                </div>

                                <div style={{ position: 'relative' }}>
                                    <Calendar
                                        {...register(`programs.${index}.startTime`, {
                                            required: true,
                                        })}
                                        value={
                                            getValues(`programs.${index}.startTime`)
                                                ? new Date(getValues(`programs.${index}.startTime`) || '')
                                                : null
                                        }
                                        placeholder={t('program-start-time')}
                                        format={DATE_FORMAT_WITH_TIME}
                                        showTime
                                        allowClear
                                        showToday={false}
                                        onChange={(value) => {
                                            if (value === null) {
                                                setValue(`programs.${index}.startTime`, value, { shouldDirty: true });
                                            }
                                        }}
                                        onOk={(value) => {
                                            setValue(`programs.${index}.startTime`, value, { shouldDirty: true });
                                            clearErrors(`programs.${index}.startTime`);
                                        }}
                                    />

                                    {errors?.programs?.[index]?.startTime && (
                                        <ErrorMessage style={{ left: 0 }}>
                                            {t('required-calendar', { ns: 'common/errors' })}
                                        </ErrorMessage>
                                    )}
                                </div>

                                <div style={{ position: 'relative' }}>
                                    <Controller
                                        name={`programs[${index}].description`}
                                        control={control}
                                        rules={{
                                            maxLength: {
                                                value: 200,
                                                message: t('max-length', {
                                                    ns: 'common/errors',
                                                    length: 200,
                                                }),
                                            },
                                        }}
                                        defaultValue={''}
                                        render={({ field: { onChange, value } }) => (
                                            <DefaultEditor
                                                containerProps={{ style: { width: '100%' } }}
                                                value={value as string}
                                                onChange={(event) => onChange(event.target.value)}
                                            />
                                        )}
                                    />

                                    {errors?.programs?.[index]?.description && (
                                        <ErrorMessage style={{ left: 0 }}>
                                            {errors?.programs?.[index]?.description?.message ?? ''}
                                        </ErrorMessage>
                                    )}
                                </div>
                            </ArrayContainer>

                            <Button mode={'dangerOutlined'} onClick={() => remove(index)}>
                                {t('delete', { ns: 'common/shared' })}
                            </Button>
                        </FormRow>
                        <ProgramEvents
                            parentIndex={index}
                            control={control}
                            errors={errors.programs?.[index] as FieldErrors}
                        />
                    </InformationCard>
                );
            })}
            <Button
                mode={'primaryBlue'}
                onClick={() =>
                    append({
                        name: '',
                        description: '',
                        startTime: '',
                        creationDate: new Date(),
                    })
                }
            >
                {t('append-program', { ns: 'common/shared' })}
            </Button>
        </>
    );
};

export default ProgramsBase;
