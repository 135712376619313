import React from 'react';
import { CellRenderType } from 'enums';
import { Events } from 'types';

export const initialColumns: Omit<Events, 'image'> = {
    id: '',
    name: '',
    creationDate: new Date(),
    groupEn: {
        id: '',
        name: '',
        creationDate: new Date(),
        linkRu: '',
        linkEn: '',
    },
    groupRu: {
        id: '',
        name: '',
        creationDate: new Date(),
        linkRu: '',
        linkEn: '',
    },
    isVisible: false,
};

export const cellRenderMapper: Record<keyof Omit<Events, 'image'>, CellRenderType> = {
    id: CellRenderType.TEXT,
    name: CellRenderType.TEXT,
    creationDate: CellRenderType.DATETIME,
    groupRu: CellRenderType.GROUP,
    groupEn: CellRenderType.GROUP,
    isVisible: CellRenderType.BOOLEAN,
};
