import { INewsApi } from 'interfaces';
import { News, GetNewsSequence, NewsAddOrUpdatePhoto, HttpResponse } from 'types';
import { BaseApi } from './base-api.service';
import { generateFormData } from 'kl-b2c-ui-kit';

export class NewsApi extends BaseApi<GetNewsSequence, News> implements INewsApi<GetNewsSequence, News> {
    public addPhoto(data: NewsAddOrUpdatePhoto): Promise<HttpResponse<News>> {
        return this.apiService.request<News>({
            method: 'POST',
            url: `/${this.basePath}/addPhoto`,
            data: generateFormData(data),
        });
    }

    public deletePhoto(id: string): Promise<HttpResponse<News>> {
        return this.apiService.request<News>({
            method: 'DELETE',
            url: `/${this.basePath}/deletePhoto`,
            params: {
                id,
            },
        });
    }
}
