export enum FilterType {
    Type = 'type',
    Chapter = 'chapter',
    Country = 'country',
    Email = 'email',
    DomainType = 'domainType',
    Domain = 'domain',
    IsVisibleHomePage = 'isVisibleHomePage',
    IsVisibleCorporateBusinessPage = 'isVisibleCorporateBusinessPage',
    IsVisibleGovernmentPage = 'isVisibleGovernmentPage',
    IsShownAboutPage = 'isShowAboutPage',
    Name = 'name',
    IsVisible = 'isVisible',
    IsVisibleOnHomePage = 'isVisibleOnHomePage',
    IsVisibleTimeElapsed = 'isVisibleTimeElapsed',
    IsRegistrationOpened = 'isRegistrationOpened',
    PrivacyPolicyIsSelected = 'privacyPolicyIsSelected',
    MarketingIsSelected = 'marketingIsSelected',
    EventFormType = 'formType',
    CourseFormType = 'courseFormType',
    FirstName = 'firstName',
    LastName = 'lastName',
    CourseName = 'courseName',
    ProfessionName = 'professionName',
    ProfessionGroupType = 'professionGroupType',
    CourseGroupType = 'courseGroupType',
    IsShowCorporatePage = 'isShowCorporatePage',
    IsShowGovernmentPage = 'isShowGovernmentPage',
    IsShowHomePage = 'isShowHomePage',
}
