import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useOrganization, useToaster } from 'contexts';
import { DomainType, FormBuilderKeys } from 'enums';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { Heading, Loader } from '@kl/components-v6';
import { useTranslation } from 'react-i18next';
import { Organization as OrganizationType } from 'types';
import { FormBuilder } from 'containers';
import { getSwaggerError } from 'utils/swagger-errors';
import { GetFile } from 'kl-b2c-ui-kit';

const INITIAL_DATA: Omit<OrganizationType, 'id' | 'creationDate'> = {
    name: '',
    isVisibleGovernmentPage: false,
    domain: DomainType.Ru,
    image: {} as GetFile,
};

const Organization: FC = () => {
    const { getOrganization, organization, updateOrganization, addOrganization } = useOrganization();
    const { id } = useParams();
    const navigate = useNavigate();
    const { setToaster } = useToaster();
    const [loading, setLoading] = useState<boolean>(false);

    const { t } = useTranslation(['pages/organizations', 'common/shared']);

    const methods = useForm<Omit<OrganizationType, 'id' | 'creationDate'>>();

    const onSubmit = async (data: FieldValues) => {
        setLoading(true);
        try {
            if (id) {
                await updateOrganization({ ...data, id } as OrganizationType);
            } else {
                await addOrganization(data as OrganizationType);
            }
            setLoading(false);
            navigate('/organizations');
        } catch (e) {
            const swagger = getSwaggerError(e);
            if (swagger) {
                setToaster({
                    type: 'error',
                    message: swagger,
                });
                return;
            }
            setToaster({
                type: 'error',
                message: t('backend-error', { ns: 'common/shared' }),
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (id) {
            getOrganization(id);
        }
    }, [id]);

    if (id && !organization) {
        return <Loader centered size={'large'} tip={t('loading', { ns: 'common/shared' })} />;
    }

    return (
        <>
            <Heading type={'H2'}>{id ? t('update-organization') : t('add-organization')}</Heading>
            <FormProvider {...methods}>
                <FormBuilder<Omit<OrganizationType, 'id' | 'creationDate'>>
                    data={organization || INITIAL_DATA}
                    submit={onSubmit}
                    cancel={() => navigate('/organizations')}
                    formKey={FormBuilderKeys.Organization}
                    isFormEmpty={!id}
                    loading={loading}
                />
            </FormProvider>
        </>
    );
};

export default Organization;
