import React, { ChangeEvent, FC, useState } from 'react';
import { Delete } from '@kl/icons/16';
import { TEN_MB_IN_BYTES } from 'consts';
import { HiddenInput, Label, LabelContainer, DeleteContainer } from './styled';
import { useTranslation } from 'react-i18next';
import { formatBytes } from 'kl-b2c-ui-kit';

interface ImageUploaderProps {
    change: (file: File | null) => void;
    accept: string;
    maxSize?: number;
    preloadedFile?: File;
    placeholder?: string;
}

const ImageUploader: FC<ImageUploaderProps> = (props) => {
    const { change, accept, maxSize = TEN_MB_IN_BYTES, preloadedFile, placeholder } = props;
    const { t } = useTranslation('common/shared');

    const [file, setFile] = useState<File | null>(preloadedFile ? preloadedFile : null);

    return file ? (
        <DeleteContainer>
            <span>{file.name}</span>
            <Delete
                onClick={() => {
                    setFile(null);
                    change(null);
                }}
            />
        </DeleteContainer>
    ) : (
        <>
            <HiddenInput
                id={'file-uploader'}
                type={'file'}
                accept={accept}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    const files = (event.target as HTMLInputElement).files;
                    if (files && files[0]) {
                        setFile(files[0]);
                        change(files[0]);
                    }
                }}
            />
            <LabelContainer>
                <Label htmlFor={'file-uploader'}>{placeholder ? placeholder : t('upload-image')}</Label>
                <span>({t('image-help-message', { formats: accept, size: formatBytes(maxSize) })})</span>
            </LabelContainer>
        </>
    );
};

export default ImageUploader;
