import styled from 'styled-components';

export const FormRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 0 0 25px 0;
    gap: 10px;
    position: relative;

    & > span {
        width: 200px;
        flex-shrink: 0;
    }

    span {
        &:nth-child(2) {
            flex: 1;
        }
    }
`;
