export enum ApiBaseKeys {
    Agreements = 'agreement',
    Cooperation = 'cooperationForm',
    Subscription = 'subscriptionForm',
    Speakers = 'eventSpeaker',
    NewsGroups = 'newsGroup',
    News = 'news',
    NewsSequence = 'news',
    NewsTags = 'newsTag',
    Events = 'event',
    EventGroups = 'eventGroup',
    EventTags = 'eventTag',
    Courses = 'course',
    CourseGroups = 'courseGroup',
    CourseLanguages = 'courseLanguage',
    EventApplications = 'eventForm',
    CourseApplications = 'courseForm',
    ProfessionApplications = 'professionForm',
    ProfessionGroup = 'professionGroup',
    Professions = 'profession',
    Reviews = 'review',
    Organizations = 'organization',
}
