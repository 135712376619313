import React, { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEvent, useToaster } from 'contexts';
import { FormBuilderKeys } from 'enums';
import { Controller, FieldValues, FormProvider, useForm, useFieldArray } from 'react-hook-form';
import { Loader, Button, Heading, Textbox } from '@kl/components-v6';
import { useTranslation } from 'react-i18next';
import { Teaser, UpdateEventParticle } from 'types';
import { FormBuilder } from 'containers';
import { FormRow } from 'containers/news/news/styled';
import { ErrorMessage } from 'containers/form-builder/styled';
import { ArrayContainer } from './styled';
import moment from 'moment';
import { DATE_FORMAT_WITH_TIME } from 'consts/date';
import { DefaultEditor } from 'react-simple-wysiwyg';
import { EmptyList } from 'components/empty-list';

type FormValues = {
    teasers: Omit<Teaser, 'id'>[];
};

const Teasers: FC = () => {
    const { event, updateParticle } = useEvent();
    const { setToaster } = useToaster();
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);

    const { t } = useTranslation(['pages/events', 'common/shared']);

    const methods = useForm<FormValues>({ defaultValues: { teasers: event?.teasers || [] } });

    const {
        control,
        formState: { errors },
    } = methods;

    const { fields, append, remove } = useFieldArray<FormValues, 'teasers'>({
        control,
        name: 'teasers',
    });

    const onSubmit = async (data: FieldValues) => {
        setLoading(true);

        if (id) {
            await updateParticle<UpdateEventParticle<Teaser>>({ eventId: id, items: data.teasers }, 'updateTeasers');
            setToaster({
                type: 'success',
                message: t('update-teasers-success'),
            });
        }

        setLoading(false);
    };

    if (id && !event?.teasers) {
        return <Loader centered size={'large'} tip={t('loading', { ns: 'common/shared' })} />;
    }

    return (
        <>
            <Heading type={'H2'}>{t('update-teasers')}</Heading>
            <FormProvider {...methods}>
                <FormBuilder<FormValues>
                    data={{} as FormValues}
                    submit={onSubmit}
                    cancel={() => navigate('/events/all')}
                    formKey={FormBuilderKeys.EventGeneral}
                    isFormEmpty={!id}
                    loading={loading}
                >
                    <>
                        {!fields.length && <EmptyList />}
                        {fields.map((field, index) => {
                            return (
                                <div key={field.id}>
                                    <FormRow>
                                        <span>
                                            {`Teaser ${index + 1}`}
                                            <br />
                                            {
                                                //@ts-ignore
                                                field.creationDate &&
                                                    moment(field?.creationDate).format(DATE_FORMAT_WITH_TIME)
                                            }
                                        </span>

                                        <ArrayContainer>
                                            <div style={{ position: 'relative' }}>
                                                <Controller
                                                    name={`teasers.${index}.description`}
                                                    control={control}
                                                    rules={{
                                                        required: {
                                                            value: true,
                                                            message: t('required-field', { ns: 'common/errors' }),
                                                        },
                                                        maxLength: {
                                                            value: 500,
                                                            message: t('max-length', {
                                                                ns: 'common/errors',
                                                                length: 500,
                                                            }),
                                                        },
                                                    }}
                                                    defaultValue={''}
                                                    render={({ field: { onChange, value } }) => {
                                                        return (
                                                            <DefaultEditor
                                                                containerProps={{ style: { width: '100%' } }}
                                                                value={value as string}
                                                                onChange={(e) => onChange(e.target.value)}
                                                            />
                                                        );
                                                    }}
                                                />
                                                {errors?.teasers?.[index]?.description && (
                                                    <ErrorMessage style={{ left: 0 }}>
                                                        {errors?.teasers?.[index]?.description?.message ?? ''}
                                                    </ErrorMessage>
                                                )}
                                            </div>
                                            <div style={{ position: 'relative' }}>
                                                <Controller
                                                    name={`teasers.${index}.number`}
                                                    control={control}
                                                    rules={{
                                                        required: {
                                                            value: true,
                                                            message: t('required-field', { ns: 'common/errors' }),
                                                        },
                                                        min: {
                                                            value: 1,
                                                            message: t('min-value', {
                                                                ns: 'common/errors',
                                                                value: 1,
                                                            }),
                                                        },
                                                    }}
                                                    defaultValue={1}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Textbox
                                                            type={'number'}
                                                            value={value as unknown as string}
                                                            allowClear
                                                            onChange={(val) => {
                                                                if (!val) {
                                                                    onChange(val);
                                                                    return;
                                                                }
                                                                const newVal = `${val}`
                                                                    .replace('.', '')
                                                                    .replace(',', '');
                                                                onChange(newVal);
                                                            }}
                                                            placeholder={t('prize-fund-number')}
                                                        />
                                                    )}
                                                />

                                                {errors?.teasers?.[index]?.number && (
                                                    <ErrorMessage style={{ left: 0 }}>
                                                        {errors?.teasers?.[index]?.number?.message}
                                                    </ErrorMessage>
                                                )}
                                            </div>
                                        </ArrayContainer>

                                        <Button mode={'dangerOutlined'} onClick={() => remove(index)}>
                                            {t('delete', { ns: 'common/shared' })}
                                        </Button>
                                    </FormRow>
                                </div>
                            );
                        })}
                        <Button
                            mode={'primaryBlue'}
                            onClick={() =>
                                append({
                                    description: '',
                                    number: fields.length + 1,
                                    creationDate: new Date(),
                                })
                            }
                        >
                            {t('append', { ns: 'common/shared' })}
                        </Button>
                    </>
                </FormBuilder>
            </FormProvider>
        </>
    );
};

export default Teasers;
