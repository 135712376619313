import React, { FC } from 'react';
import { useNewsSequence } from 'contexts';
import { cellRenderMapper, initialColumns } from './mappers';
import { NewsSequence as NewsModel, NewsTable } from 'types';
import { renderCell, getTableColumns } from 'utils';
import { PageBuilder } from 'containers';
import { DomainType, FilterType, PageBuilderAdditionalFilters, PageBuilderKey } from 'enums';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const NewsSequence: FC = () => {
    const { news, getNewsSequence, getExcel, deleteNewsSequence } = useNewsSequence();
    const { i18n } = useTranslation('pages/news-sequence');
    const navigate = useNavigate();

    return (
        <PageBuilder<NewsTable>
            pageKey={PageBuilderKey.NewsSequence}
            data={news}
            getItems={(params) => getNewsSequence(params as NewsModel)}
            deleteItems={deleteNewsSequence}
            columns={getTableColumns<NewsTable>(
                initialColumns,
                cellRenderMapper,
                renderCell,
                i18n.getResourceBundle(i18n.language, 'pages/news-sequence')
            )}
            additionalFilters={[
                { type: PageBuilderAdditionalFilters.Dropdown, key: FilterType.DomainType },
                { type: PageBuilderAdditionalFilters.Dropdown, key: FilterType.IsVisibleOnHomePage },
                { type: PageBuilderAdditionalFilters.Dropdown, key: FilterType.IsVisible },
                { type: PageBuilderAdditionalFilters.DebounceTextBox, key: FilterType.Name },
            ]}
            updateItem={(record: NewsTable) => {
                const { id, groupEn, groupRu } = record;
                navigate(`/single-news/${groupEn?.id || groupRu?.id}/${groupRu ? DomainType.Ru : DomainType.En}/${id}`);
            }}
        />
    );
};

export default NewsSequence;
