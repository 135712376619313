import React from 'react';
import { Organization } from 'types';
import { DomainType, CellRenderType } from 'enums';
import { GetFile } from 'kl-b2c-ui-kit';

export const initialColumns: Organization = {
    id: '',
    creationDate: new Date(),
    name: '',
    isVisibleGovernmentPage: false,
    domain: DomainType.Ru,
    image: {} as GetFile,
};

export const cellRenderMapper: Record<keyof Organization, CellRenderType> = {
    id: CellRenderType.ORGANIZATION_ID,
    creationDate: CellRenderType.DATETIME,
    name: CellRenderType.TEXT,
    isVisibleGovernmentPage: CellRenderType.BOOLEAN,
    domain: CellRenderType.DOMAIN_TYPE,
    image: CellRenderType.IMAGE_PREVIEW,
};
