import React, { FC } from 'react';
import { useNewsGroups } from 'contexts';
import { cellRenderMapper, initialColumns } from './mappers';
import { Group, GroupWithBusy, NewsGroups as NewsGroupsModel } from 'types';
import { renderCell, getTableColumns } from 'utils';
import { PageBuilder } from 'containers';
import { DomainType, FilterType, PageBuilderAdditionalFilters, PageBuilderKey } from 'enums';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const NewsGroups: FC = () => {
    const { newsGroups, getNewsGroups, getExcel, deleteNewsGroups } = useNewsGroups();
    const { i18n } = useTranslation('pages/news-groups');
    const navigate = useNavigate();

    return (
        <PageBuilder<GroupWithBusy>
            pageKey={PageBuilderKey.NewsGroups}
            data={{
                count: newsGroups.count,
                items:
                    newsGroups.items?.map((item) => ({
                        ...item,
                        busyEn: {
                            busy: item.busyEn,
                            id: item.id,
                            domain: DomainType.En,
                            newsId: item.linkEn,
                        },
                        busyRu: {
                            busy: item.busyRu,
                            id: item.id,
                            domain: DomainType.Ru,
                            newsId: item.linkRu,
                        },
                    })) || [],
            }}
            getItems={(params) => getNewsGroups(params as NewsGroupsModel)}
            deleteItems={deleteNewsGroups}
            columns={[
                ...getTableColumns<Omit<Group, 'busyEn' | 'busyRu' | 'linkEn' | 'linkRu'>>(
                    initialColumns,
                    cellRenderMapper,
                    (value, type) => renderCell(value, type, { path: '/single-news', navigate }),
                    i18n.getResourceBundle(i18n.language, 'pages/news-groups')
                ),
            ]}
            additionalFilters={[{ type: PageBuilderAdditionalFilters.DebounceTextBox, key: FilterType.Name }]}
            addItem={() => navigate('/news-group')}
            updateItem={(group: GroupWithBusy) => navigate(`/news-group/${group.id}`)}
        />
    );
};

export default NewsGroups;
