import { Tabs } from '@kl/components-v6';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { General, Teasers, PrizeFunds, Phases, Results, TargetAudiences, Files, Gallery, Programs } from './features';
import { useEvent } from 'contexts';

const Event = () => {
    const { id } = useParams();
    const { getEvent, event } = useEvent();

    useEffect(() => {
        if (id) {
            getEvent(id);
        }
    }, [id]);

    if (!id) {
        return <General />;
    }

    return (
        <Tabs defaultActiveKey={'event/general'}>
            <Tabs.TabPane disabled={!event} tab="General" key={'event/general'}>
                <General />
            </Tabs.TabPane>
            <Tabs.TabPane disabled={!event} tab="Teasers" key={'event/teasers'}>
                <Teasers />
            </Tabs.TabPane>
            <Tabs.TabPane disabled={!event} tab="Prize funds" key={'event/prizeFunds'}>
                <PrizeFunds />
            </Tabs.TabPane>
            <Tabs.TabPane disabled={!event} tab="Target audiences" key={'event/targetAudiences'}>
                <TargetAudiences />
            </Tabs.TabPane>
            <Tabs.TabPane disabled={!event} tab="Phases" key={'event/phases'}>
                <Phases />
            </Tabs.TabPane>
            <Tabs.TabPane disabled={!event} tab="Results" key={'event/results'}>
                <Results />
            </Tabs.TabPane>
            <Tabs.TabPane disabled={!event} tab="Files" key={'event/files'}>
                <Files />
            </Tabs.TabPane>
            <Tabs.TabPane disabled={!event} tab="Gallery" key={'event/gallery'}>
                <Gallery />
            </Tabs.TabPane>
            <Tabs.TabPane disabled={!event} tab="Programs" key={'event/programs'}>
                <Programs />
            </Tabs.TabPane>
        </Tabs>
    );
};

export default Event;
