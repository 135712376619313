import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
  }

  #root {
    display: flex;
    flex-direction: row;
    min-height: 100%;
    height: 100%;
  }
`;
