import { Checkbox, Heading, Loader, Select, Textbox } from '@kl/components-v6';
import { FormBuilder } from 'containers';
import { enumsToOptions } from 'containers/form-builder/mappers';
import { ErrorMessage } from 'containers/form-builder/styled';
import { FormRow } from 'containers/news/news/styled';
import { useModal, useProfession, useProfessions, useToaster } from 'contexts';
import {
    ControlRendererType,
    CourseDirection,
    CourseFormType,
    CourseForWhom,
    CourseSkill,
    DomainType,
    EventType,
    FormBuilderKeys,
    ModalType,
} from 'enums';
import React, { FC, useEffect, useState } from 'react';
import { Controller, FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import DefaultEditor from 'react-simple-wysiwyg';
import { AddProfession, Profession, UpdateProfession } from 'types';
import { getSwaggerError } from 'utils/swagger-errors';

const INITIAL_DATA: AddProfession = {
    prevId: '',
    image: null,
    name: '',
    isVisible: false,
    additionalDescription: '',
    baseDescription: '',
    isVisibleHomePage: false,
    formTypes: [],
    additionalImage: null,
    announcementDescription: '',
    studyForms: [],
    forWhom: [],
    skill: CourseSkill.CommunicatesManagers,
    duration: '',
    directionTypes: [],
    groupEnId: null,
    groupRuId: null,
    languages: [],
    isExistCourse: false,
    isFree: false,
    link: '',
};

const General: FC = () => {
    const { updateItem, addItem, item, professionSelect, getSelectedCoursesProfessions } = useProfession();
    const { deleteItems } = useProfessions();
    const { setToaster } = useToaster();
    const { id, groupId, domain } = useParams();
    const navigate = useNavigate();
    const { setModal } = useModal();
    const [loading, setLoading] = useState<boolean>(false);

    const { t } = useTranslation(['pages/professions', 'common/shared', 'common/errors']);

    const methods = useForm<AddProfession>();

    const {
        watch,
        formState: { errors },
    } = methods;

    const { control } = methods;

    useEffect(() => {
        getSelectedCoursesProfessions(Number(domain as unknown as DomainType));
    }, []);

    const onSubmit = async (data: FieldValues) => {
        try {
            setLoading(true);

            if (!data.skill) delete data.skill;

            if (Number(domain as unknown as DomainType) === DomainType.Ru) {
                data = { ...data, groupRuId: groupId, isFree: !!data?.isFree };
            } else {
                data = { ...data, groupEnId: groupId, isFree: !!data?.isFree };
            }

            if (id) {
                await updateItem({ ...data, id } as UpdateProfession);
                setToaster({
                    type: 'success',
                    message: t('update-general-success'),
                });
            } else {
                const newProfession = await addItem(data as AddProfession);
                navigate(`/profession/${groupId}/${domain}/${newProfession.id}`);
            }
        } catch (e) {
            const swagger = getSwaggerError(e);
            if (swagger) {
                setToaster({
                    type: 'error',
                    message: swagger,
                });
                return;
            }
            setToaster({
                type: 'error',
                message: t('backend-error', { ns: 'common/shared' }),
            });
        } finally {
            setLoading(false);
        }
    };

    if (id && !item) {
        return <Loader centered size={'large'} tip={t('loading', { ns: 'common/shared' })} />;
    }

    const convert = (profession: Profession | null): AddProfession | null => {
        if (!profession) return null;

        return {
            ...profession,
            groupEnId: profession?.groupEn?.id,
            groupRuId: profession?.groupRu?.id,
        };
    };

    const onDeleteProfession = async () => {
        if (id) {
            setModal(ModalType.Confirm, <div>{t('delete-confirm', { ns: 'common/shared' })}</div>, async () => {
                setLoading(true);

                await deleteItems([id]);

                setLoading(false);
                navigate('/professions/all');
            });
        }
    };

    const includeCoursesWatch = watch('isExistCourse')?.valueOf() ?? item?.isExistCourse;

    return (
        <>
            <Heading type={'H2'}>{id ? t('update-general') : t('add-general')}</Heading>
            <FormProvider {...methods}>
                <FormBuilder<AddProfession>
                    data={convert(item) || INITIAL_DATA}
                    submit={onSubmit}
                    cancel={() => navigate('/professions/all')}
                    onDelete={onDeleteProfession}
                    formKey={FormBuilderKeys.ProfessionGeneral}
                    isFormEmpty={!id}
                    loading={loading}
                >
                    <FormRow>
                        <span>{t('isExistCourse', { ns: 'common/shared' })}</span>
                        <Controller
                            name={'isExistCourse'}
                            control={control}
                            defaultValue={item?.isExistCourse || INITIAL_DATA.isExistCourse}
                            render={({ field: { onChange, value } }) => (
                                <Checkbox defaultChecked={value} onChange={onChange} />
                            )}
                        />
                    </FormRow>
                    {includeCoursesWatch && (
                        <>
                            <FormRow>
                                <span>{t('baseDescription', { ns: 'common/shared' })}*</span>
                                <Controller
                                    name={'baseDescription'}
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: t('required-field', {
                                                ns: 'common/errors',
                                            }),
                                        },
                                        maxLength: {
                                            value: 5000,
                                            message: t('max-length', {
                                                ns: 'common/errors',
                                                length: 5000,
                                            }),
                                        },
                                    }}
                                    defaultValue={item?.baseDescription ?? ''}
                                    render={({ field: { onChange, value } }) => {
                                        return (
                                            <DefaultEditor
                                                containerProps={{ style: { width: '100%' } }}
                                                onClick={(e) => {
                                                    // Strange behaviour for cursor, so I have to prevent it
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                }}
                                                value={value}
                                                onChange={(event) => onChange(event.target.value)}
                                            />
                                        );
                                    }}
                                />
                                {errors.baseDescription && (
                                    <ErrorMessage>{errors.baseDescription.message}</ErrorMessage>
                                )}
                            </FormRow>
                            <FormRow>
                                <span>{t('additionalDescription', { ns: 'common/shared' })}*</span>
                                <Controller
                                    name={'additionalDescription'}
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: t('required-field', {
                                                ns: 'common/errors',
                                            }),
                                        },
                                        maxLength: {
                                            value: 2000,
                                            message: t('max-length', {
                                                ns: 'common/errors',
                                                length: 2000,
                                            }),
                                        },
                                    }}
                                    defaultValue={item?.additionalDescription ?? ''}
                                    render={({ field: { onChange, value } }) => {
                                        return (
                                            <DefaultEditor
                                                containerProps={{ style: { width: '100%' } }}
                                                onClick={(e) => {
                                                    // Strange behaviour for cursor, so I have to prevent it
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                }}
                                                value={value}
                                                onChange={(event) => onChange(event.target.value)}
                                            />
                                        );
                                    }}
                                />
                                {errors.additionalDescription && (
                                    <ErrorMessage>{errors.additionalDescription.message}</ErrorMessage>
                                )}
                            </FormRow>
                            <FormRow>
                                <span>{t('previous-profession', { ns: 'common/shared' })}</span>
                                <Controller
                                    name={'prevId'}
                                    control={control}
                                    defaultValue={item?.prevProfession?.id ?? ''}
                                    render={({ field: { onChange } }) => {
                                        return (
                                            <Select
                                                allowClear
                                                showSearch
                                                onChange={onChange}
                                                defaultValue={
                                                    item?.prevProfession
                                                        ? {
                                                              label: item?.prevProfession?.name,
                                                              value: item?.prevProfession?.id,
                                                          }
                                                        : []
                                                }
                                                error={!!errors.prevId}
                                                options={[
                                                    {
                                                        label: 'empty',
                                                        value: '',
                                                    },
                                                    ...professionSelect
                                                        .filter((r) => r.id !== id)
                                                        .map((r) => ({
                                                            label: r.name,
                                                            value: r.id,
                                                        })),
                                                ]}
                                            />
                                        );
                                    }}
                                />
                                {errors.prevId && <ErrorMessage>{errors.prevId.message}</ErrorMessage>}
                            </FormRow>
                            <FormRow>
                                <span>{t('duration', { ns: 'common/shared' })}*</span>
                                <Controller
                                    name={'duration'}
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: t('required-field', {
                                                ns: 'common/errors',
                                            }),
                                        },
                                        maxLength: {
                                            value: 100,
                                            message: t('max-length', {
                                                ns: 'common/errors',
                                                length: 100,
                                            }),
                                        },
                                    }}
                                    defaultValue={item?.duration || ''}
                                    render={({ field: { onChange, value } }) => (
                                        <Textbox
                                            value={value}
                                            invalid={!!errors['duration']?.message}
                                            allowClear
                                            onChange={onChange}
                                            placeholder={t('duration', { ns: 'common/shared' })}
                                        />
                                    )}
                                />
                                {errors.duration && <ErrorMessage>{errors.duration.message}</ErrorMessage>}
                            </FormRow>
                            <FormRow>
                                <span>{t('languages', { ns: 'common/shared' })}*</span>
                                <Controller
                                    name={'languages'}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: t('required-field', {
                                                ns: 'common/errors',
                                            }),
                                        },
                                    }}
                                    control={control}
                                    defaultValue={item?.languages || []}
                                    render={({ field: { onChange } }) => {
                                        const enumsKeys = enumsToOptions(ControlRendererType.Languages);
                                        return (
                                            <Select
                                                showSearch
                                                mode={'multiple'}
                                                onChange={onChange}
                                                defaultValue={item?.languages || []}
                                                error={!!errors.languages}
                                                options={enumsKeys.map((option) => ({
                                                    ...option,
                                                }))}
                                            />
                                        );
                                    }}
                                />
                                {errors.languages && <ErrorMessage>{errors.languages.message}</ErrorMessage>}
                            </FormRow>
                            <FormRow>
                                <span>{t('studyForms', { ns: 'common/shared' })}*</span>
                                <Controller
                                    name={'studyForms'}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: t('required-field', {
                                                ns: 'common/errors',
                                            }),
                                        },
                                    }}
                                    control={control}
                                    defaultValue={item?.studyForms || []}
                                    render={({ field: { onChange } }) => {
                                        const enumsKeys = enumsToOptions(ControlRendererType.CourseStudyForms);
                                        return (
                                            <Select
                                                showSearch
                                                mode={'multiple'}
                                                onChange={onChange}
                                                defaultValue={item?.studyForms || []}
                                                error={!!errors.studyForms}
                                                options={enumsKeys.map((option) => ({
                                                    ...option,
                                                    disabled:
                                                        watch('studyForms').length >= 40 &&
                                                        !watch('studyForms').includes(option.value as EventType),
                                                }))}
                                            />
                                        );
                                    }}
                                />
                                {errors.studyForms && <ErrorMessage>{errors.studyForms.message}</ErrorMessage>}
                            </FormRow>
                            <FormRow>
                                <span>{t('isFree', { ns: 'common/shared' })}</span>
                                <Controller
                                    name={'isFree'}
                                    control={control}
                                    defaultValue={item?.isFree}
                                    render={({ field: { onChange, value } }) => (
                                        <Checkbox defaultChecked={value} onChange={onChange} />
                                    )}
                                />
                            </FormRow>
                            <FormRow>
                                <span>{t('formTypes', { ns: 'common/shared' })}*</span>
                                <Controller
                                    name={'formTypes'}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: t('required-field', {
                                                ns: 'common/errors',
                                            }),
                                        },
                                    }}
                                    control={control}
                                    defaultValue={item?.formTypes || []}
                                    render={({ field: { onChange } }) => {
                                        const enumsKeys = enumsToOptions(ControlRendererType.CourseFormTypes);
                                        return (
                                            <Select
                                                showSearch
                                                mode={'multiple'}
                                                onChange={onChange}
                                                defaultValue={item?.formTypes || []}
                                                error={!!errors.formTypes}
                                                options={enumsKeys.map((option) => ({
                                                    ...option,
                                                    disabled:
                                                        watch('formTypes').length >= 4 &&
                                                        !watch('formTypes').includes(option.value as CourseFormType),
                                                }))}
                                            />
                                        );
                                    }}
                                />
                                {errors.formTypes && <ErrorMessage>{errors.formTypes.message}</ErrorMessage>}
                            </FormRow>
                        </>
                    )}
                    <FormRow>
                        <span>{t('skill', { ns: 'common/shared' })}*</span>
                        <Controller
                            name={'skill'}
                            rules={{
                                required: {
                                    value: true,
                                    message: t('required-field', {
                                        ns: 'common/errors',
                                    }),
                                },
                            }}
                            control={control}
                            defaultValue={item?.skill}
                            render={({ field: { onChange } }) => {
                                const eventTypeOptions = enumsToOptions(ControlRendererType.CourseSkill);
                                return (
                                    <Select
                                        defaultValue={item?.skill ?? ''}
                                        onSelect={(value) => onChange(value as string)}
                                        options={eventTypeOptions}
                                    />
                                );
                            }}
                        />
                        {errors.skill && <ErrorMessage>{errors.skill.message}</ErrorMessage>}
                    </FormRow>
                    {!includeCoursesWatch && (
                        <FormRow>
                            <span>{t('link', { ns: 'common/shared' })}*</span>
                            <Controller
                                name={'link'}
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: t('required-field', {
                                            ns: 'common/errors',
                                        }),
                                    },
                                    maxLength: {
                                        value: 1000,
                                        message: t('max-length', {
                                            ns: 'common/errors',
                                            length: 1000,
                                        }),
                                    },
                                }}
                                defaultValue={item?.link || ''}
                                render={({ field: { onChange, value } }) => (
                                    <Textbox
                                        value={value}
                                        invalid={!!errors.link}
                                        allowClear
                                        onChange={onChange}
                                        placeholder={t('link', { ns: 'common/shared' })}
                                    />
                                )}
                            />
                            {errors.link && <ErrorMessage>{errors.link.message}</ErrorMessage>}
                        </FormRow>
                    )}
                    <FormRow>
                        <span>{t('directionTypes', { ns: 'common/shared' })}*</span>
                        <Controller
                            name={'directionTypes'}
                            rules={{
                                required: {
                                    value: true,
                                    message: t('required-field', {
                                        ns: 'common/errors',
                                    }),
                                },
                            }}
                            control={control}
                            defaultValue={item?.directionTypes || []}
                            render={({ field: { onChange } }) => {
                                const enumsKeys = enumsToOptions(ControlRendererType.CourseDirectionTypes);
                                return (
                                    <Select
                                        showSearch
                                        mode={'multiple'}
                                        onChange={onChange}
                                        defaultValue={item?.directionTypes || []}
                                        error={!!errors.directionTypes}
                                        options={enumsKeys.map((option) => ({
                                            ...option,
                                            disabled:
                                                watch('directionTypes').length >= 40 &&
                                                !watch('directionTypes').includes(option.value as CourseDirection),
                                        }))}
                                    />
                                );
                            }}
                        />
                        {errors.directionTypes && <ErrorMessage>{errors.directionTypes.message}</ErrorMessage>}
                    </FormRow>
                    <FormRow>
                        <span>{t('forWhom', { ns: 'common/shared' })}*</span>
                        <Controller
                            name={'forWhom'}
                            rules={{
                                required: {
                                    value: true,
                                    message: t('required-field', {
                                        ns: 'common/errors',
                                    }),
                                },
                            }}
                            control={control}
                            defaultValue={item?.forWhom || []}
                            render={({ field: { onChange } }) => {
                                const enumsKeys = enumsToOptions(ControlRendererType.CourseForWhom);
                                return (
                                    <Select
                                        showSearch
                                        mode={'multiple'}
                                        onChange={onChange}
                                        defaultValue={item?.forWhom || []}
                                        error={!!errors.forWhom}
                                        options={enumsKeys.map((option) => ({
                                            ...option,
                                            disabled:
                                                watch('forWhom').length >= 40 &&
                                                !watch('forWhom').includes(option.value as CourseForWhom),
                                        }))}
                                    />
                                );
                            }}
                        />
                        {errors.forWhom && <ErrorMessage>{errors.forWhom.message}</ErrorMessage>}
                    </FormRow>
                    <FormRow>
                        {domain && (
                            <>
                                {Number(domain as unknown as DomainType) === DomainType.Ru ? (
                                    <>
                                        <span>{t('groupRuId', { ns: 'common/shared' })}</span>
                                        <span>{item?.groupRu.name}</span>
                                    </>
                                ) : (
                                    <>
                                        <span>{t('groupEnId', { ns: 'common/shared' })}</span>
                                        <span>{item?.groupEn.name}</span>
                                    </>
                                )}
                            </>
                        )}
                    </FormRow>
                </FormBuilder>
            </FormProvider>
        </>
    );
};

export default General;
