import React, { FC } from 'react';
import { Controller, useFieldArray, Control, FieldErrors, useFormContext } from 'react-hook-form';
import { Button, Calendar, Textbox } from '@kl/components-v6';
import { useTranslation } from 'react-i18next';
import { ProgramEvent } from 'types';
import { FormRow } from 'containers/news/news/styled';
import { ErrorMessage } from 'containers/form-builder/styled';
import { ArrayContainer } from './styled';
import { DATE_FORMAT_WITH_TIME } from 'consts/date';
import moment from 'moment';
import DefaultEditor from 'react-simple-wysiwyg';

type FormValues = {
    eventProgramEvents: ProgramEvent[];
};

interface ProgramEventsProps {
    parentIndex: number;
    control: Control;
    errors: FieldErrors<FormValues>;
}

const ProgramEvents: FC<ProgramEventsProps> = ({ parentIndex, control, errors }) => {
    const { t } = useTranslation(['pages/events', 'common/shared']);

    const { getValues, register, clearErrors, setValue } = useFormContext<FormValues>();

    const { fields, remove, append } = useFieldArray({
        control,
        name: `programs[${parentIndex}].eventProgramEvents`,
    });

    return (
        <>
            {fields.map((field, index) => {
                return (
                    <FormRow key={field.id} style={{ margin: '0 0 40px 0', alignItems: 'flex-start' }}>
                        <span>
                            {`Event ${index + 1}`}
                            <br />
                            {
                                //@ts-ignore
                                field.creationDate && moment(field?.creationDate).format(DATE_FORMAT_WITH_TIME)
                            }
                        </span>

                        <ArrayContainer>
                            <div style={{ position: 'relative' }}>
                                <Controller
                                    name={`programs[${parentIndex}].eventProgramEvents[${index}].name`}
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: t('required-field', { ns: 'common/errors' }),
                                        },
                                        maxLength: {
                                            value: 200,
                                            message: t('max-length', {
                                                ns: 'common/errors',
                                                length: 200,
                                            }),
                                        },
                                    }}
                                    defaultValue={''}
                                    render={({ field: { onChange, value } }) => (
                                        <Textbox
                                            value={value}
                                            allowClear
                                            onChange={onChange}
                                            placeholder={t('event-name')}
                                        />
                                    )}
                                />

                                {errors?.eventProgramEvents?.[index]?.name && (
                                    <ErrorMessage style={{ left: 0 }}>
                                        {errors?.eventProgramEvents?.[index]?.name?.message}
                                    </ErrorMessage>
                                )}
                            </div>

                            <div style={{ position: 'relative' }}>
                                {/*TODO: a lot of ts-ignores here, we should understand why calendar doesnt typed as expected*/}
                                <Calendar
                                    // @ts-ignore
                                    {...register(`programs.${parentIndex}.eventProgramEvents.${index}.startTime`, {
                                        required: true,
                                    })}
                                    value={
                                        // @ts-ignore
                                        getValues(`programs.${parentIndex}.eventProgramEvents.${index}.startTime`)
                                            ? new Date(
                                                  // @ts-ignore
                                                  getValues(
                                                      // @ts-ignore
                                                      `programs.${parentIndex}.eventProgramEvents.${index}.startTime`
                                                  )
                                              )
                                            : null
                                    }
                                    placeholder={t('event-start-time')}
                                    format={DATE_FORMAT_WITH_TIME}
                                    showTime
                                    allowClear
                                    onChange={(value) => {
                                        if (value === null) {
                                            setValue(
                                                // @ts-ignore
                                                `programs.${parentIndex}.eventProgramEvents.${index}.startTime`,
                                                null,
                                                { shouldDirty: true }
                                            );
                                        }
                                    }}
                                    onOk={(value) => {
                                        setValue(
                                            // @ts-ignore
                                            `programs.${parentIndex}.eventProgramEvents.${index}.startTime`,
                                            value,
                                            { shouldDirty: true }
                                        );
                                        // @ts-ignore
                                        clearErrors(`programs.${parentIndex}.eventProgramEvents.${index}.startTime`);
                                    }}
                                />

                                {errors?.eventProgramEvents?.[index]?.startTime && (
                                    <ErrorMessage style={{ left: 0 }}>
                                        {t('required-calendar', { ns: 'common/errors' })}
                                    </ErrorMessage>
                                )}
                            </div>
                            <div style={{ position: 'relative' }}>
                                <Controller
                                    name={`programs[${parentIndex}].eventProgramEvents[${index}].description`}
                                    control={control}
                                    rules={{
                                        maxLength: {
                                            value: 2000,
                                            message: t('max-length', {
                                                ns: 'common/errors',
                                                length: 2000,
                                            }),
                                        },
                                    }}
                                    defaultValue={''}
                                    render={({ field: { onChange, value } }) => (
                                        <DefaultEditor
                                            containerProps={{ style: { width: '100%' } }}
                                            value={value as string}
                                            onChange={onChange}
                                        />
                                    )}
                                />

                                {errors?.eventProgramEvents?.[index]?.description && (
                                    <ErrorMessage style={{ left: 0 }}>
                                        {errors?.eventProgramEvents?.[index]?.description?.message}
                                    </ErrorMessage>
                                )}
                            </div>
                        </ArrayContainer>

                        <Button mode={'dangerOutlined'} onClick={() => remove(index)}>
                            {t('delete', { ns: 'common/shared' })}
                        </Button>
                    </FormRow>
                );
            })}
            <Button
                mode={'primaryBlack'}
                onClick={() =>
                    append({
                        startTime: '',
                        name: '',
                        description: '',
                        creationDate: new Date(),
                    })
                }
            >
                {t('append-event-program')}
            </Button>
        </>
    );
};

export default ProgramEvents;
