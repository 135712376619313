import React, { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEvent, useToaster } from 'contexts';
import { FormBuilderKeys } from 'enums';
import { Controller, FieldValues, FormProvider, useForm, useFieldArray } from 'react-hook-form';
import { Loader, Button, Heading, Textbox } from '@kl/components-v6';
import { useTranslation } from 'react-i18next';
import { File as InnerFile } from 'types';
import { FormBuilder } from 'containers';
import { FormRow } from 'containers/news/news/styled';
import { ErrorMessage } from 'containers/form-builder/styled';
import { GetFile } from 'kl-b2c-ui-kit';
import { EmptyList, ImagePreview, ImageUploader } from 'components';
import { EVENTS_FILES, TEN_MB_IN_BYTES } from 'consts';
import { ArrayContainer } from './styled';
import { fileSize } from 'containers/form-builder/rules';

type FormValues = {
    files: Omit<InnerFile, 'id' | 'creationDate' | 'description'>[];
};

const Files: FC = () => {
    const { event, updateParticleWithFiles } = useEvent();
    const { setToaster } = useToaster();
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);

    const { t } = useTranslation(['pages/events', 'common/shared']);

    const methods = useForm<FormValues>({ defaultValues: { files: event?.files || [] } });

    const {
        control,
        formState: { errors },
    } = methods;

    const { fields, append, remove } = useFieldArray<FormValues, 'files'>({
        control,
        name: 'files',
    });

    const onSubmit = async (data: FieldValues) => {
        setLoading(true);

        if (id) {
            await updateParticleWithFiles<File>({ eventId: id, items: data.files }, 'updateFiles');
            setToaster({
                type: 'success',
                message: t('update-files-success'),
            });
        }

        setLoading(false);
    };

    if (id && !event?.files) {
        return <Loader centered size={'large'} tip={t('loading', { ns: 'common/shared' })} />;
    }

    return (
        <>
            <Heading type={'H2'}>{t('update-files')}</Heading>
            <FormProvider {...methods}>
                <FormBuilder<FormValues>
                    data={{} as FormValues}
                    submit={onSubmit}
                    cancel={() => navigate('/events/all')}
                    formKey={FormBuilderKeys.EventGeneral}
                    isFormEmpty={!id}
                    loading={loading}
                >
                    <>
                        {!fields.length && <EmptyList />}
                        {fields.map((field, index) => {
                            return (
                                <FormRow key={field.id} style={{ margin: '0 0 45px 0' }}>
                                    <span>{`File ${index + 1}`}</span>

                                    <ArrayContainer>
                                        <div style={{ position: 'relative' }}>
                                            <Controller
                                                name={`files.${index}.name`}
                                                control={control}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: t('required-field', { ns: 'common/errors' }),
                                                    },
                                                    maxLength: {
                                                        value: 100,
                                                        message: t('max-length', {
                                                            ns: 'common/errors',
                                                            length: 100,
                                                        }),
                                                    },
                                                }}
                                                defaultValue={''}
                                                render={({ field: { onChange, value } }) => (
                                                    <Textbox
                                                        value={value}
                                                        allowClear
                                                        onChange={onChange}
                                                        placeholder={t('file-name')}
                                                    />
                                                )}
                                            />

                                            {errors?.files?.[index]?.name && (
                                                <ErrorMessage style={{ left: 0 }}>
                                                    {t('required-field', { ns: 'common/errors' })}
                                                </ErrorMessage>
                                            )}
                                        </div>

                                        <div style={{ position: 'relative' }}>
                                            <Controller
                                                name={`files.${index}.file`}
                                                control={control}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: t('required-file', { ns: 'common/errors' }),
                                                    },
                                                    validate: (file: File | GetFile | null) => {
                                                        return fileSize(file, TEN_MB_IN_BYTES, EVENTS_FILES, t);
                                                    },
                                                }}
                                                defaultValue={null}
                                                render={({ field: { onChange, value } }) => {
                                                    if ((value as GetFile)?.id) {
                                                        return (
                                                            <ImagePreview
                                                                clear={() => onChange(null)}
                                                                size={
                                                                    value?.contentType.includes('image') ? 150 : 'auto'
                                                                }
                                                                image={value as GetFile}
                                                            />
                                                        );
                                                    }

                                                    return (
                                                        <ImageUploader
                                                            accept={EVENTS_FILES}
                                                            maxSize={TEN_MB_IN_BYTES}
                                                            placeholder={t('upload-file-placeholder')}
                                                            change={onChange}
                                                        />
                                                    );
                                                }}
                                            />

                                            {errors?.files?.[index]?.file && (
                                                <ErrorMessage style={{ left: 0 }}>
                                                    {errors?.files?.[index]?.file?.message ?? ''}
                                                </ErrorMessage>
                                            )}
                                        </div>
                                    </ArrayContainer>

                                    <Button mode={'dangerOutlined'} onClick={() => remove(index)}>
                                        {t('delete', { ns: 'common/shared' })}
                                    </Button>
                                </FormRow>
                            );
                        })}
                        <Button
                            mode={'primaryBlue'}
                            onClick={() =>
                                append({
                                    name: '',
                                    file: null,
                                })
                            }
                        >
                            {t('append', { ns: 'common/shared' })}
                        </Button>
                    </>
                </FormBuilder>
            </FormProvider>
        </>
    );
};

export default Files;
