import React, { FC } from 'react';
import { useEventGroups } from 'contexts';
import { cellRenderMapper, initialColumns } from './mappers';
import { Group, EventGroups as EventGroupsModel, GroupWithBusy } from 'types';
import { renderCell, getTableColumns } from 'utils';
import { PageBuilder } from 'containers';
import { DomainType, PageBuilderKey } from 'enums';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const EventGroups: FC = () => {
    const { eventGroups, getEventGroups, getExcel, deleteEventGroups } = useEventGroups();
    const { i18n } = useTranslation('pages/events');
    const navigate = useNavigate();

    return (
        <PageBuilder<GroupWithBusy>
            pageKey={PageBuilderKey.EventGroups}
            data={{
                count: eventGroups.count,
                items:
                    eventGroups.items?.map((item) => ({
                        ...item,
                        busyEn: {
                            busy: item.busyEn,
                            id: item.id,
                            domain: DomainType.En,
                            newsId: item.linkEn,
                        },
                        busyRu: {
                            busy: item.busyRu,
                            id: item.id,
                            domain: DomainType.Ru,
                            newsId: item.linkRu,
                        },
                    })) || [],
            }}
            getItems={(params) => getEventGroups(params as EventGroupsModel)}
            getExcel={(params) => getExcel(params as Omit<EventGroupsModel, 'page' | 'size'>)}
            deleteItems={deleteEventGroups}
            columns={getTableColumns<Omit<Group, 'busyRu' | 'busyEn' | 'linkRu' | 'linkEn'>>(
                initialColumns,
                cellRenderMapper,
                (value, type) => renderCell(value, type, { path: '/event', navigate }),
                i18n.getResourceBundle(i18n.language, 'pages/events')
            )}
            addItem={() => navigate('/event-group')}
            updateItem={(group: GroupWithBusy) => navigate(`/event-group/${group.id}`)}
        />
    );
};

export default EventGroups;
