import { PageBuilder } from 'containers';
import { useCourseApplications } from 'contexts';
import { FilterType, PageBuilderAdditionalFilters, PageBuilderKey } from 'enums';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
    CourseApplicationShort as CourseApplicationModel,
    GetCourseApplications,
    GetCourseApplicationsFilter,
} from 'types';
import { renderCell, getTableColumns } from 'utils';
import { cellRenderMapper, initialColumns } from './mappers';

const CourseApplications: FC = () => {
    const { courseApplications, getCourseApplications, getExcel, deleteCourseApplications, getCourseApplication } =
        useCourseApplications();
    const { i18n, t } = useTranslation(['pages/courses', 'common/shared']);
    const navigate = useNavigate();
    const { courseId } = useParams();

    return (
        <PageBuilder<CourseApplicationModel>
            pageKey={PageBuilderKey.CourseApplications}
            data={courseApplications}
            getItems={(params) => getCourseApplications({ ...(params as GetCourseApplicationsFilter), courseId })}
            getItem={(id: string) => getCourseApplication(id)}
            showItem={(application: CourseApplicationModel) => {
                return navigate(`/course-application/${application.id}`);
            }}
            getExcel={(params) =>
                getExcel({ ...(params as Omit<GetCourseApplicationsFilter, 'page' | 'size'>), courseId })
            }
            deleteItems={deleteCourseApplications}
            columns={getTableColumns<CourseApplicationModel>(
                initialColumns,
                cellRenderMapper,
                (value, type) => renderCell(value, type, { path: '/agreements', navigate }),
                {
                    ...i18n.getResourceBundle(i18n.language, 'pages/courses'),
                    ...i18n.getResourceBundle(i18n.language, 'common/shared'),
                }
            )}
            additionalFilters={[
                { type: PageBuilderAdditionalFilters.DebounceTextBox, key: FilterType.Email },
                { type: PageBuilderAdditionalFilters.DebounceTextBox, key: FilterType.FirstName },
                { type: PageBuilderAdditionalFilters.DebounceTextBox, key: FilterType.LastName },
                { type: PageBuilderAdditionalFilters.DebounceTextBox, key: FilterType.CourseName },
                { type: PageBuilderAdditionalFilters.Dropdown, key: FilterType.CourseFormType },
                { type: PageBuilderAdditionalFilters.Dropdown, key: FilterType.DomainType },
                { type: PageBuilderAdditionalFilters.Dropdown, key: FilterType.MarketingIsSelected },
            ]}
        />
    );
};

export default CourseApplications;
