import {
    GlobalSiteConfig,
    ControlRendererConfig,
    Speaker,
    News,
    Group,
    Event,
    CourseGroup,
    AddCourseGroup,
    AddCourse,
    Course,
    Profession,
    ProfessionGroup,
    AddProfessionGroup,
    AddProfession,
    Review,
    Organization,
    CourseApplication,
} from 'types';
import { ControlRendererType, FormBuilderKeys } from 'enums';
import {
    configRendererTypes,
    speakerRendererTypes,
    newsGroupRendererTypes,
    newsRendererTypes,
    eventGeneralRendererType,
    courseGeneralRendererType,
    professionGeneralRendererType,
    reviewRendererTypes,
    organizationRendererTypes,
    courseApplicationRendererType,
    professionsGroupRendererTypes,
    courseGroupRendererTypes,
} from './control-configs';
import { RegisterOptions } from 'react-hook-form';
import { omit } from 'kl-b2c-ui-kit';

// These are available types for pair TYPE: CONTROL_TYPE for example if you want to add form for Speakers
// you add | Speaker here.
type AvailableTypes =
    | GlobalSiteConfig
    | Speaker
    | CourseApplication
    | Review
    | Group
    | News
    | Event
    | CourseGroup
    | Course
    | Profession
    | ProfessionGroup;

// This is mapper that get controls types by ExcludedControlType, for example Config and set it to object with solid
// typings, in Config it is Record<keyof GlobalSiteConfig, ControlRendererType>, so in imported configRendererTypes you cannot
// add properties that are not in GlobalSiteConfig
const controlTypes = (
    key: FormBuilderKeys,
    t: (key: string, options?: Record<string, number | string>) => string
): Record<keyof AvailableTypes, { type: ControlRendererType; rules?: RegisterOptions }> => {
    const types = {
        Config: configRendererTypes(t),
        Speaker: speakerRendererTypes(t),
        Review: reviewRendererTypes(t),
        Organization: organizationRendererTypes(t),
        NewsGroup: newsGroupRendererTypes(t),
        News: newsRendererTypes(t),
        EventGroup: newsGroupRendererTypes(t),
        EventGeneral: eventGeneralRendererType(t),
        CourseGroup: courseGroupRendererTypes(t),
        CourseGeneral: courseGeneralRendererType(t),
        ProfessionGroup: professionsGroupRendererTypes(t),
        ProfessionGeneral: professionGeneralRendererType(t),
        CourseApplication: courseApplicationRendererType(t),
    };

    return types[key];
};

export const getControlRenderer = <T>(
    formKey: FormBuilderKeys,
    data: T,
    t: (key: string, options?: Record<string, number | string>) => string
) => {
    switch (formKey) {
        case FormBuilderKeys.Config: {
            return getConfigPageForm<GlobalSiteConfig>(data as GlobalSiteConfig, formKey, t);
        }
        case FormBuilderKeys.Speaker: {
            return getConfigPageForm<Speaker>(omit<T>(['id', 'creationDate'], data) as Speaker, formKey, t);
        }
        case FormBuilderKeys.CourseApplication: {
            return getConfigPageForm<CourseApplication>(data as CourseApplication, formKey, t);
        }
        case FormBuilderKeys.Review: {
            return getConfigPageForm<Review>(omit<T>(['id', 'creationDate'], data) as Review, formKey, t);
        }
        case FormBuilderKeys.Organization: {
            return getConfigPageForm<Organization>(omit<T>(['id', 'creationDate'], data) as Organization, formKey, t);
        }
        case FormBuilderKeys.NewsGroup: {
            return getConfigPageForm<Group>(
                omit<T>(['id', 'creationDate', 'busyRu', 'busyEn', 'linkEn', 'linkRu'], data) as Group,
                formKey,
                t
            );
        }
        case FormBuilderKeys.EventGroup: {
            return getConfigPageForm<Group>(
                omit<T>(['id', 'creationDate', 'busyRu', 'busyEn', 'linkEn', 'linkRu'], data) as Group,
                formKey,
                t
            );
        }
        case FormBuilderKeys.News: {
            return getConfigPageForm<News>(
                omit<T>(['id', 'creationDate', 'busyRu', 'busyEn'], data) as News,
                formKey,
                t
            );
        }
        case FormBuilderKeys.EventGeneral: {
            return getConfigPageForm<Event>(
                omit<T>(['id', 'creationDate', 'busyRu', 'busyEn'], data) as Event,
                formKey,
                t
            );
        }
        case FormBuilderKeys.CourseGroup: {
            return getConfigPageForm<AddCourseGroup>(
                omit<T>(['id', 'creationDate'], data) as AddCourseGroup,
                formKey,
                t
            );
        }
        case FormBuilderKeys.ProfessionGroup: {
            return getConfigPageForm<AddProfessionGroup>(
                omit<T>(['id', 'creationDate'], data) as AddProfessionGroup,
                formKey,
                t
            );
        }
        case FormBuilderKeys.CourseGeneral: {
            return getConfigPageForm<AddCourse>(omit<T>(['id', 'creationDate'], data) as AddCourse, formKey, t);
        }
        case FormBuilderKeys.ProfessionGeneral: {
            return getConfigPageForm<AddProfession>(omit<T>(['id', 'creationDate'], data) as AddProfession, formKey, t);
        }
        default: {
            throw new Error(`There is no control renderer for ${formKey}, consider to add one`);
        }
    }
};

const getConfigPageForm = <T>(
    data: T,
    formKey: FormBuilderKeys,
    t: (key: string, options?: Record<string, number | string>) => string
): Record<keyof T, ControlRendererConfig> => {
    let result = {} as Record<keyof T, ControlRendererConfig>;
    const controlType = controlTypes(formKey as FormBuilderKeys, t);

    Object.keys(data as keyof AvailableTypes).forEach((key: string) => {
        const dataType = controlType[key as keyof AvailableTypes];
        if (!dataType) return;
        const { type, rules, size, readonly, extraKey } = dataType;

        result = {
            ...result,
            [key]: {
                controlValue: data[key as keyof T],
                config: {
                    type,
                    rules,
                    size,
                    readonly,
                    extraKey,
                },
                key,
            },
        };
    });

    return result;
};
