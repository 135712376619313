export enum PageBuilderKey {
    Agreements = 'Agreements',
    Cooperation = 'Cooperation',
    Subscription = 'Subscription',
    Speakers = 'Speakers',
    Events = 'Events',
    EventGroups = 'EventGroups',
    NewsGroups = 'NewsGroups',
    NewsSequence = 'NewsSequence',
    CourseGroups = 'CourseGroups',
    ProfessionGroups = 'ProfessionGroups',
    CourseLanguages = 'CourseLanguages',
    Courses = 'Courses',
    Professions = 'Professions',
    EventApplications = 'EventApplications',
    CourseApplications = 'CourseApplications',
    ProfessionApplications = 'ProfessionApplications',
    Reviews = 'Reviews',
    Organizations = 'Organizations',
}
