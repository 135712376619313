import React, { FC } from 'react';
import { useCourseGroups } from 'contexts';
import { cellRenderMapper, initialColumns } from './mappers';
import { GroupWithBusy, GetCourseGroupsFilter, GetCourseGroupsExcelFilter, CourseGroup } from 'types';
import { renderCell, getTableColumns } from 'utils';
import { PageBuilder } from 'containers';
import { CourseGroupType, DomainType, FilterType, PageBuilderAdditionalFilters, PageBuilderKey } from 'enums';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CourseGroupWithBusy } from 'types/courses/course-group';

const CoursesGroups: FC = () => {
    const { items, getItems, getExcel, deleteItems } = useCourseGroups();
    const { i18n } = useTranslation('pages/courses');
    const navigate = useNavigate();

    return (
        <PageBuilder<CourseGroupWithBusy>
            pageKey={PageBuilderKey.CourseGroups}
            data={{
                count: items.count,
                items:
                    items.items?.map((item) => ({
                        ...item,
                        busyEn: {
                            busy: item.busyEn,
                            id: item.id,
                            domain: DomainType.En,
                            newsId: item.linkEn,
                        },
                        busyRu: {
                            busy: item.busyRu,
                            id: item.id,
                            domain: DomainType.Ru,
                            newsId: item.linkRu,
                        },
                    })) || [],
            }}
            getItems={(params) => getItems(params as GetCourseGroupsFilter)}
            getExcel={(params) => getExcel(params as GetCourseGroupsExcelFilter)}
            deleteItems={deleteItems}
            columns={getTableColumns<Omit<CourseGroup, 'busyRu' | 'busyEn' | 'linkRu' | 'linkEn'>>(
                initialColumns,
                cellRenderMapper,
                (value, type) => renderCell(value, type, { path: '/course', navigate }),
                i18n.getResourceBundle(i18n.language, 'pages/courses')
            )}
            updateItem={(group: CourseGroupWithBusy) => navigate(`/course-group/${group.id}`)}
            addItem={() => navigate('/course-group')}
            canDelete={(data) => data.type !== CourseGroupType.Free}
            additionalFilters={[
                { type: PageBuilderAdditionalFilters.DebounceTextBox, key: FilterType.Name },
                { type: PageBuilderAdditionalFilters.Dropdown, key: FilterType.CourseGroupType },
            ]}
        />
    );
};

export default CoursesGroups;
