import React, { FC, useState } from 'react';
import { YMaps, Map as YMap, Placemark } from 'react-yandex-maps';
import { Coordinates } from 'types/core';
import { MapContainer } from './styled';
import { useTranslation } from 'react-i18next';
import { Button } from '@kl/components-v6';

interface Props {
    defaultValue: Coordinates;
    mapApi: string;
    onChange: (val: Coordinates) => void;
}

const Map: FC<Props> = ({ onChange, defaultValue, mapApi }) => {
    const [isVisible, setIsVisible] = useState(!!defaultValue.latitude && !!defaultValue.longitude);
    const { t } = useTranslation(['pages/events']);
    const [coordinates, setCoordinates] = useState(
        !!defaultValue.latitude && !!defaultValue.longitude
            ? [defaultValue.latitude, defaultValue.longitude]
            : undefined
    );

    const clearLocation = () => {
        onChange({
            latitude: undefined,
            longitude: undefined,
        });
        setIsVisible(false);
    };

    const click = () => {
        if (isVisible) {
            clearLocation();
            setCoordinates(undefined);
        } else setIsVisible(true);
    };

    return (
        <MapContainer>
            {isVisible && (
                <>
                    <YMaps
                        query={{
                            apikey: mapApi,
                            ns: 'use-load-option',
                            lang: 'en_RU',
                        }}
                    >
                        <YMap
                            style={{
                                width: '600px',
                                height: '500px',
                                maxHeight: '70vh',
                                overflow: 'hidden',
                            }}
                            modules={['geolocation', 'geocode']}
                            defaultState={{
                                center: [defaultValue?.latitude ?? 55.751244, defaultValue?.longitude ?? 37.618423],
                                zoom: 10,
                                controls: [],
                            }}
                            options={{ minZoom: 2, maxZoom: 15 }}
                            onClick={(e: { get: (val: string) => number[] }) => {
                                const c = e.get('coords');
                                setCoordinates(c);
                                onChange({
                                    latitude: c[0],
                                    longitude: c[1],
                                });
                            }}
                        >
                            {coordinates && (
                                <Placemark
                                    geometry={coordinates}
                                    options={{
                                        zIndex: 100,
                                    }}
                                />
                            )}
                        </YMap>
                    </YMaps>
                </>
            )}
            <Button onClick={click}>{isVisible ? t('clear-location') : t('add-location')}</Button>
        </MapContainer>
    );
};

export default Map;
