import React from 'react';
import { AgreementsChapter, CellRenderType } from 'enums';
import { Agreement } from 'types';

export const initialColumns: Agreement = {
    id: '',
    creationDate: new Date(),
    chapter: AgreementsChapter.All,
    email: '',
    privacyPolicyIsSelected: false,
    marketingIsSelected: false,
    marketingCheckBoxText: '',
    privacyPolicyCheckBoxText: '',
    privacyPolicyText: '',
};

export const cellRenderMapper: Record<keyof Agreement, CellRenderType> = {
    id: CellRenderType.TEXT,
    creationDate: CellRenderType.DATETIME,
    chapter: CellRenderType.CHAPTER,
    email: CellRenderType.TEXT,
    marketingCheckBoxText: CellRenderType.MARKUP,
    privacyPolicyCheckBoxText: CellRenderType.MARKUP,
    privacyPolicyText: CellRenderType.MARKUP,
    privacyPolicyIsSelected: CellRenderType.BOOLEAN,
    marketingIsSelected: CellRenderType.BOOLEAN,
};
