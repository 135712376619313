import React, { FC } from 'react';
import { useEvents } from 'contexts';
import { Button } from '@kl/components-v6';
import { cellRenderMapper, initialColumns } from './mappers';
import { Events as EventsModel, GetEventsFilters } from 'types';
import { renderCell, getTableColumns } from 'utils';
import { PageBuilder } from 'containers';
import { DomainType, FilterType, PageBuilderAdditionalFilters, PageBuilderKey } from 'enums';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Events: FC = () => {
    const { events, getEvents, getExcel, deleteEvents, getEvent } = useEvents();
    const { i18n, t } = useTranslation('pages/events');
    const navigate = useNavigate();

    return (
        <PageBuilder<Omit<EventsModel, 'image'>>
            pageKey={PageBuilderKey.Events}
            data={events}
            getItems={(params) => getEvents(params as GetEventsFilters)}
            getItem={(id: string) => getEvent(id)}
            getExcel={(params) => getExcel(params as Omit<GetEventsFilters, 'page' | 'size'>)}
            deleteItems={deleteEvents}
            columns={getTableColumns<Omit<EventsModel, 'image'>>(
                initialColumns,
                cellRenderMapper,
                renderCell,
                i18n.getResourceBundle(i18n.language, 'pages/events')
            )}
            additionalFilters={[
                { type: PageBuilderAdditionalFilters.Dropdown, key: FilterType.DomainType },
                { type: PageBuilderAdditionalFilters.Dropdown, key: FilterType.IsVisible },
                { type: PageBuilderAdditionalFilters.Dropdown, key: FilterType.IsVisibleTimeElapsed },
                { type: PageBuilderAdditionalFilters.Dropdown, key: FilterType.IsRegistrationOpened },
            ]}
            updateItem={(event: EventsModel) => {
                const { id, groupEn, groupRu } = event;
                navigate(`/event/${groupEn?.id || groupRu?.id}/${groupRu ? DomainType.Ru : DomainType.En}/${id}`);
            }}
            additionalButtons={[
                {
                    title: '',
                    dataIndex: 'delete',
                    key: 'delete',
                    width: '5%',
                    render: (_: unknown, record: EventsModel) => (
                        <Button onClick={() => navigate(`/events/event-applications-by-id/${record.id}`)}>
                            {t('show-event-applications')}
                        </Button>
                    ),
                },
            ]}
        />
    );
};

export default Events;
