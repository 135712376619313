import { getBaseSingularContext } from 'contexts';
import { useContext } from 'react';
import { getApiService, getCourseGroupsApiService, getStorageService } from 'services';
import {
    AddCourseGroup,
    CourseGroup,
    GetCourseGroupsExcelFilter,
    GetCourseGroupsFilter,
    UpdateCourseGroup,
} from 'types';

const storageService = getStorageService();
const apiService = getApiService(storageService);
const courseApiService = getCourseGroupsApiService(apiService);

const ProviderResult = getBaseSingularContext<
    GetCourseGroupsFilter,
    GetCourseGroupsExcelFilter,
    CourseGroup,
    CourseGroup,
    AddCourseGroup,
    UpdateCourseGroup
>(courseApiService);

export const useCourseGroup = () => useContext(ProviderResult.Context);
export default ProviderResult.Provider;
