import React from 'react';
import { Cooperation } from 'types';
import { LegalType, CellRenderType } from 'enums';

export const initialColumns: Omit<Cooperation, 'id'> = {
    creationDate: new Date(),
    name: '',
    email: '',
    phone: '',
    country: '',
    type: LegalType.All,
    utmTerm: '',
    utmMedium: '',
    utmCampaign: '',
    utmSource: '',
    utmContent: '',
};

export const cellRenderMapper: Record<keyof Omit<Cooperation, 'id'>, CellRenderType> = {
    creationDate: CellRenderType.DATETIME,
    email: CellRenderType.LINK,
    name: CellRenderType.TEXT,
    phone: CellRenderType.TEXT,
    country: CellRenderType.TEXT,
    type: CellRenderType.IS_LEGAL,
    utmTerm: CellRenderType.TEXT,
    utmMedium: CellRenderType.TEXT,
    utmCampaign: CellRenderType.TEXT,
    utmSource: CellRenderType.TEXT,
    utmContent: CellRenderType.TEXT,
};
