import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCourseApplications, useToaster } from 'contexts';
import { CourseFormType, DomainType, FormBuilderKeys } from 'enums';
import { FormProvider, useForm } from 'react-hook-form';
import { Loader } from '@kl/components-v6';
import { useTranslation } from 'react-i18next';
import { CourseApplication as CourseApplicationType } from 'types';
import { FormBuilder } from 'containers';

const INITIAL_DATA: CourseApplicationType = {
    id: '',
    creationDate: new Date(),
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    country: '',
    company: '',
    position: '',
    formType: CourseFormType.Company,
    domainType: DomainType.Ru,
    courseId: '',
    courseName: '',
    utmTerm: '',
    utmMedium: '',
    utmCampaign: '',
    utmSource: '',
    utmContent: '',
    marketingIsSelected: false,
    agreementId: '',
};

const CourseApplication: FC = () => {
    const { getCourseApplication, courseApplications } = useCourseApplications();
    const { id } = useParams();
    const navigate = useNavigate();
    const methods = useForm<CourseApplicationType>();
    const [loading, setLoading] = useState<boolean>(true);

    const { t } = useTranslation('common/shared');

    useEffect(() => {
        if (id) {
            getCourseApplication(id).then(() => setLoading(false));
        }
    }, [id]);

    if ((id && (!courseApplications || !courseApplications.items)) || courseApplications.items === null) {
        return <Loader centered size={'large'} tip={t('loading', { ns: 'common/shared' })} />;
    }

    return (
        <FormProvider {...methods}>
            <FormBuilder<CourseApplicationType>
                data={courseApplications.items[0] || INITIAL_DATA}
                cancel={() => navigate('/courses/course-applications')}
                formKey={FormBuilderKeys.CourseApplication}
                isFormEmpty={!id}
                loading={loading}
            />
        </FormProvider>
    );
};

export default CourseApplication;
