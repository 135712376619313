import React from 'react';
import { Tabs } from '@kl/components-v6';
import { useNavigate, Outlet } from 'react-router-dom';

const elements = [
    {
        tabTitle: 'Courses',
        path: '/courses/all',
    },
    {
        tabTitle: 'Courses Groups',
        path: '/courses/courses-groups',
    },
    {
        tabTitle: 'Courses Applications',
        path: '/courses/course-applications',
    },
];

const CoursesBase = () => {
    const navigate = useNavigate();

    return (
        <>
            <Tabs activeKey={window.location.pathname} onChange={(path) => navigate(path)}>
                {elements.map((element) => (
                    <Tabs.TabPane tab={element.tabTitle} key={element.path} />
                ))}
            </Tabs>

            <Outlet />
        </>
    );
};

export default CoursesBase;
