import React, { FC } from 'react';
import { ArrayContainer } from 'containers/events/event/features/styled';
import { ErrorMessage } from 'containers/form-builder/styled';
import { FormRow } from 'containers/news/news/styled';
import { Button, Select, Textbox } from '@kl/components-v6';
import { Control, Controller, FieldErrors, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AddOrUpdateCourseToProfessionLevel } from 'types/professions';
import { DomainType } from 'enums/domain-type';
import { CourseTable } from 'types/courses';

type FormValues = {
    courseProfessionLevels: AddOrUpdateCourseToProfessionLevel[];
};

interface CourseProfessionProps {
    parentIndex: number;
    control: Control;
    errors: FieldErrors<FormValues>;
    courses: CourseTable[];
}

const CourseProfessionLevel: FC<CourseProfessionProps> = ({ parentIndex, control, errors, courses }) => {
    const { t } = useTranslation(['pages/professions', 'common/shared']);
    const { fields, remove, append } = useFieldArray({
        control,
        name: `levels[${parentIndex}].courseProfessionLevels`,
    });
    return (
        <>
            {fields.map((field, index) => {
                return (
                    <FormRow key={field.id} style={{ margin: '0 0 40px 0', alignItems: 'flex-start' }}>
                        <span>{`Course ${index + 1}`}</span>

                        <ArrayContainer>
                            <div style={{ position: 'relative' }}>
                                <Controller
                                    name={`levels[${parentIndex}].courseProfessionLevels[${index}].number`}
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: t('required-field', { ns: 'common/errors' }),
                                        },
                                        min: {
                                            value: 1,
                                            message: t('min-value', {
                                                ns: 'common/errors',
                                                value: 1,
                                            }),
                                        },
                                    }}
                                    defaultValue={1}
                                    render={({ field: { onChange, value } }) => (
                                        <Textbox
                                            type={'number'}
                                            value={value as unknown as string}
                                            allowClear
                                            onChange={(val) => {
                                                if (!val) {
                                                    onChange(val);
                                                    return;
                                                }
                                                const newVal = `${val}`.replace('.', '').replace(',', '');
                                                onChange(newVal);
                                            }}
                                            placeholder={t('course-number')}
                                        />
                                    )}
                                />

                                {errors?.courseProfessionLevels?.[index]?.number && (
                                    <ErrorMessage style={{ left: 0 }}>
                                        {errors?.courseProfessionLevels?.[index]?.number?.message}
                                    </ErrorMessage>
                                )}
                            </div>

                            <div style={{ position: 'relative' }}>
                                <Controller
                                    name={`levels[${parentIndex}].courseProfessionLevels[${index}].courseId`}
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: t('required-field', { ns: 'common/errors' }),
                                        },
                                    }}
                                    defaultValue={''}
                                    render={({ field: { onChange, value } }) => (
                                        <Select
                                            allowClear
                                            showSearch
                                            onChange={onChange}
                                            defaultValue={[value]}
                                            error={!!errors?.courseProfessionLevels?.[index]?.courseId}
                                            options={courses.map((r) => ({
                                                label: r.name.substring(0, 45) + '...',
                                                value: r.id,
                                            }))}
                                        />
                                    )}
                                />

                                {errors?.courseProfessionLevels?.[index]?.courseId && (
                                    <ErrorMessage style={{ left: 0 }}>
                                        {errors?.courseProfessionLevels?.[index]?.courseId?.message}
                                    </ErrorMessage>
                                )}
                            </div>
                        </ArrayContainer>

                        <Button mode={'dangerOutlined'} onClick={() => remove(index)}>
                            {t('delete', { ns: 'common/shared' })}
                        </Button>
                    </FormRow>
                );
            })}
            <Button
                mode={'primaryBlack'}
                onClick={() =>
                    append({
                        courseId: '',
                        number: 1,
                    })
                }
            >
                {t('append-course')}
            </Button>
        </>
    );
};

export default CourseProfessionLevel;
