import React, { PropsWithChildren } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from '@kl/components-v6/design-system/context/provider';
import { ThemeKey } from '@kl/components-v6/design-system/types';
import ApiProvider from 'contexts/api.context';
import '@kl/components-v6/design-system/global-style/styles.css';
import 'normalize.css';
import { AuthProvider, ModalProvider, ToasterProvider } from 'contexts';

const CoreProvider: React.FC<PropsWithChildren> = ({ children }): JSX.Element => {
    return (
        <AuthProvider>
            <ApiProvider>
                <BrowserRouter>
                    <ConfigProvider theme={ThemeKey.Light}>
                        <ModalProvider>
                            <ToasterProvider>{children}</ToasterProvider>
                        </ModalProvider>
                    </ConfigProvider>
                </BrowserRouter>
            </ApiProvider>
        </AuthProvider>
    );
};

export default CoreProvider;
