import { AxiosError } from 'axios';
import { SwaggerError } from 'types';

export const getSwaggerError = (e: unknown): string => {
    const axios = e as AxiosError;
    if (!axios?.response || !axios.response?.data) return '';

    const errors = axios.response.data as SwaggerError;
    if (!errors.title || !errors.status || !errors.errors) return '';

    const keys = Object.keys(errors.errors);
    const trueError = keys.map((key) => `${key} - ${errors.errors[key].join(', ')}`);
    console.log({ trueError, keys });
    return trueError.join(', ');
};
