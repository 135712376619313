import React, { createContext, FC, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { getSettingsService, getStorageService } from 'services';
import { AppSettings } from 'types';
import { LS_KEYS } from 'consts';

interface SettingsContext {
    settings: AppSettings | null;
}

const SettingsContext = createContext<SettingsContext>({ settings: null });

const SettingsProvider: FC<PropsWithChildren> = ({ children }) => {
    const storageService = getStorageService();
    const settingsService = getSettingsService();
    const [settings, setSettings] = useState<AppSettings | null>(null);

    useEffect(() => {
        settingsService
            .getSettings()
            .then((settings: AppSettings) => {
                setSettings(settings);
                storageService.setItem<AppSettings>(LS_KEYS['appSettings'], settings);
            })
            .catch((e: Error) => {
                console.warn(`Cannot load app settings: ${e.message}`);
            });
    }, []);

    const memoValue = useMemo(
        () => ({
            settings,
        }),
        [settings]
    );

    return <SettingsContext.Provider value={memoValue}>{children}</SettingsContext.Provider>;
};

export const useSettings = () => useContext(SettingsContext);

export default SettingsProvider;
