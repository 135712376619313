import React, { createContext, FC, Key, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { getApiService, getOrganizationsApiService, getStorageService } from 'services';
import { Organization, Organizations } from 'types';
import { downloadFileFromBase64, PaginationOutput } from 'kl-b2c-ui-kit';
import { dropdownToBoolean } from '../mappers';

interface OrganizationsContext {
    organizations: PaginationOutput<Organization>;
    getOrganizations: (params: Organizations) => Promise<void>;
    getExcel: (params: Omit<Organizations, 'page' | 'size'>) => Promise<void>;
    deleteOrganizations: (ids: Key[]) => Promise<void>;
}

const OrganizationsContext = createContext<OrganizationsContext>({} as OrganizationsContext);

const OrganizationsProvider: FC<PropsWithChildren> = ({ children }) => {
    const storageService = getStorageService();
    const [organizations, setOrganizations] = useState<PaginationOutput<Organization>>({
        count: 0,
        items: null,
    });
    const apiService = getApiService(storageService);
    const organizationsApiService = getOrganizationsApiService(apiService);

    const getOrganizations = async (params: Organizations) => {
        const organizations = await organizationsApiService.getItems({
            ...params,
            domain: params.domain ? params.domain : null,
            isVisibleGovernmentPage: dropdownToBoolean(params.isVisibleGovernmentPage),
        });
        setOrganizations(organizations.data);
    };

    const deleteOrganizations = async (ids: Key[]) => {
        await organizationsApiService.deleteMany(ids);
    };

    const getExcel = async (params: Omit<Organizations, 'page' | 'size'>) => {
        const base64 = await organizationsApiService.getExcel({
            ...params,
            domain: params.domain ? params.domain : null,
            isVisibleGovernmentPage: dropdownToBoolean(params.isVisibleGovernmentPage),
        });
        downloadFileFromBase64(base64.data, 'xlsx', 'eventOrganizations');
    };

    const memoValue = useMemo(
        () => ({
            getOrganizations,
            organizations,
            getExcel,
            deleteOrganizations,
        }),
        [getOrganizations, organizations]
    );

    return <OrganizationsContext.Provider value={memoValue}>{children}</OrganizationsContext.Provider>;
};

export const useOrganizations = () => useContext(OrganizationsContext);

export default OrganizationsProvider;
