import { Event } from 'types';
import { ControlRendererType } from 'enums';
import { RegisterOptions } from 'react-hook-form';
import { GetFile } from 'kl-b2c-ui-kit';
import { fileSize } from 'containers/form-builder/rules';
import { ACCEPTED_IMAGE_FORMATS_EXCEPT_GIF, TEN_MB_IN_BYTES } from 'consts';

export const eventGeneralRendererType = (
    t: (key: string, options?: Record<string, number | string>) => string
): Record<keyof Omit<Event, 'id' | 'creationDate'>, { type: ControlRendererType; rules?: RegisterOptions }> => ({
    name: {
        type: ControlRendererType.TextBox,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 255,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 255,
                }),
            },
        },
    },
    startDate: {
        type: ControlRendererType.Hidden,
    },
    finishDate: {
        type: ControlRendererType.Hidden,
    },
    image: {
        type: ControlRendererType.UploadImageExceptGif,
        rules: {
            required: {
                value: true,
                message: t('required-image', {
                    ns: 'common/errors',
                }),
            },
            validate: (file: File | GetFile) => {
                return fileSize(file, TEN_MB_IN_BYTES, ACCEPTED_IMAGE_FORMATS_EXCEPT_GIF, t);
            },
        },
    },
    targetAudienceDescription: {
        type: ControlRendererType.Wysiwyg,
        rules: {
            maxLength: {
                value: 1000,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 1000,
                }),
            },
        },
    },
    additionalRequirements: {
        type: ControlRendererType.Wysiwyg,
        rules: {
            maxLength: {
                value: 500,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 500,
                }),
            },
        },
    },
    additionalDescription: {
        type: ControlRendererType.Wysiwyg,
        rules: {
            maxLength: {
                value: 2000,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 2000,
                }),
            },
        },
    },
    baseDescription: {
        type: ControlRendererType.Wysiwyg,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 1000,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 1000,
                }),
            },
        },
    },
    isVisible: {
        type: ControlRendererType.Hidden,
    },
    isVisibleTimeElapsed: {
        type: ControlRendererType.CheckBox,
    },
    isVisibleLocation: {
        type: ControlRendererType.Hidden,
    },
    isRegistrationOpened: {
        type: ControlRendererType.CheckBox,
    },
    locationPhone: {
        type: ControlRendererType.Hidden,
    },
    location: {
        type: ControlRendererType.Hidden,
    },
    locationEmail: {
        type: ControlRendererType.Hidden,
    },
    groupEn: {
        type: ControlRendererType.Hidden,
    },
    groupRu: {
        type: ControlRendererType.Hidden,
    },
    speakers: {
        type: ControlRendererType.Hidden,
    },
    formTypes: {
        type: ControlRendererType.Hidden,
    },
    eventType: {
        type: ControlRendererType.Hidden,
    },
    locationCity: {
        type: ControlRendererType.Hidden,
    },
    locationCountry: {
        type: ControlRendererType.Hidden,
    },
    isVisibleKidsCyberResilience: {
        type: ControlRendererType.Hidden,
    },
    coordinates: {
        type: ControlRendererType.Hidden,
    },
    tags: {
        type: ControlRendererType.Hidden,
    },
    teasers: {
        type: ControlRendererType.Hidden,
    },
    prizeFunds: {
        type: ControlRendererType.Hidden,
    },
    targetAudiences: {
        type: ControlRendererType.Hidden,
    },
    phases: {
        type: ControlRendererType.Hidden,
    },
    results: {
        type: ControlRendererType.Hidden,
    },
    files: {
        type: ControlRendererType.Hidden,
    },
    photos: {
        type: ControlRendererType.Hidden,
    },
    programs: {
        type: ControlRendererType.Hidden,
    },
});
