const ACCEPTED_IMAGE_FORMATS = '.jpg, .jpeg, .png, .svg, .svg+xml, .gif';
const ACCEPTED_IMAGE_FORMATS_EXCEPT_GIF = '.jpg, .jpeg, .png, .svg, .svg+xml';
const ACCEPTED_IMAGE_FORMATS_EXCEPT_GIF_SWG = '.jpg, .jpeg, .png';
const ACCEPTED_FILE_FORMATS = `${ACCEPTED_IMAGE_FORMATS} .xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf`;
const EVENTS_FILES = '.pdf, .doc, .docx, .ppt, .pptx';
const HUNDRED_MB_IN_BYTES = 104857600;
const TEN_MB_IN_BYTES = 10485760;
const THREE_MB_IN_BYTES = 3145728;

export {
    EVENTS_FILES,
    ACCEPTED_IMAGE_FORMATS,
    HUNDRED_MB_IN_BYTES,
    TEN_MB_IN_BYTES,
    ACCEPTED_FILE_FORMATS,
    ACCEPTED_IMAGE_FORMATS_EXCEPT_GIF,
    ACCEPTED_IMAGE_FORMATS_EXCEPT_GIF_SWG,
    THREE_MB_IN_BYTES,
};
