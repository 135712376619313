import React, { createContext, FC, Key, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { getApiService, getNewsSequenceApiService, getStorageService } from 'services';
import { NewsSequence, NewsTable } from 'types';
import { downloadFileFromBase64, PaginationOutput } from 'kl-b2c-ui-kit';
import { dropdownToBoolean } from 'contexts/mappers';

interface NewsSequenceContext {
    news: PaginationOutput<NewsTable>;
    getNewsSequence: (params: NewsSequence) => Promise<void>;
    getExcel: (params: Omit<NewsSequence, 'page' | 'size'>) => Promise<void>;
    deleteNewsSequence: (ids: Key[]) => Promise<void>;
}

const NewsSequenceContext = createContext<NewsSequenceContext>({} as NewsSequenceContext);

const NewsSequenceProvider: FC<PropsWithChildren> = ({ children }) => {
    const storageService = getStorageService();
    const [news, setNewsSequence] = useState<PaginationOutput<NewsTable>>({
        count: 0,
        items: null,
    });
    const apiService = getApiService(storageService);
    const newsApiService = getNewsSequenceApiService(apiService);

    const getNewsSequence = async (params: NewsSequence) => {
        const news = await newsApiService.getItems({
            ...params,
            domainType: params.domainType ? params.domainType : null,
            isVisible: dropdownToBoolean(params.isVisible),
            isVisibleOnHomePage: dropdownToBoolean(params.isVisibleOnHomePage),
        });
        setNewsSequence(news.data);
    };

    const deleteNewsSequence = async (ids: Key[]) => {
        await newsApiService.deleteMany(ids);
    };

    const getExcel = async (params: Omit<NewsSequence, 'page' | 'size'>) => {
        const base64 = await newsApiService.getExcel({
            ...params,
            domainType: params.domainType ? params.domainType : null,
            isVisible: dropdownToBoolean(params.isVisible),
            isVisibleOnHomePage: dropdownToBoolean(params.isVisibleOnHomePage),
        });
        downloadFileFromBase64(base64.data, 'xlsx', 'news');
    };

    const memoValue = useMemo(
        () => ({
            getNewsSequence,
            news,
            getExcel,
            deleteNewsSequence,
        }),
        [getNewsSequence, news, getExcel, deleteNewsSequence]
    );

    return <NewsSequenceContext.Provider value={memoValue}>{children}</NewsSequenceContext.Provider>;
};

export const useNewsSequence = () => useContext(NewsSequenceContext);

export default NewsSequenceProvider;
