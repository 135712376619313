import { IApiService, ICourseApi } from 'interfaces';
import {
    HttpResponse,
    GetCourses,
    Course,
    GetCoursesExcel,
    CourseTable,
    AddCourse,
    UpdateCourse,
    UpdateCourseResults,
    UpdateCourseTargetAudiences,
    UpdateCourseTeasers,
    GetCoursesFilter,
    GetCoursesExcelFilter,
    UpdateCoursePrices,
} from 'types';
import { BaseApi2 } from './base-api2.service';
import { generateFormData } from 'kl-b2c-ui-kit';
import { ApiBaseKeys } from 'enums/api-base-keys';
import { dropdownToBoolean } from 'contexts/mappers';

class CoursesApi
    extends BaseApi2<
        GetCoursesFilter,
        GetCoursesExcelFilter,
        GetCourses,
        GetCoursesExcel,
        CourseTable,
        Course,
        AddCourse,
        UpdateCourse
    >
    // eslint-disable-next-line prettier/prettier
    implements ICourseApi {
    constructor(apiService: IApiService, path: ApiBaseKeys) {
        super(apiService, path);
    }
    protected override getAddData(data: AddCourse): FormData | AddCourse {
        const result = generateFormData(data as Object);
        result.delete('additionalProgram');
        result.append(
            'additionalProgram.isDelete',
            `${(data.additionalProgram as { isDelete: boolean; image?: File | undefined })?.isDelete ?? 'false'}`
        );
        result.append(
            'additionalProgram.image',
            (data.additionalProgram as { isDelete: boolean; image?: File | undefined })?.image ?? ''
        );
        return result;
    }
    protected override getUpdateData(data: UpdateCourse): FormData | UpdateCourse {
        const result = generateFormData(data as Object);
        result.delete('additionalProgram');
        result.append(
            'additionalProgram.isDelete',
            `${(data.additionalProgram as { isDelete: boolean; image?: File | undefined })?.isDelete ?? 'false'}`
        );
        result.append(
            'additionalProgram.image',
            (data.additionalProgram as { isDelete: boolean; image?: File | undefined })?.image ?? ''
        );
        return result;
    }
    protected override getParams(data: GetCoursesFilter): GetCourses {
        return {
            ...data,
            isVisible: dropdownToBoolean(data.isVisible),
            domainType: data.domainType ? data.domainType : null,
        };
    }
    protected override getExcelParams(data: GetCoursesExcelFilter): GetCoursesExcel {
        return {
            ...data,
            isVisible: dropdownToBoolean(data.isVisible),
            domainType: data.domainType ? data.domainType : null,
        };
    }
    public updateTeasers(data: UpdateCourseTeasers): Promise<HttpResponse<Course>> {
        return this.apiService.request<Course>({
            method: 'PUT',
            url: `/${this.path}/teasersUpdate`,
            data,
        });
    }
    public updateTargetAudiences(data: UpdateCourseTargetAudiences): Promise<HttpResponse<Course>> {
        return this.apiService.request<Course>({
            method: 'PUT',
            url: `/${this.path}/targetAudienceUpdate`,
            data,
        });
    }
    public updatePrices(data: UpdateCoursePrices): Promise<HttpResponse<Course>> {
        return this.apiService.request<Course>({
            method: 'PUT',
            url: `/${this.path}/pricesUpdate`,
            data,
        });
    }
    public updateResults(data: UpdateCourseResults): Promise<HttpResponse<Course>> {
        const form = new FormData();
        form.append('courseId', data.courseId);
        data.items.forEach((item, index) => {
            this.formArrayTransform(form, item, 'items', index);
        });
        return this.apiService.request<Course>({
            method: 'PUT',
            url: `/${this.path}/resultsUpdate`,
            data: form,
        });
    }
}

export default CoursesApi;
