import React, { PropsWithChildren } from 'react';
import { Button, Space } from '@kl/components-v6';
import { useFormContext } from 'react-hook-form';

interface FooterProps {
    res: {
        submitBtnText: string;
        cancelBtnText: string;
        deleteBtnText?: string;
    };
    cancel: () => void;
    submit: () => void;
    onDelete?: () => void;
    loading: boolean;
}

const Footer = (props: PropsWithChildren<FooterProps>) => {
    const {
        res: { submitBtnText, cancelBtnText, deleteBtnText },
        submit,
        cancel,
        onDelete,
        loading = false,
    } = props;

    const {
        formState: { isDirty },
    } = useFormContext();

    return (
        <Space size={10} style={{ marginTop: 40 }}>
            <Button loading={loading} disabled={!isDirty} onClick={submit} mode={'primaryBlack'} text={submitBtnText} />

            <Button onClick={cancel} mode={'secondary'} text={cancelBtnText} />

            {onDelete && <Button onClick={onDelete} mode={'danger'} text={deleteBtnText} />}
        </Space>
    );
};

export default Footer;
