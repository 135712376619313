import { IEventsApi } from 'interfaces';
import { GetEvents, UpdateEventParticle, Event, HttpResponse } from 'types';
import { BaseApi } from './base-api.service';
import { generateFormData } from 'kl-b2c-ui-kit';

class EventsApi extends BaseApi<GetEvents, Event> implements IEventsApi {
    public updateEventParticle<T>(
        data: UpdateEventParticle<T>,
        key: string,
        isFormData = false
    ): Promise<HttpResponse<Event>> {
        return this.apiService.request<Event>({
            method: 'PUT',
            url: `/${this.basePath}/${key}`,
            data: isFormData ? generateFormData(data) : data,
        });
    }

    public updateParticleWithFiles<T>(data: UpdateEventParticle<T>, key: string): Promise<HttpResponse<Event>> {
        const form = new FormData();
        form.append('eventId', data.eventId);
        data.items.forEach((item, index) => {
            Object.keys(item as keyof T).forEach((key: string) => {
                // @ts-ignore
                form.append(`items[${index}].${key}`, item[key]);
            });
        });
        return this.apiService.request<Event>({
            method: 'PUT',
            url: `/${this.basePath}/${key}`,
            data: form,
        });
    }
}

export default EventsApi;
