import React, { createContext, FC, Key, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { getApiService, getEventApplicationsApiService, getStorageService } from 'services';
import { EventApplication, GetEventApplications, GetEventApplicationsFilter } from 'types';
import { downloadFileFromBase64, PaginationOutput } from 'kl-b2c-ui-kit';
import { dropdownToBoolean } from 'contexts/mappers';

interface EventApplicationsContext {
    eventApplications: PaginationOutput<EventApplication>;
    getEventApplications: (params: GetEventApplicationsFilter) => Promise<void>;
    getEventApplication: (id: string) => Promise<void>;
    getExcel: (params: Omit<GetEventApplicationsFilter, 'page' | 'size'>) => Promise<void>;
    deleteEventApplications: (ids: Key[]) => Promise<void>;
}

const EventApplicationsContext = createContext<EventApplicationsContext>({} as EventApplicationsContext);

const EventApplicationsProvider: FC<PropsWithChildren> = ({ children }) => {
    const storageService = getStorageService();
    const [eventApplications, setEventApplications] = useState<PaginationOutput<EventApplication>>({
        count: 0,
        items: null,
    });
    const apiService = getApiService(storageService);
    const eventApplicationsApiService = getEventApplicationsApiService(apiService);

    const getEventApplications = async (params: GetEventApplicationsFilter) => {
        const eventApplications = await eventApplicationsApiService.getItems({
            ...params,
            formType: params.formType ? params.formType : null,
            marketingIsSelected: dropdownToBoolean(params.marketingIsSelected),
        });
        setEventApplications(eventApplications.data);
    };

    const getEventApplication = async (id: string) => {
        const event = await eventApplicationsApiService.getItem(id);
        setEventApplications({ count: 1, items: [event.data] });
    };

    const deleteEventApplications = async (ids: Key[]) => {
        await eventApplicationsApiService.deleteMany(ids);
    };

    const getExcel = async (params: Omit<GetEventApplicationsFilter, 'page' | 'size'>) => {
        const base64 = await eventApplicationsApiService.getExcel({
            ...params,
            marketingIsSelected: dropdownToBoolean(params.marketingIsSelected),
        });
        downloadFileFromBase64(base64.data, 'xlsx', 'EventApplications');
    };

    const memoValue = useMemo(
        () => ({
            getEventApplications,
            eventApplications,
            getExcel,
            deleteEventApplications,
            getEventApplication,
        }),
        [getEventApplications, eventApplications, getEventApplication]
    );

    return <EventApplicationsContext.Provider value={memoValue}>{children}</EventApplicationsContext.Provider>;
};

export const useEventApplications = () => useContext(EventApplicationsContext);

export default EventApplicationsProvider;
