import { Group } from 'types';
import { ControlRendererType } from 'enums';
import { RegisterOptions } from 'react-hook-form';

export const newsGroupRendererTypes = (
    t: (key: string, options?: Record<string, number | string>) => string
): Record<
    keyof Omit<Group, 'busyEn' | 'busyRu' | 'linkEn' | 'linkRu'>,
    { type: ControlRendererType; rules?: RegisterOptions }
> => ({
    id: {
        type: ControlRendererType.Text,
    },
    name: {
        type: ControlRendererType.TextBox,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 255,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 255,
                }),
            },
            pattern: {
                value: /\S$/,
                message: t('white-space-in-the-end', {
                    ns: 'common/errors',
                    length: 255,
                }),
            },
        },
    },
    creationDate: {
        type: ControlRendererType.DateTime,
    },
});
